// src/components/Users.jsx

import React, { useState, useEffect, useContext } from 'react';
import './Users.css';
import Spinner from '../Common/Spinner';
import Table from '../Common/Table';
import UserForm from './UserForm';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ResetPasswordConfirmationModal from '../Common/ResetPasswordConfirmationModal';
import CustomModal from '../Common/CustomModal';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../DataContext';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import SearchBar from '../Common/SearchBar';
import PaginationControls from '../Common/PaginationControls';

const Users = () => {
  const { t } = useTranslation();
  const [showUserModal, setShowUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [editingUser, setEditingUser] = useState(null);

  // Add state for rolesMap and loading state
  const [rolesMap, setRolesMap] = useState({});
  const [isRolesLoading, setIsRolesLoading] = useState(false);

  // State to check if the view is mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Consume context
  const {
    users,
    roles,
    isUsersLoading,
    addUser,
    editUser,
    deleteUser,
    resetUserPassword,
    organizationData,
    fetchUsersAndRoles,
    getObjectByReference,
  } = useContext(DataContext);

  // State Variables for Delete Confirmation
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');

  // State Variables for Reset Password Confirmation
  const [showResetModal, setShowResetModal] = useState(false);
  const [userToReset, setUserToReset] = useState(null);

  const [toasts, setToasts] = useState([]);

  // Sorting state
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');

  // Add state for Search Term and Pagination
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // Loading states for add/edit and delete operations
  const [isSubmittingUser, setIsSubmittingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch users and roles when the component mounts
  useEffect(() => {
    if (organizationData.groupId) {
      fetchUsersAndRoles();
    }
  }, [organizationData.groupId]);

  // Fetch roles when users data changes
  useEffect(() => {
    if (users.length > 0) {
      fetchRolesForUsers(users);
    }
  }, [users]);

  // Function to fetch roles based on user data
  const fetchRolesForUsers = async (users) => {
    setIsRolesLoading(true);
    const uniqueRoleRefs = new Set();

    users.forEach((user) => {
      const userRoles = user.roles || [];
      const userRoleEntry = userRoles.find(
        (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
      );
      if (userRoleEntry && userRoleEntry.role) {
        uniqueRoleRefs.add(userRoleEntry.role);
      }
    });

    const rolesMapTemp = {};

    const promises = Array.from(uniqueRoleRefs).map((roleRef) =>
      getObjectByReference(roleRef).then((roleData) => {
        if (roleData) {
          rolesMapTemp[roleRef.id] = roleData;
        }
      })
    );

    await Promise.all(promises);
    setRolesMap(rolesMapTemp);
    setIsRolesLoading(false);
  };

  // Function to handle adding a new user
  const handleAddUser = () => {
    setModalTitle(t('users.addUser'));
    setEmail('');
    setRole('');
    setFirstName('');
    setLastName('');
    setEditingUser(null);
    setShowUserModal(true);
  };

  // Function to handle editing an existing user
  const handleEditUser = (user) => {
    setModalTitle(t('users.editUser'));
    setEmail(user.email);
    setFirstName(user.firstName);
    setLastName(user.lastName);

    const userRoles = user.roles || [];
    const userRoleEntry = userRoles.find(
      (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
    );
    const roleId = userRoleEntry?.role?.id || '';

    setRole(roleId);
    setEditingUser(user);
    setShowUserModal(true);
  };

  // Function to handle deleting a user
  const handleDeleteUser = (user) => {
    setUserToDelete(user);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: `${user.firstName} ${user.lastName}`,
      })
    );
    setShowDeleteModal(true);
  };

  // Function to confirm deletion
  const confirmDeleteUser = async () => {
    if (!userToDelete) return;

    setIsDeletingUser(true);

    try {
      await deleteUser(userToDelete.id);
      showToastMessage(t('users.deleteSuccess'), 'success');
    } catch (error) {
      console.error('Error deleting user: ', error);
      showToastMessage(`${t('users.deleteError')}: ${error.message}`, 'error');
    } finally {
      setIsDeletingUser(false);
      setShowDeleteModal(false);
      setUserToDelete(null);
    }
  };

  // Function to handle password reset
  const handleResetPassword = (user) => {
    setUserToReset(user);
    setShowResetModal(true);
  };

  // Function to confirm password reset
  const confirmResetPassword = async () => {
    if (!userToReset) return;

    try {
      await resetUserPassword(userToReset.email);
      showToastMessage(t('users.resetPasswordSuccess'), 'success');
    } catch (error) {
      console.error('Error sending password reset email: ', error);
      showToastMessage(t('users.resetPasswordError'), 'error');
    } finally {
      setShowResetModal(false);
      setUserToReset(null);
    }
  };

  // Function to handle form submission for adding/editing user
  const handleUserModalSubmit = async (e) => {
    e.preventDefault();

    if (!firstName.trim()) {
      showToastMessage(t('users.firstNameRequired'), 'error');
      return;
    }

    if (!lastName.trim()) {
      showToastMessage(t('users.lastNameRequired'), 'error');
      return;
    }

    setIsSubmittingUser(true);

    if (!editingUser) {
      if (!email) {
        showToastMessage(t('users.emailRequired'), 'error');
        setIsSubmittingUser(false);
        return;
      }

      try {
        await addUser({ email, firstName, lastName, roleId: role });
        showToastMessage(t('users.addSuccess'), 'success');
        setShowUserModal(false);
      } catch (error) {
        console.error('Error adding user: ', error);
        showToastMessage(`${t('users.addError')}: ${error.message}`, 'error');
      } finally {
        setIsSubmittingUser(false);
      }
    } else {
      try {
        await editUser(editingUser.id, { firstName, lastName, roleId: role });
        showToastMessage(t('users.updateSuccess'), 'success');
        setShowUserModal(false);
        setEditingUser(null);
      } catch (error) {
        console.error('Error updating user: ', error);
        showToastMessage(`${t('users.updateError')}: ${error.message}`, 'error');
      } finally {
        setIsSubmittingUser(false);
      }
    }
  };

  // Sorting function
  const setSorting = (field) => {
    if (sortField === field) {
      // Toggle sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // Pagination handlers
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  const handlePageClick = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Compute filtered users
  const filteredUsers = React.useMemo(() => {
    const searchString = searchTerm.toLowerCase();
    return users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      const email = user.email.toLowerCase();

      const userRoles = user.roles || [];
      const userRoleEntry = userRoles.find(
        (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
      );
      let roleName = '';
      if (userRoleEntry && userRoleEntry.role) {
        const roleId = userRoleEntry.role.id;
        const roleData = rolesMap[roleId];
        roleName = roleData ? roleData.name.toLowerCase() : '';
      }

      return (
        fullName.includes(searchString) ||
        email.includes(searchString) ||
        roleName.includes(searchString)
      );
    });
  }, [users, searchTerm, rolesMap, organizationData.groupId]);

  // Define table columns
  const columns = [
    {
      id: 'fullName',
      label: t('userTable.fullName'),
      accessor: ['firstName', 'lastName'],
      sortable: true,
    },
    {
      id: 'email',
      label: t('userTable.email'),
      accessor: 'email',
      sortable: true,
    },
    {
      id: 'roles',
      label: t('userTable.role'),
      accessor: (user) => {
        const userRoles = user.roles || [];
        const userRoleEntry = userRoles.find(
          (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
        );

        if (userRoleEntry && userRoleEntry.role) {
          const roleId = userRoleEntry.role.id;
          const roleData = rolesMap[roleId];
          return roleData ? roleData.name : t('loading');
        }
        return '';
      },
      sortable: true,
    },
  ];

  // Define table actions
  const actions = [
    {
      label: t('edit'),
      icon: 'faEdit',
      callback: handleEditUser,
      condition: () => true,
    },
    {
      label: t('delete'),
      icon: 'faTrash',
      callback: handleDeleteUser,
      condition: () => true,
    },
    {
      label: t('resetPassword'),
      icon: 'faKey',
      callback: handleResetPassword,
      condition: () => true,
    },
  ];

  // Sort users based on sorting state
  const sortedUsers = React.useMemo(() => {
    const data = filteredUsers;
    if (!sortField) return data;
    const column = columns.find((col) => col.id === sortField);
    if (!column) return data;

    const accessor = column.accessor;
    return [...data].sort((a, b) => {
      let aValue;
      let bValue;

      if (typeof accessor === 'function') {
        aValue = accessor(a);
        bValue = accessor(b);
      } else if (typeof accessor === 'string') {
        aValue = a[accessor];
        bValue = b[accessor];
      } else if (Array.isArray(accessor)) {
        aValue = accessor.map((key) => a[key]).join(' ');
        bValue = accessor.map((key) => b[key]).join(' ');
      } else {
        aValue = '';
        bValue = '';
      }

      // Handle undefined or loading values
      if (!aValue) aValue = '';
      if (!bValue) bValue = '';

      if (typeof aValue === 'string') aValue = aValue.toLowerCase();
      if (typeof bValue === 'string') bValue = bValue.toLowerCase();

      if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredUsers, sortField, sortOrder, columns]);

  // Compute total pages
  const totalPages = Math.ceil(sortedUsers.length / itemsPerPage);

  // Compute paginated users
  const paginatedUsers = React.useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sortedUsers.slice(startIndex, endIndex);
  }, [sortedUsers, currentPage, itemsPerPage]);

  // Loading State
  if (isUsersLoading || isRolesLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  return (
    <div className="users-table-container">
      {/* Header Section */}
      <div className="users-table-header">
        <h2>{t('usersHeader.title')}</h2>
      </div>

      <div className="users-header-actions">
        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder={t('users.searchPlaceholder')}
          isLoading={isUsersLoading || isRolesLoading}
        />
        <ActionButton
          onClick={handleAddUser}
          text=""
          label={t('usersHeader.addUser')}
          icon="faPlus"
          isMobile={isMobile}
          colorType="primary"
          ariaLabel={t('usersHeader.addUserAria')}
        />
      </div>

      <div className="record-count" aria-live="polite">
        {filteredUsers.length === 1
          ? t('userTable.recordsFound', { count: filteredUsers.length })
          : t('userTable.recordsFound_plural', { count: filteredUsers.length })}
      </div>

      <>
        <Table
          data={paginatedUsers}
          columns={columns}
          actions={actions}
          userRole={null}
          setSorting={setSorting}
          sortField={sortField}
          sortOrder={sortOrder}
          noRecordsMessage={t('users.noRecords')}
          actionsHeaderLabel={t('actions')}
        />
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={handlePrevPage}
          onNext={handleNextPage}
          onPageClick={handlePageClick}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          itemsPerPageOptions={[20, 50, 100]}
          itemsPerPageLabel={t('userTable.itemsPerPageLabel', 'Users per page:')}
          previousLabel={t('previousLabel')}
          nextLabel={t('nextLabel')}
          pageLabel={t('pageLabel')}
          isMobileBreakpoint={768}
        />
      </>

      {/* User Modal for Adding/Editing Users */}
      {showUserModal && (
        <CustomModal
          show={showUserModal}
          onClose={() => setShowUserModal(false)}
          title={modalTitle}
        >
          <UserForm
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            roles={roles}
            role={role}
            setRole={setRole}
            isMobile={isMobile}
            onSubmit={handleUserModalSubmit}
            isEditing={editingUser !== null}
            isSubmitting={isSubmittingUser}
          />
        </CustomModal>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={confirmDeleteUser}
          onCancel={() => setShowDeleteModal(false)}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
          isMobile={isMobile}
          isLoading={isDeletingUser}
        />
      )}

      {/* Reset Password Confirmation Modal */}
      {showResetModal && (
        <ResetPasswordConfirmationModal
          show={showResetModal}
          onConfirm={confirmResetPassword}
          onCancel={() => setShowResetModal(false)}
          record={userToReset}
          isMobile={isMobile}
        />
      )}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default Users;
