// src/components/MessageSender.jsx

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import './MessageSender.css';
import MessageInput from './MessageInput';
import ImageUpload from './ImageUpload';
import ImagePreview from './ImagePreview';
import ActionButton from '../Common/ActionButton';
import ErrorMessage from './ErrorMessage';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DataContext } from '../../DataContext';
import SelectField from '../FormBuilder/Fields/SelectField';
import DefaultProfilePicture from '../../assets/UserIcon.png';
import ToastContainer from '../Common/ToastContainer';
import CustomModal from '../Common/CustomModal'; // Import CustomModal

const MessageSender = (props) => {
  const { t } = useTranslation();
  const { onClose, single, recipient } = props;
  const { teams, groupsData, useResponses, organizationData } = useContext(DataContext);

  // Fetch members data using useResponses
  const {
    data: responses = [],
    isLoading: membersLoading,
    error: membersError,
  } = useResponses('membersForm');

  // Map responses to members
  const members = responses.map((response) => {
    const member = {};
    response.fields.forEach((field) => {
      member[field.id] = field.value;
    });
    member.id = response.id;
    member.profilePicture = member.profilePicture || DefaultProfilePicture;
    member.groups = response.groups ? response.groups:[];
    return member;
  });

  const [message, setMessage] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const [recipientType, setRecipientType] = useState('All');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const [toasts, setToasts] = useState([]);

  // New state variables for progress modal
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [currentRecipient, setCurrentRecipient] = useState('');

  // Toast Management
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    let objectUrl;
    if (image) {
      objectUrl = URL.createObjectURL(image);
      setImagePreviewUrl(objectUrl);
    }
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [image]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError('');

    let recipients = [];

    if (single && recipient) {
      recipients = [
        {
          phoneNumber: recipient,
          name: recipient,
        },
      ];
    } else {
      if (recipientType === 'All') {
        // Get all members
        recipients = members
          .filter((member) => member.phoneNumber)
          .map((member) => ({
            phoneNumber: member.phoneNumber,
            name: `${member.firstName} ${member.lastName}`,
          }));
      } else if (recipientType === 'Groups') {
        const selectedGroupIds = selectedGroups.map((group) => group.value);
        console.log(selectedGroupIds);
        recipients = members
          .filter(
            (member) =>
              member.groups && member.groups.some((group) => selectedGroupIds.includes(group.groupId))
          )
          .filter((member) => member.phoneNumber)
          .map((member) => ({
            phoneNumber: member.phoneNumber,
            name: `${member.firstName} ${member.lastName}`,
          }));
      } else if (recipientType === 'Teams') {
        
        const selectedTeamIds = selectedTeams.map((team) => team.value);
        const selectedTeamsData = teams.filter((team) => selectedTeamIds.includes(team.id));
        const recipientMap = new Map();
        selectedTeamsData.forEach((team) => {
          (team.membersData || []).forEach((member) => {
            if (member.phoneNumber) {
              const key = member.phoneNumber;
              if (!recipientMap.has(key)) {
                recipientMap.set(key, {
                  phoneNumber: member.phoneNumber,
                  name: `${member.firstName} ${member.lastName}`,
                });
              }
            }
          });
        });
        recipients = Array.from(recipientMap.values());
      } else if (recipientType === 'Members') {
        const selectedMemberIds = selectedMembers.map((member) => member.value);
        recipients = members
          .filter((member) => selectedMemberIds.includes(member.id))
          .filter((member) => member.phoneNumber)
          .map((member) => ({
            phoneNumber: member.phoneNumber,
            name: `${member.firstName} ${member.lastName}`,
          }));
      }
    }

    if (recipients.length === 0) {
      setError(t('messageSender.noRecipients', 'No recipients found.'));
      setIsLoading(false);
      return;
    }

    setTotalMessages(recipients.length);
    setProgress(0);
    setShowProgressModal(single?false:true);

    const sendMessages = async () => {
      const endpoint = 'https://sendsinglesmsalt-lgfph5hmwq-uc.a.run.app';

      const results = [];
      let index = 0;

      for (const recipient of recipients) {
        setCurrentRecipient(recipient.name || recipient.phoneNumber);
        try {
          const formData = new FormData();
          formData.append('fromPhoneNumber', '+19147329805');
          formData.append('message', message);
          formData.append('toPhoneNumber', recipient.phoneNumber);
          if (image) {
            formData.append('image', image);
          }

          const response = await axios.post(endpoint, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status !== 200) {
            console.error(`Failed to send message to ${recipient.phoneNumber}`);
            results.push({ success: false, phoneNumber: recipient.phoneNumber });
          } else {
            results.push({ success: true, phoneNumber: recipient.phoneNumber });
          }
        } catch (error) {
          console.error(`Error sending message to ${recipient.phoneNumber}:`, error);
          results.push({ success: false, phoneNumber: recipient.phoneNumber });
        }

        index += 1;
        setProgress(index);
      }

      const failedMessages = results.filter((result) => !result.success);

      if (failedMessages.length > 0) {
        showToast(t('messageSender.sendPartialSuccess', 'Some messages failed to send.'), 'warning');
      } else {
        showToast(t('messageSender.sendSuccess', 'Messages sent successfully'), 'success');
      }

      setMessage('');
      setImage(null);
      setImagePreviewUrl(null);
      setIsLoading(false);
      setShowProgressModal(false);
  }

    sendMessages();
  };

  const removeImage = () => {
    setImage(null);
    setImagePreviewUrl(null);
  };

  // Custom styles for react-select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected
        ? 'var(--primary-color)'
        : state.isFocused
        ? '#f0f0f0'
        : '#fff',
    }),
  };

  // Function to format options with profile picture and label
  const formatOptionLabel = ({ label, profilePicture }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {profilePicture ? (
        <img
          src={profilePicture}
          alt={label}
          style={{ width: 30, height: 30, backgroundColor: 'var(--primary-color)' , borderRadius: '50%', marginRight: 10 }}
        />
      ) : (
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: '50%',
            backgroundColor: '#ccc',
            marginRight: 10,
          }}
        ></div>
      )}
      <span>{label}</span>
    </div>
  );

  // Options for recipientType
  const recipientTypeOptions = [
    { value: 'All', label: t('messageSender.sendToAll', 'All Members') },
    ...(organizationData.groupId === organizationData.organizationId
      ? [{ value: 'Groups', label: t('messageSender.sendToGroups', 'Groups') }]
      : []),
    { value: 'Teams', label: t('messageSender.sendToTeams', 'Teams') },
    { value: 'Members', label: t('messageSender.sendToMembers', 'Individual Members') },
  ];

  return (
    <div className="message-container">
      <div className="message-recovery-container">
        <h2>{t('messageSender.title', 'Send Message')}</h2>
        <form onSubmit={handleSubmit} className="login-form">
          {!single && (
            <>
              <SelectField
                label={t('messageSender.recipientType', 'Send To')}
                name="recipientType"
                value={recipientType}
                onChange={(value) => setRecipientType(value)}
                options={recipientTypeOptions}
                required
              />

              {recipientType === 'Groups' && (
                <div className="input-group">
                  <label>{t('messageSender.selectGroups', 'Select Groups')}</label>
                  <Select
                    isMulti
                    options={groupsData.map((group) => ({
                      value: group.id,
                      label: group.name || group.groupName,
                      profilePicture: group.profilePicture || DefaultProfilePicture,
                    }))}
                    value={selectedGroups}
                    onChange={(selectedOptions) => setSelectedGroups(selectedOptions)}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                    classNamePrefix="react-select"
                  />
                </div>
              )}

              {recipientType === 'Teams' && (
                <div className="input-group">
                  <label>{t('messageSender.selectTeams', 'Select Teams')}</label>
                  <Select
                    isMulti
                    options={teams.map((team) => ({
                      value: team.id,
                      label: team.name,
                    }))}
                    value={selectedTeams}
                    onChange={(selectedOptions) => setSelectedTeams(selectedOptions)}
                    classNamePrefix="react-select"
                  />
                </div>
              )}

              {recipientType === 'Members' && (
                <div className="input-group">
                  <label>{t('messageSender.selectMembers', 'Select Members')}</label>
                  <Select
                    isMulti
                    options={members.map((member) => ({
                      value: member.id,
                      label: `${member.firstName} ${member.lastName}`,
                      profilePicture: member.profilePicture || DefaultProfilePicture,
                    }))}
                    value={selectedMembers}
                    onChange={(selectedOptions) => setSelectedMembers(selectedOptions)}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                    classNamePrefix="react-select"
                  />
                </div>
              )}
            </>
          )}
          <div className="input-group">
            <MessageInput message={message} setMessage={setMessage} />
            <ImageUpload setImage={setImage} />
            {imagePreviewUrl && (
              <ImagePreview imagePreviewUrl={imagePreviewUrl} removeImage={removeImage} />
            )}
          </div>
          {error && <ErrorMessage error={error} />}
          <ActionButton
            type="submit"
            text={
              isLoading
                ? t('messageSender.sending', 'Sending...')
                : t('messageSender.sendMessage', 'Send Message')
            }
            icon="faPaperPlane"
            disabled={isLoading}
            size="large"
            fullWidth={true}
            colorType="primary"
          />
        </form>
      </div>

      {/* Progress Modal */}
      {showProgressModal && (
        <CustomModal
          show={showProgressModal}
          onClose={() => {}}
          title={t('messageSender.sendingMessages', 'Sending Messages')}
          closable={false} // Disable close button while sending
        >
          <div className="progress-modal-content">
            <p>
              {t('messageSender.sendingTo', 'Sending to')}: {currentRecipient}
            </p>
            <progress value={progress} max={totalMessages}></progress>
            <p>
              {progress} / {totalMessages} {t('messageSender.messagesSent', 'messages sent')}
            </p>
          </div>
        </CustomModal>
      )}

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

MessageSender.propTypes = {

  single: PropTypes.bool,
  recipient: PropTypes.string,
};

MessageSender.defaultProps = {
  single: false,
  recipient: '',
};

export default MessageSender;
