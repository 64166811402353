// src/utils/CSVUtility.js

import Papa from 'papaparse';

/**
 * Checks if a value is a valid Date object.
 * @param {Date} date - The date object to validate.
 * @returns {boolean} - True if valid date, false otherwise.
 */
export const isValidDate = (date) => {
  if (!date) return false;
  const parsedDate = new Date(date);
  return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
};

/**
 * Converts various timestamp formats to an ISO date string.
 * Supports Firestore Timestamp objects and date strings.
 * @param {Object|string|number} timestamp - The timestamp to convert.
 * @returns {string|null} - ISO date string or null if invalid.
 */
const convertTimestampToISO = (timestamp) => {
  if (!timestamp) return null;

  if (typeof timestamp.toDate === 'function') {
    // Firestore Timestamp object with toDate() method
    return timestamp.toDate().toISOString();
  } else if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
    // Firestore Timestamp object with seconds and nanoseconds
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6).toISOString();
  } else if (typeof timestamp === 'string' || typeof timestamp === 'number') {
    const date = new Date(timestamp);
    return isValidDate(date) ? date.toISOString() : null;
  } else if (timestamp instanceof Date) {
    return timestamp.toISOString();
  } else {
    return null;
  }
};

/**
 * Converts a CSV date value to an ISO date string.
 * Supports both ISO strings and timestamp integers.
 * @param {string|number} csvValue - The CSV date value.
 * @returns {string|null} - ISO date string or null if invalid.
 */
const convertToISODateString = (csvValue) => {
  if (!csvValue) return null;

  let date;

  if (typeof csvValue === 'number' || /^\d+$/.test(csvValue)) {
    // Assume it's a timestamp in seconds
    const timestampInSeconds = Number(csvValue);
    date = new Date(timestampInSeconds * 1000);
  } else {
    // Assume it's an ISO string
    date = new Date(csvValue);
  }

  return isValidDate(date) ? date.toISOString() : null;
};

/**
 * Generates a CSV string from responses using Papa.unparse.
 * @param {Array} responses - Array of response objects.
 * @param {Array} fields - Array of field definitions from the form.
 * @returns {string} - CSV content.
 */
export const generateCSV = (responses, fields) => {
  if (!responses || responses.length === 0) return '';

  const csvData = [];

  // Start with fixed headers
  const headers = ['id', 'submittedDateTime'];

  // Collect headers based on the fields in order
  fields.forEach((field) => {
    if (field.type === 'Section' || field.type === 'ImagePicker') return;

    if (field.type === 'Checkbox' && Array.isArray(field.options)) {
      field.options.forEach((option) => {
        const headerKey = `${field.id}_${option.value}`;
        headers.push(headerKey);
      });
    } else if (field.type === 'MultiRowControl' && Array.isArray(field.rowFields)) {
      // For MultiRowControl, we need to handle variable number of rows
      // Collect all possible row indices across responses for this field
      const maxRows = Math.max(
        ...responses.map((response) => {
          const fieldData = response.fields.find((f) => f.id === field.id);
          if (fieldData && Array.isArray(fieldData.value)) {
            return fieldData.value.length;
          }
          return 0;
        })
      );

      // For each possible row, add headers for each sub-field
      for (let rowIndex = 0; rowIndex < maxRows; rowIndex++) {
        field.rowFields.forEach((rowField) => {
          const headerKey = `${field.id}_${rowIndex + 1}_${rowField.id}`;
          headers.push(headerKey);
        });
      }
    } else {
      headers.push(field.id);
    }
  });

  // Prepare data rows
  responses.forEach((response) => {
    const rowData = {
      id: response.id,
      submittedDateTime: response.submittedAt
        ? convertTimestampToISO(response.submittedAt) || 'Unknown Date'
        : 'Unknown Date',
    };

    // Create a map of field values for easy access
    const fieldValuesMap = {};
    response.fields.forEach((field) => {
      fieldValuesMap[field.id] = field;
    });

    // Populate rowData based on headers
    headers.forEach((header) => {
      if (header === 'id' || header === 'submittedDateTime') return;

      const fieldId = header.split('_')[0];
      const field = fieldValuesMap[fieldId];

      if (!field) {
        // Field not present in this response
        rowData[header] = '';
        return;
      }

      if (field.type === 'Checkbox' && Array.isArray(field.value)) {
        // Checkbox options are stored as separate columns
        const optionName = header.slice(fieldId.length + 1);
        const option = field.value.find((opt) => opt.name === optionName);
        rowData[header] = option && option.completed ? 'Yes' : 'No';
      } else if (field.type === 'MultiRowControl' && Array.isArray(field.value)) {
        // Extract row index and sub-field id
        const parts = header.split('_');
        const rowIndex = parseInt(parts[1], 10) - 1; // Convert to zero-based index
        const subFieldId = parts.slice(2).join('_'); // In case subFieldId contains underscores

        const row = field.value[rowIndex];
        if (row && row[subFieldId] !== undefined) {
          rowData[header] = row[subFieldId];
        } else {
          rowData[header] = '';
        }
      } else {
        // Regular field
        if (
          field.type === 'DatePicker' ||
          field.type === 'TimePicker' ||
          field.type === 'DateTimePicker'
        ) {
          rowData[header] = convertTimestampToISO(field.value) || '';
        } else if (field.type === 'ToggleSwitch') {
          rowData[header] = field.value ? 'Yes' : 'No';
        } else {
          rowData[header] =
            field.value !== null && field.value !== undefined
              ? field.value.toString()
              : '';
        }
      }
    });

    csvData.push(rowData);
  });

  // Use Papa.unparse for efficient CSV generation
  const csvContent = Papa.unparse({
    fields: headers,
    data: csvData,
  });

  return csvContent;
};

/**
 * Parses a CSV file and returns the data.
 * @param {File} file - The CSV file to parse.
 * @returns {Promise<Array>} - Promise resolving to array of parsed rows.
 */
export const parseCSV = (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true, // Assumes first row is headers
      skipEmptyLines: true,
      complete: (results) => {
        if (results.errors.length) {
          reject(results.errors);
        } else {
          resolve(results.data);
        }
      },
      error: (error) => {
        reject(error);
      },
    });
  });
};

/**
 * Maps a CSV row to responseData structure based on fields, formId, and organizationData.
 * @param {Object} row - The CSV row object.
 * @param {Array} fields - Array of field definitions.
 * @param {string} formId - The form ID.
 * @param {Object} organizationData - Organization data containing groupId and organizationId.
 * @param {string} defaultImage - The default image URL for ImagePicker fields.
 * @returns {Object} - Mapped response data.
 */
export const mapCSVRowToResponseData = (
  row,
  fields,
  formId,
  organizationData,
  defaultImage
) => {
  let order = 0;
  const mappedFields = fields
    .filter((field) => field.type !== 'Section')
    .map((field) => {
      order += 1;
      const { id, type, label } = field;

      let value;

      switch (type) {
        case 'Checkbox':
          // For Checkbox, assume multiple CSV columns: fieldId_optionName
          value = field.options.map((option) => ({
            name: option.value || '',
            completed: ['true', '1', 'yes', 'Yes'].includes(
              (row[`${id}_${option.value}`] || '').toLowerCase()
            ),
          }));
          break;

        case 'Select':
        case 'RadioButton':
          value = row[id] || '';
          break;

        case 'Telephone':
          value = validateAndFormatPhone(row[id] || '');
          break;

        case 'Email':
        case 'ColorPicker':
        case 'Text':
        case 'TextArea':
          value = row[id] || '';
          break;

        case 'ToggleSwitch':
          value = ['true', '1', 'yes', 'Yes'].includes((row[id] || '').toLowerCase());
          break;

        case 'DatePicker':
        case 'TimePicker':
        case 'DateTimePicker':
          value = convertToISODateString(row[id] || '');
          break;

        case 'RangeSlider':
          value = Number(row[id]);
          break;

        case 'MultiRowControl':
          // Reconstruct the value array from CSV columns
          const multiRowValues = [];
          const fieldHeaders = Object.keys(row).filter((key) => key.startsWith(`${id}_`));

          fieldHeaders.forEach((fullKey) => {
            const parts = fullKey.split('_');
            if (parts.length < 3) return;

            const rowIndex = parseInt(parts[1], 10) - 1; // Convert to zero-based index
            const subFieldId = parts.slice(2).join('_'); // In case subFieldId contains underscores

            if (isNaN(rowIndex) || !subFieldId) return;

            if (!multiRowValues[rowIndex]) {
              multiRowValues[rowIndex] = {};
            }
            multiRowValues[rowIndex][subFieldId] = row[fullKey];
          });

          // Remove any undefined entries in the array
          value = multiRowValues.filter((row) => row && Object.keys(row).length > 0);
          break;

        case 'ImagePicker':
          // Set to default image if CSV does not provide an image URL
          value = (row[id] || '').trim() !== '' ? row[id] : defaultImage;
          break;

        default:
          value = row[id] || '';
      }

      return {
        id,
        type,
        label,
        order,
        value,
      };
    });

  const responseData = {
    fields: mappedFields,
    // Timestamps will be set in DataContext
  };

  // Handle 'submittedDateTime' from CSV if present
  if (row.submittedDateTime) {
    const convertedSubmittedAt = convertToISODateString(row.submittedDateTime);
    if (convertedSubmittedAt) {
      responseData.submittedAt = convertedSubmittedAt;
      responseData.createdAt = convertedSubmittedAt;
      responseData.updatedAt = convertedSubmittedAt;
    }
  }

  // Conditional logic for membersForm
  if (formId === 'membersForm') {
    const groupEntry = {
      groupId: organizationData.groupId,
      memberType: 'member',
      organizationId: organizationData.organizationId,
    };
    responseData.groups = [groupEntry];
  }

  return responseData;
};

/**
 * Validates and formats a phone number to E.164 standard.
 * @param {string} phone - The phone number to validate and format.
 * @returns {string|null} - Formatted phone number or null if invalid.
 */
const validateAndFormatPhone = (phone) => {
  if (!phone) return null;

  // Remove all non-digit and non-plus characters
  let cleanedPhone = phone.replace(/[^0-9+]/g, '');

  // Handle possible '+1' prefix
  if (cleanedPhone.startsWith('+1')) {
    cleanedPhone = cleanedPhone.slice(2);
  } else if (cleanedPhone.length === 11 && cleanedPhone.startsWith('1')) {
    cleanedPhone = cleanedPhone.slice(1);
  }

  // After removing prefixes, the number should be exactly 10 digits
  if (cleanedPhone.length === 10) {
    return `+1${cleanedPhone}`;
  } else {
    return null;
  }
};

/**
 * Validates response data based on fields.
 * @param {Object} responseData - The response data to validate.
 * @param {Array} fields - Array of field definitions.
 * @returns {Object|null} - Errors object or null if no errors.
 */
export const validateResponseData = (responseData, fields) => {
  const errors = {};

  const fieldMap = fields.reduce((acc, field) => {
    acc[field.id] = field;
    return acc;
  }, {});

  responseData.fields.forEach((field) => {
    if (field.type === 'Section') return;

    const { id, type, value } = field;
    const originalField = fieldMap[id];

    if (originalField.required) {
      if (type === 'Checkbox') {
        const isAnyCompleted = value.some((option) => option.completed);
        if (!isAnyCompleted) {
          errors[id] = `${originalField.label} is required. At least one option must be selected.`;
        }
      } else if (
        type !== 'Checkbox' &&
        type !== 'MultiRowControl' &&
        type !== 'ToggleSwitch' &&
        (value === '' || value === null || value === undefined)
      ) {
        errors[id] = `${originalField.label} is required.`;
      }

      if (type === 'Telephone') {
        if (value === null && originalField.required) {
          errors[id] = `${originalField.label} must be a valid phone number.`;
        }
      }

      if (
        type === 'MultiRowControl' &&
        Array.isArray(value) &&
        value.length === 0
      ) {
        errors[id] = `${originalField.label} requires at least one row.`;
      }

      if (
        (type === 'DatePicker' ||
          type === 'TimePicker' ||
          type === 'DateTimePicker') &&
        !value
      ) {
        errors[id] = `${originalField.label} requires a valid date/time.`;
      }

      if (type === 'RangeSlider') {
        const { min, max } = originalField;
        if (
          typeof min === 'number' &&
          typeof max === 'number' &&
          (value < min || value > max)
        ) {
          errors[id] = `${originalField.label} must be between ${min} and ${max}.`;
        }
      }

      if (type === 'ColorPicker') {
        const isValidColor = /^#([0-9A-F]{3}){1,2}$/i.test(value);
        if (!isValidColor) {
          errors[id] = `${originalField.label} must be a valid color code.`;
        }
      }
    }
  });

  return Object.keys(errors).length > 0 ? errors : null;
};

/**
 * Downloads a CSV file given its content and filename.
 * @param {string} csvContent - The CSV content as a string.
 * @param {string} filename - The desired filename for the downloaded CSV.
 */
export const downloadCSV = (csvContent, filename = 'responses.csv') => {
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Check for navigator.msSaveBlob (IE 10+)
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    // For Firefox, it is necessary to delay revoking the ObjectURL
    setTimeout(() => {
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    }, 100);
  }
};
