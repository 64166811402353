// src/components/FormBuilder/FormManager.jsx

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import FormBuilder from './FormBuilder';
import FillOutForm from './FillOutForm';
import ResponsesTab from './ResponsesTab';
import ShareTab from './ShareTab';
import GeneralTab from './GeneralTab';
import Tabs from './Tabs';
import { DataContext } from '../../DataContext';
import './FormManager.css';
import { useTranslation } from 'react-i18next';
import ToastContainer from '../Common/ToastContainer';

const FormManager = ({
  initialData = null,
  initialActiveTab = 'general',
  onUpdate,
}) => {
  const { t } = useTranslation();
  const {
    organizationData,
    saveForm,
    fetchResponsesForForm,
    deleteResponse,
    refetchAllForms,
  } = useContext(DataContext);

  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [responses, setResponses] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState(initialData?.id || null);

  // State for form data
  const [formName, setFormName] = useState('');
  const [isClosed, setIsClosed] = useState(false);
  const [formData, setFormData] = useState(initialData || {});
  const [isSavingGeneral, setIsSavingGeneral] = useState(false);

  // Toasts state
  const [toasts, setToasts] = useState([]);

  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    if (initialData) {
      setFormName(initialData.name || '');
      setIsClosed(initialData.isClosed || false);
      setSelectedFormId(initialData.id);
      setFormData(initialData);
      if (initialActiveTab === 'responses') {
        setActiveTab('responses');
      } else {
        setActiveTab('general');
      }
    } else {
      setActiveTab(initialActiveTab);
    }
  }, [initialData, initialActiveTab]);

  const handleUpdateForm = async (updatedFormData) => {
    try {
      await saveForm(selectedFormId, updatedFormData);
      // Update local state
      setFormName(updatedFormData.name);
      setIsClosed(updatedFormData.isClosed);
      setFormData(updatedFormData);
      // Refetch forms to ensure data is up to date
      refetchAllForms();
      if (onUpdate) {
        onUpdate(updatedFormData);
      }
    } catch (error) {
      showToastMessage(t('formManager.errorUpdatingForm'), 'error');
    }
  };

  const handleSaveGeneral = async () => {
    if (!formName.trim()) {
      showToastMessage(t('formManager.formNameRequired'), 'error');
      return;
    }

    setIsSavingGeneral(true);

    try {
      // Check if the form name is editable
      const isNameEditable = !['membersForm', 'groupsForm'].includes(selectedFormId);

      // Prepare updated form data
      const updatedFormData = {
        isClosed,
      };

      // Only update the name if it's editable
      if (isNameEditable) {
        updatedFormData.name = formName.trim();
      }

      if (selectedFormId) {
        // Update existing form
        await saveForm(selectedFormId, updatedFormData);
        showToastMessage(
          isNameEditable
            ? t('formManager.generalInfoUpdated')
            : t('formManager.generalInfoUpdatedWithoutName'),
          'success'
        );
        setFormData((prevData) => ({ ...prevData, ...updatedFormData }));
      } else {
        // Create new form
        const newFormId = await saveForm(null, updatedFormData);
        showToastMessage(t('formManager.newFormCreated'), 'success');
        setSelectedFormId(newFormId);
        setActiveTab('builder');
        setFormData({ id: newFormId, ...updatedFormData });
      }
      refetchAllForms(); // Refresh forms list
    } catch (error) {
      console.error('Error saving general settings:', error);
      showToastMessage(t('formManager.errorSavingGeneral'), 'error');
    } finally {
      setIsSavingGeneral(false);
    }
  };

  useEffect(() => {
    if (activeTab !== 'responses') return;

    const fetchResponses = async () => {
      try {
        const fetchedResponses = await fetchResponsesForForm(selectedFormId);
        setResponses(fetchedResponses);
      } catch (error) {
        console.error('Error fetching responses:', error);
        showToastMessage(t('formManager.errorFetchingResponses'), 'error');
      }
    };

    if (selectedFormId) {
      fetchResponses();
    }
  }, [activeTab, selectedFormId, fetchResponsesForForm, t]);

  const handleDeleteResponse = async (responseId) => {
    try {
      await deleteResponse(selectedFormId, responseId);
      setResponses((prevResponses) => prevResponses.filter((r) => r.id !== responseId));
      showToastMessage(t('formManager.responseDeleted'), 'success');
    } catch (error) {
      console.error('Error deleting response:', error);
      showToastMessage(t('formManager.errorDeletingResponse'), 'error');
    }
  };

  return (
    <div className="form-manager-container">
      <div className="form-content">
        <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="tab-content-container">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={activeTab}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div className="tab-content">
                {activeTab === 'general' && (
                  <GeneralTab
                    formId={selectedFormId} // Pass formId here
                    formName={formName}
                    setFormName={setFormName}
                    isClosed={isClosed}
                    setIsClosed={setIsClosed}
                    onSave={handleSaveGeneral}
                    isSaving={isSavingGeneral}
                  />
                )}
                {activeTab === 'builder' && (
                  selectedFormId ? (
                    <FormBuilder
                      initialData={formData}
                      onUpdate={handleUpdateForm}
                      showToast={showToastMessage}
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToBuild')}
                    </div>
                  )
                )}
                {activeTab === 'fill' && (
                  selectedFormId ? (
                    <FillOutForm
                      fields={formData.fields || []}
                      formId={selectedFormId}
                      showToast={showToastMessage}
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToFill')}
                    </div>
                  )
                )}
                {activeTab === 'responses' && (
                  selectedFormId ? (
                    <ResponsesTab
                      formId={selectedFormId}
                      fields={formData.fields || []}
                      responses={responses}
                      showToast={showToastMessage}
                      onDeleteResponse={handleDeleteResponse}
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToViewResponses')}
                    </div>
                  )
                )}
                {activeTab === 'share' && (
                  selectedFormId ? (
                    <ShareTab
                      formId={selectedFormId}
                      formName={formName}
                      groupId={organizationData.groupId}
                      showToast={showToastMessage}
                    />
                  ) : (
                    <div className="info-message">
                      {t('formManager.assignFormNameToShare')}
                    </div>
                  )
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>

      {/* Toasts */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

FormManager.propTypes = {
  initialData: PropTypes.object,
  initialActiveTab: PropTypes.oneOf([
    'general',
    'builder',
    'fill',
    'responses',
    'share',
  ]),
  onUpdate: PropTypes.func.isRequired,
};

FormManager.defaultProps = {
  initialData: null,
  initialActiveTab: 'general',
};

export default FormManager;
