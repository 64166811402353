// src/components/Common/IconMap.js

import * as SolidIcons from '@fortawesome/free-solid-svg-icons';

const IconMap = Object.entries(SolidIcons)
  .filter(([key]) => key.startsWith('fa'))
  .reduce((map, [key, value]) => {
    map[key] = value;
    return map;
  }, {});

export default IconMap;
