// src/components/Authentication/PasswordRecovery.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../Common/CustomModal';
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import './PasswordRecovery.css';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';

const PasswordRecovery = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Access appSettings and authentication functions from DataContext
  const { appSettings, appSettingsLoading, sendPasswordResetEmail } = useContext(DataContext);

  // Access language settings from LanguageSettingsContext
  const { loading: languageLoading } = useLanguageSettings();

  // Access loading state from ColorSettingsContext
  const { loading: colorLoading } = useColorSettings();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await sendPasswordResetEmail(email);
      setMessage(t('passwordRecovery.successMessage'));
      setShowModal(true);
    } catch (error) {
      setMessage(t('passwordRecovery.error', { error: error.message }));
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/');
  };

  const goToLogin = () => {
    navigate('/');
  };

  // Handle loading state by combining loading states
  if (colorLoading || languageLoading || appSettingsLoading) {
    return (
      <div className="password-recovery-background">
        <div className="password-recovery-container">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="password-recovery-background">
      <div className="password-recovery-container">
        <div className="password-recovery-logo">
          <img
            src={appSettings?.profilePicture || CatholicoreLogo}
            alt={t('passwordRecovery.logoAlt')}
          />
        </div>
        <h2>{t('passwordRecovery.title')}</h2>
        <form onSubmit={handleSubmit} className="password-recovery-form">
          <div className="password-recovery-input-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('passwordRecovery.emailPlaceholder')}
              required
              aria-label={t('passwordRecovery.emailLabel')}
            />
          </div>
          <button type="submit" className="password-recovery-submit-button">
            {t('passwordRecovery.resetButton')}
          </button>
          <br />
          <br />
          <h6 onClick={goToLogin} className="password-recovery-forgot">
            {t('passwordRecovery.backToLogin')}
          </h6>
        </form>
      </div>
      <CustomModal
        show={showModal}
        onClose={handleCloseModal}
        title={t('passwordRecovery.modalTitle')}
      >
        <p className="password-recovery-message">{message}</p>
      </CustomModal>
    </div>
  );
};

export default PasswordRecovery;
