// src/utils/PDFUtility.js

import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * Converts a Base64 image to a Base64 image with a white background.
 * @param {string} base64Image - The original Base64 image string.
 * @returns {Promise<string>} - A promise that resolves to the new Base64 image string.
 */
const convertImageToBase64WithWhiteBackground = (base64Image) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      // Fill the background with white
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the image on top
      ctx.drawImage(img, 0, 0);

      // Get the new Base64 image
      const newBase64Image = canvas.toDataURL('image/png');

      resolve(newBase64Image);
    };
    img.onerror = reject;
    img.src = base64Image;
  });
};

/**
 * Generates a PDF from the given responses and fields.
 * @param {Array} responses - Array of response objects.
 * @param {Array} fields - Array of field definitions.
 * @param {boolean} isIndividual - If true, generates individual PDFs; otherwise, combines all responses into one PDF.
 * @param {string} groupLogo - Base64 string of the group logo image.
 * @param {string} formName - Name of the form.
 */
export const generatePDF = async (
  responses,
  fields,
  isIndividual = false,
  groupLogo = null,
  formName = 'Form'
) => {
  if (!responses || responses.length === 0) {
    console.warn('No responses to generate PDF.');
    return;
  }

  let processedGroupLogo = groupLogo;

  if (groupLogo) {
    try {
      processedGroupLogo = await convertImageToBase64WithWhiteBackground(groupLogo);
    } catch (error) {
      console.error('Failed to process groupLogo:', error);
    }
  }

  if (isIndividual) {
    // Generate individual PDFs
    responses.forEach((response) => {
      const doc = new jsPDF();

      addResponseToPDF(doc, response, fields, processedGroupLogo, formName);

      doc.save(`${formName.replace(/\s+/g, '_')}_${response.id}.pdf`);
    });
  } else {
    // Generate a single PDF with all responses
    const doc = new jsPDF();

    responses.forEach((response, index) => {
      if (index > 0) {
        doc.addPage();
      }
      addResponseToPDF(doc, response, fields, processedGroupLogo, formName);
    });

    doc.save(`${formName.replace(/\s+/g, '_')}_responses.pdf`);
  }
};

/**
 * Adds a response to the PDF document.
 * @param {jsPDF} doc - The jsPDF document.
 * @param {Object} response - The response object.
 * @param {Array} fields - The fields definitions.
 * @param {string} groupLogo - Base64 string of the group logo image.
 * @param {string} formName - Name of the form.
 */
const addResponseToPDF = (
  doc,
  response,
  fields,
  groupLogo,
  formName
) => {
  const margin = 10;
  let y = margin;

  // Set global styles
  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(12);

  const pageWidth = doc.internal.pageSize.getWidth();

  // Add group logo if provided
  if (groupLogo) {
    // Define image dimensions
    const imgSize = 40; // Adjusted size
    const imgX = (pageWidth - imgSize) / 2;
    const imgY = y;

    // Add the logo image centered
    doc.addImage(groupLogo, 'PNG', imgX, imgY, imgSize, imgSize);

    y += imgSize + 10; // Adjusted spacing below the logo
  }

  // Add form name with adjusted font size
  doc.setFontSize(16);
  doc.setFont('Helvetica', 'bold');
  doc.text(formName, pageWidth / 2, y, { align: 'center' });
  y += 10; // Adjusted spacing below title

  // Add a horizontal line
  doc.setLineWidth(0.5);
  doc.setDrawColor(150);
  doc.line(margin, y, pageWidth - margin, y);
  y += 5; // Spacing below line

  // Prepare data for the table, excluding ImagePicker fields
  const tableData = response.fields
    .filter((field) => field.type !== 'ImagePicker')
    .map((field) => {
      const fieldDef = fields.find((f) => f.id === field.id);
      const value = formatFieldValue(field, fieldDef);

      return {
        Label: field.label,
        Value: value,
        Type: field.type, // Include the type to identify MultiRowControl
        FieldDef: fieldDef, // Include fieldDef for further processing
      };
    });

  // Arrange data into table rows
  const tableRows = [];
  let i = 0;
  while (i < tableData.length) {
    const item = tableData[i];
    if (item.Type === 'MultiRowControl') {
      // Add as a single row spanning all columns
      tableRows.push([
        {
          content: `${item.Label}: ${item.Value}`,
          colSpan: 2,
          styles: { fontStyle: 'bold' },
        },
      ]);
      i++;
    } else {
      // Prepare a row with up to two items
      const row = [];
      // First item
      row.push({
        content: `${item.Label}: ${item.Value}`,
        styles: { fontStyle: 'normal' },
      });

      // Second item, if available and not a MultiRowControl
      if (
        i + 1 < tableData.length &&
        tableData[i + 1].Type !== 'MultiRowControl'
      ) {
        const item2 = tableData[i + 1];
        row.push({
          content: `${item2.Label}: ${item2.Value}`,
          styles: { fontStyle: 'normal' },
        });
        i += 2;
      } else {
        // Fill with empty cell
        row.push('');
        i++;
      }

      tableRows.push(row);
    }
  }

  // AutoTable options with adjusted styles
  doc.autoTable({
    startY: y,
    margin: { left: margin, right: margin },
    theme: 'grid',
    styles: { font: 'Helvetica', fontSize: 10, cellPadding: 2 },
    columnStyles: {
      0: { cellWidth: 'auto', halign: 'left' },
      1: { cellWidth: 'auto', halign: 'left' },
    },
    head: [],
    body: tableRows,
    didParseCell: function (data) {
      if (data.section === 'body') {
        if (data.row.index % 2 === 0) {
          data.cell.styles.fillColor = [245, 245, 245]; // Light gray background for even rows
        }
      }
    },
  });
};

/**
 * Formats the field value for display in the PDF.
 * @param {Object} field - The field object.
 * @param {Object} fieldDef - The field definition.
 * @returns {string} - The formatted field value.
 */
const formatFieldValue = (field, fieldDef) => {
  const { value, type } = field;
  let formattedValue;

  switch (type) {
    case 'MultiRowControl':
      if (
        !value ||
        !Array.isArray(value) ||
        !fieldDef ||
        !fieldDef.rowFields
      ) {
        formattedValue = '';
        break;
      }
      // Convert the multi-row data to a string representation
      formattedValue = value
        .map((row, index) => {
          const rowValues = fieldDef.rowFields
            .map(
              (rowField) =>
                `${rowField.label}: ${row[rowField.id] || ''}`
            )
            .join(', ');
          return `Row ${index + 1}: ${rowValues}`;
        })
        .join('\n');
      break;
    case 'Checkbox':
      if (Array.isArray(value)) {
        formattedValue = value
          .map(
            (option) =>
              `${option.name}: ${option.completed ? 'Yes' : 'No'}`
          )
          .join(', ');
      } else {
        formattedValue = '';
      }
      break;
    case 'DatePicker':
      formattedValue = value ? new Date(value).toLocaleDateString() : '';
      break;
    case 'DateTimePicker':
    case 'TimePicker':
      formattedValue = value ? new Date(value).toLocaleString() : '';
      break;
    case 'ToggleSwitch':
      formattedValue = value ? 'Yes' : 'No';
      break;
    default:
      if (Array.isArray(value)) {
        formattedValue = value.join(', ');
      } else {
        formattedValue =
          value !== null && value !== undefined
            ? value.toString()
            : '';
      }
  }

  // Capitalize value except for emails
  if (
    fieldDef &&
    fieldDef.type !== 'EmailInput' &&
    typeof formattedValue === 'string'
  ) {
    formattedValue = formattedValue
      .split(' ')
      .map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ');
  }

  return formattedValue;
};
