// src/components/FormBuilder/Fields/SelectField.jsx

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { DataContext } from '../../../DataContext'; // Import DataContext
import './SelectField.css';

const SelectField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  catalogId,
  options: customOptions,
  required, // Added required prop
  error,    // Added error prop for displaying validation errors
  disabled, // Added disabled prop to handle disabling the field
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { fetchCatalogOptions } = useContext(DataContext);

  // Fetch options based on catalogId or use customOptions
  useEffect(() => {
    const fetchOptions = async () => {
      if (catalogId) {
        setIsLoading(true);
        try {
          const fetchedOptions = await fetchCatalogOptions(catalogId);
          setOptions(fetchedOptions);
        } catch (error) {
          console.error('Error fetching catalog options:', error);
          setOptions([]);
        } finally {
          setIsLoading(false);
        }
      } else if (customOptions && Array.isArray(customOptions)) {
        const formattedCustomOptions = customOptions.map((option) => ({
          value: option.value,
          label: option.label,
        }));
        setOptions(formattedCustomOptions);
      } else {
        setOptions([]);
      }
    };

    fetchOptions();
  }, [catalogId, customOptions, fetchCatalogOptions]);

  const handleChange = (selectedOption) => {
    onChange(selectedOption ? selectedOption.value : '');
  };

  // Find the selected option based on value
  const selectedOption = options.find((option) => option.value === value) || null;

  return (
    <div className={`form-group select-field ${error ? 'has-error' : ''}`}>
      <label htmlFor={name}>
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <Select
        id={name}
        name={name}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        isLoading={isLoading}
        placeholder={placeholder}
        isClearable
        className="react-select-container"
        classNamePrefix="react-select"
        isDisabled={disabled}
      />
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string, // Assuming single select
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  catalogId: PropTypes.string, // ID of the catalog document to fetch options from
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ), // Custom options
  required: PropTypes.bool, // Added required prop
  error: PropTypes.string,   // Added error prop
  disabled: PropTypes.bool,  // Added disabled prop
};

SelectField.defaultProps = {
  value: '',
  placeholder: 'Select an option',
  catalogId: '',
  options: [],
  required: false, // Default to not required
  error: '',       // Default to no error
  disabled: false, // Default to enabled
};

export default SelectField;
