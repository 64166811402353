// src/components/LanguageSelector.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './LanguageSelector.css';
import { useTranslation } from 'react-i18next';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import Spinner from '../Common/Spinner';

const LanguageSelector = ({ language, handleLanguageChange }) => {
  const { t } = useTranslation();

  const { language: contextLanguage, updateLanguage, loading: languageLoading } = useLanguageSettings();
  const { appSettings, loading: colorLoading } = useColorSettings();

  if (colorLoading || languageLoading) {
    return (
      <div className="language-selector-row">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="language-selector-row">
      <label htmlFor="language" className="language-label">
        {t('Language')}:
      </label>
      <select
        id="language"
        className="language-select"
        value={language}
        onChange={handleLanguageChange}
      >
        <option value="en">English</option>
        <option value="es">Español</option>
        <option value="pt">Português</option>
        <option value="pl">Polski</option>
        {/* Add more languages as needed */}
      </select>
    </div>
  );
};

LanguageSelector.propTypes = {
  language: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
};

export default LanguageSelector;
