// src/components/Common/ToastContainer.jsx

import React from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import PropTypes from 'prop-types';
import Toast from './Toast';
import './ToastContainer.css';

const ToastContainer = ({ toasts, removeToast }) => {
  // Create a portal root if it doesn't exist
  const toastRoot = document.getElementById('toast-root') || createToastRoot();

  return ReactDOM.createPortal(
    <div className="toast-container">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          type={toast.type}
          onClose={() => removeToast(toast.id)}
          duration={toast.duration}
        />
      ))}
    </div>,
    toastRoot
  );
};

// Function to create a toast root if it doesn't exist
const createToastRoot = () => {
  const toastRoot = document.createElement('div');
  toastRoot.setAttribute('id', 'toast-root');
  document.body.appendChild(toastRoot);
  return toastRoot;
};

ToastContainer.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      duration: PropTypes.number,
    })
  ).isRequired,
  removeToast: PropTypes.func.isRequired,
};

export default ToastContainer;
