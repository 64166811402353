// src/components/Authentication/Login.jsx

import React, { useState, useContext } from 'react';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import CustomModal from '../Common/CustomModal';
import './Login.css';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
import CatholicoreLogoWhite from '../../assets/CatholicoreLogoWhite.png';

// Import contexts
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Access DataContext
  const {
    appSettingsLoading,
    organizationData,
    currentUser,
    currentUserLoading,
    currentUserError,
    signIn,
    appSettings,
  } = useContext(DataContext);

  // Access color settings
  const { loading: colorLoading } = useColorSettings();

  // Access language settings
  const { loading: languageLoading } = useLanguageSettings();

  // Handle modal close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setShowModal(false);

    try {
      await signIn(email, password);
      // Navigation is handled by currentUser change
    } catch (error) {
      console.error('Authentication Error:', error);
      setMessage(t('login.authenticationError'));
      setShowModal(true);
    }
  };

  // Combined loading state
  const isLoading = currentUserLoading || appSettingsLoading || colorLoading || languageLoading;

  // Handle loading state
  if (isLoading) {
    return (
      <div className="login-background">
        {/* White Logo Positioned at Top Left */}
        <div className="login-white-logo">
          <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
        </div>
        <div className="login-container">
          <div className="login-logo">
            <img
              src={appSettings?.profilePicture || CatholicoreLogo}
              alt={t('login.logoAlt')}
            />
          </div>
          <h2>{t('login.title')}</h2>
          <Spinner />
        </div>
      </div>
    );
  }

  // Handle authentication error
  if (currentUserError) {
    return (
      <div className="login-background">
        {/* White Logo Positioned at Top Left */}
        <div className="login-white-logo">
          <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
        </div>
        <div className="login-container">
          <div className="login-logo">
            <img
              src={appSettings?.profilePicture || CatholicoreLogo}
              alt={t('login.logoAlt')}
            />
          </div>
          <h2>{t('login.title')}</h2>
          <p>{t('login.authError', { error: currentUserError.message })}</p>
        </div>
      </div>
    );
  }

  // Redirect if user is authenticated
  if (currentUser) {
    return <Navigate to={`/${organizationData.groupId}/main`} replace />;
  }

  // Render the login form
  return (
    <div className="login-background">
      {/* White Logo Positioned at Top Left */}
      <div className="login-white-logo">
        <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
      </div>
      <div className="login-container">
        <div className="login-logo">
          <img
            src={appSettings?.profilePicture || CatholicoreLogo}
            alt={t('login.logoAlt')}
          />
        </div>
        <h2>{t('login.title')}</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-input-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('login.emailPlaceholder')}
              required
              aria-label={t('login.emailLabel')}
            />
          </div>
          <div className="login-input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('login.passwordPlaceholder')}
              required
              minLength={6}
              aria-label={t('login.passwordLabel')}
            />
          </div>
          <button type="submit" className="login-submit-button">
            {t('login.submitButton')}
          </button>
        </form>
        <br />
        <h6
          onClick={() => navigate(`/${organizationData.groupId}/password-recovery`)}
          className="login-forgot-password"
        >
          {t('login.forgotPassword')}
        </h6>
      </div>
      <CustomModal show={showModal} onClose={handleCloseModal} title={t('login.modalTitle')}>
        <p className="login-message">{message}</p>
      </CustomModal>
      <footer className="login-footer">
        <div className="login-policy-links">
          <Link to="/privacy-policy" className="login-policy-link">
            Privacy Policy
          </Link>
          <span className="login-policy-separator">|</span>
          <Link to="/terms-of-service" className="login-policy-link">
            Terms of Service
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Login;
