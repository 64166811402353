// src/components/FormManager/GeneralTab.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from '../FormBuilder/Fields/TextField';
import ToggleSwitch from '../FormBuilder/Fields/ToggleSwitch';
import ActionButton from '../Common/ActionButton';
import './GeneralTab.css';

const GeneralTab = ({
  formId, // New prop
  formName,
  setFormName,
  isClosed,
  setIsClosed,
  onSave,
  isSaving,
}) => {
  const { t } = useTranslation();

  // Determine if the form name should be editable
  const isNameEditable = !['membersForm', 'groupsForm'].includes(formId);

  return (
    <div className="general-tab-container">
      {/* Close Submissions Toggle */}
      <div className="form-builder-header">
        <div></div>
        <div className="switch-control">
          <label htmlFor="closeSubmissions" className="switch-label">
            {t('generalTab.closeSubmissions')}
          </label>
          <ToggleSwitch
            id="closeSubmissions"
            checked={isClosed}
            onChange={(checked) => setIsClosed(checked)}
            activeColor="#dc3545"
          />
        </div>
      </div>

      {/* Form Name Row */}
      <div className="form-builder-header">
        <div className="form-builder-controls full-width">
          <TextField
            label={t('generalTab.formName')}
            name="formName"
            value={formName}
            onChange={(value) => setFormName(value)}
            placeholder={t('generalTab.formNamePlaceholder')}
            required={true}
            fullWidth
            disabled={!isNameEditable} // Disable if not editable
          />
        </div>
      </div>

      <ActionButton
        onClick={onSave}
        label={t('generalTab.saveForm')}
        text={t('generalTab.saveForm')}
        icon="faSave"
        isMobile={false}
        colorType="primary"
        disabled={isSaving || (!isNameEditable && !formName.trim())} // Adjusted condition
        spacing="8px"
        className="save-form-button full-width-button"
      />
    </div>
  );
};

GeneralTab.propTypes = {
  formId: PropTypes.string.isRequired, // Add formId to propTypes
  formName: PropTypes.string.isRequired,
  setFormName: PropTypes.func.isRequired,
  isClosed: PropTypes.bool.isRequired,
  setIsClosed: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
};

GeneralTab.defaultProps = {
  isSaving: false,
};

export default GeneralTab;
