// src/components/Common/ActionButton.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconMap from './IconMap'; // Import the shared iconMap
import CustomTooltip from './CustomTooltip';
import Spinner from './Spinner'; // Import the custom Spinner component
import { useTranslation } from 'react-i18next';
import './ActionButton.css';

const ActionButton = ({
  onClick,
  label,
  text,
  icon,
  isMobile,
  colorType,
  as,
  href,
  disabled,
  ariaLabel,
  type,
  target,
  rel,
  spacing,
  className,
  size,
  fullWidth, // New prop for full width option
  spin, // New prop to control spinner
}) => {
  const { t } = useTranslation();

  const isAnchor = as === 'a';

  // Determine if the button is icon-only
  const isIconOnly = icon && !text;

  const renderElement = () => {
    const classes = [
      'action-button',
      `size-${size}`, // Apply size class
      colorType === 'primary' ? 'primary' : 'secondary',
      isMobile ? 'action-button-mobile' : '',
      isIconOnly ? 'icon-only' : '',
      fullWidth ? 'full-width' : '', // Apply full-width class if fullWidth is true
      className, // Append additional classes
    ]
      .filter(Boolean)
      .join(' ');

    // Define inline styles for external margin based on the 'spacing' prop
    const style = spacing ? { margin: spacing } : {};

    const commonProps = {
      className: classes,
      style, // Apply external margin
      'aria-label': ariaLabel || label || t('actionButton.defaultLabel'),
      ...(isAnchor ? { href } : { onClick }),
      ...(isAnchor ? { target, rel } : { type }),
      ...(disabled && !isAnchor ? { disabled: true } : {}),
    };

    const content = (
      <>
        {spin && (
          <Spinner size="16px" color="#fff" /> // Use custom Spinner
        )}
        {!spin && icon && <FontAwesomeIcon icon={iconMap[icon]} className="button-icon" />}
        {(text) && <span className="button-text">{text}</span>}
      </>
    );

    if (isAnchor) {
      return <a {...commonProps}>{content}</a>;
    }

    return <button {...commonProps}>{content}</button>;
  };

  const tooltipContent =
    disabled
      ? t('actionButton.noActionAvailable')
      : label || t('actionButton.defaultLabel');

  // Show tooltip only if not mobile and the button is icon-only
  const shouldShowTooltip = !isMobile && isIconOnly;

  if (shouldShowTooltip) {
    return (
      <CustomTooltip text={tooltipContent}>{renderElement()}</CustomTooltip>
    );
  }

  return renderElement();
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  colorType: PropTypes.oneOf(['primary', 'secondary']),
  as: PropTypes.oneOf(['button', 'a']),
  href: (props, propName, componentName) => {
    if (props.as === 'a' && !props[propName]) {
      return new Error(
        `The prop \`${propName}\` is required when \`as='a'\` is specified in \`${componentName}\`.`
      );
    }
    return null;
  },
  target: PropTypes.string,
  rel: PropTypes.string,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  spacing: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']), // Added 'large' size option
  fullWidth: PropTypes.bool, // New prop type for full width option
  spin: PropTypes.bool, // New prop type for spinner control
};

ActionButton.defaultProps = {
  label: '',
  text: '',
  icon: null,
  isMobile: false,
  colorType: 'primary',
  as: 'button',
  href: null,
  target: '_self',
  rel: 'noopener noreferrer',
  disabled: false,
  ariaLabel: '',
  type: 'button',
  spacing: '',
  className: '',
  size: 'medium',
  fullWidth: false, // Default to false
  spin: false, // Default to not spinning
};

export default ActionButton;
