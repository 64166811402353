// src/components/CustomModal.jsx

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for Portals
import PropTypes from 'prop-types';
import './CustomModal.css'; // Import the CSS specific to the modal

const CustomModal = ({ show, onClose, title, children, closable = true  }) => {
  const [shouldRender, setShouldRender] = useState(show);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (show) {
      setShouldRender(true);
      // Trigger fade-in animation
      setTimeout(() => {
        setAnimationClass('fade-in-active');
      }, 10); // Delay to allow CSS transition
    } else if (shouldRender) {
      // Trigger fade-out animation
      setAnimationClass('fade-out-active');
      // Wait for the animation to complete before unmounting
      const timer = setTimeout(() => {
        setShouldRender(false);
        setAnimationClass('');
      }, 300); // Duration should match CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [show, shouldRender]);

  // Handle closing the modal when clicking outside the modal content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Handle pressing the Escape key to close the modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (shouldRender) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [shouldRender, onClose]);

  if (!shouldRender) return null;

  // Create a portal root if it doesn't exist
  const modalRoot = document.getElementById('modal-root') || createModalRoot();

  return ReactDOM.createPortal(
    <div
      className={`custom-modal-overlay ${animationClass}`}
      onClick={closable ? handleOverlayClick : undefined} // Disable clicking outside to close
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="custom-modal">
        <div className="custom-modal-header">
          <h2 id="modal-title">{title}</h2>
          {closable && (
            <button
              className="custom-modal-close"
              onClick={onClose}
              aria-label="Close modal"
            >
              &times;
            </button>
          )}
        </div>
        <div className="custom-modal-body">{children}</div>
      </div>
    </div>,
    modalRoot
  );
};

// Function to create a modal root if it doesn't exist
const createModalRoot = () => {
  const modalRoot = document.createElement('div');
  modalRoot.setAttribute('id', 'modal-root');
  document.body.appendChild(modalRoot);
  return modalRoot;
};

CustomModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  closable: PropTypes.bool,
};

CustomModal.defaultProps = {
  title: '',
  children: null,
  closable: true,
};

export default CustomModal;