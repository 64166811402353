// src/components/Authentication/ResetPassword.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../Common/CustomModal';
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import './ResetPassword.css';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  // Access appSettings and authentication functions from DataContext
  const { appSettings, appSettingsLoading, verifyPasswordResetCode, confirmPasswordReset } = useContext(DataContext);

  // Access language settings from LanguageSettingsContext
  const { loading: languageLoading } = useLanguageSettings();

  // Access loading state from ColorSettingsContext
  const { loading: colorLoading } = useColorSettings();

  // Function to validate the reset code
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('oobCode');

    if (code) {
      verifyPasswordResetCode(code)
        .then(() => {
          setIsCodeValid(true);
        })
        .catch(() => {
          setError(t('resetPassword.invalidLink'));
          setIsCodeValid(false);
        });
    } else {
      setError(t('resetPassword.missingCode'));
      setIsCodeValid(false);
    }
  }, [t, verifyPasswordResetCode]);

  const goToLogin = () => {
    navigate('/');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setMessage('');
    setShowModal(false);

    if (newPassword !== confirmPassword) {
      setError(t('resetPassword.passwordMismatch'));
      return;
    }

    try {
      const params = new URLSearchParams(window.location.search);
      const oobCode = params.get('oobCode'); // The code is typically passed as a URL parameter
      await confirmPasswordReset(oobCode, newPassword);
      setMessage(t('resetPassword.successMessage'));
      setShowModal(true);
    } catch (error) {
      setError(t('resetPassword.error', { error: error.message }));
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/');
  };

  // Handle loading state by combining loading states
  if (colorLoading || languageLoading || appSettingsLoading) {
    return (
      <div className="reset-password-background">
        <div className="reset-password-container">
          <Spinner />
        </div>
      </div>
    );
  }

  if (!isCodeValid) {
    return (
      <div className="reset-password-background">
        <div className="reset-password-container">
          <div className="reset-password-logo">
            <img
              src={appSettings?.profilePicture || CatholicoreLogo}
              alt={t('resetPassword.logoAlt')}
            />
          </div>
          <h2>{t('resetPassword.title')}</h2>
          <br />
          <h4 className="reset-password-error">{error && <p>{error}</p>}</h4>
          <h6 onClick={goToLogin} className="reset-password-forgot">
            {t('resetPassword.backToLogin')}
          </h6>
        </div>
      </div>
    );
  } else {
    return (
      <div className="reset-password-background">
        <div className="reset-password-container">
          <div className="reset-password-logo">
            <img
              src={appSettings?.profilePicture || CatholicoreLogo}
              alt={t('resetPassword.logoAlt')}
            />
          </div>
          <h2>{t('resetPassword.title')}</h2>
          <br />
          <h4 className="reset-password-error">{error && <p>{error}</p>}</h4>
          <form onSubmit={handleSubmit} className="reset-password-form">
            <div className="reset-password-input-group">
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder={t('resetPassword.newPasswordPlaceholder')}
                required
                aria-label={t('resetPassword.newPasswordLabel')}
              />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t('resetPassword.confirmPasswordPlaceholder')}
                required
                aria-label={t('resetPassword.confirmPasswordLabel')}
              />
            </div>
            <button type="submit" className="reset-password-submit-button">
              {t('resetPassword.updatePassword')}
            </button>
          </form>
        </div>
        <CustomModal
          show={showModal}
          onClose={handleCloseModal}
          title={t('resetPassword.modalTitle')}
        >
          <p>{message}</p>
        </CustomModal>
      </div>
    );
  }
};

export default ResetPassword;
