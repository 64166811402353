// src/components/Common/LanguageSettingsContext.js

import React, { createContext, useContext } from 'react';

// Create the LanguageSettingsContext
const LanguageSettingsContext = createContext();

// Custom hook to use the LanguageSettingsContext
export const useLanguageSettings = () => {
  const context = useContext(LanguageSettingsContext);
  if (!context) {
    throw new Error('useLanguageSettings must be used within a LanguageSettingsProvider');
  }
  return context;
};

export default LanguageSettingsContext;
