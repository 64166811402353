// ResetPasswordConfirmationModal.jsx
import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import ActionButton from './ActionButton';
import { useTranslation } from 'react-i18next';
import './ResetPasswordConfirmationModal.css';

const ResetPasswordConfirmationModal = ({ show, onConfirm, onCancel, record, isMobile }) => {
  const { t } = useTranslation();

  return (
    <CustomModal show={show} onClose={onCancel} title={t('resetPasswordConfirmation.title')}>
      <div className="reset-confirmation">
        <p>
          {t('resetPasswordConfirmation.message', {
            user: record?.fullName || 'Este usuario',
          })}
        </p>
        <div className="reset-confirmation-actions">
          <ActionButton
            onClick={onConfirm}
            label={t('resetPasswordConfirmation.confirmButton')}
            icon="faCheck"
            isMobile={isMobile}
            colorType="primary"
            ariaLabel={t('resetPasswordConfirmation.confirmButton')}
          />
          <ActionButton
            onClick={onCancel}
            label={t('resetPasswordConfirmation.cancelButton')}
            icon="faTimes"
            isMobile={isMobile}
            colorType="secondary"
            ariaLabel={t('resetPasswordConfirmation.cancelButton')}
          />
        </div>
      </div>
    </CustomModal>
  );
};

ResetPasswordConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  record: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
};

ResetPasswordConfirmationModal.defaultProps = {
  record: null,
};

export default ResetPasswordConfirmationModal;
