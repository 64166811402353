// src/components/Teams/TeamForm.jsx

import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../Common/ActionButton';
import './TeamForm.css';
import { useTranslation } from 'react-i18next';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import Spinner from '../Common/Spinner';
import Select from 'react-select';

// Import custom field components
import TextField from '../FormBuilder/Fields/TextField';

const TeamForm = ({
  name,
  setName,
  members,
  setMembers,
  isMobile,
  onSubmit,
  isEditing,
  membersData, // New prop
}) => {
  const { t } = useTranslation();

  // Consume LanguageSettings and ColorSettings contexts
  const { loading: languageLoading } = useLanguageSettings();
  const { loading: colorLoading } = useColorSettings();

  // Combine loading states
  if (colorLoading || languageLoading) {
    return (
      <div className="teams-modal-form">
        <Spinner />
      </div>
    );
  }

  // Prepare member options for react-select
  const memberOptions = membersData.map((member) => ({
    value: member.groupId+"_"+member.id,
    label: `${member.firstName} ${member.lastName}`,
    photoURL: member.profilePicture || '',
    groupId: member.groupId // Assuming member has photoURL field
  }));

  // Custom styles for react-select
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected
        ? 'var(--primary-color)'
        : state.isFocused
        ? '#f0f0f0'
        : '#fff',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--primary-color)',
      color: '#fff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      ':hover': {
        backgroundColor: '#c82333',
        color: '#fff',
      },
    }),
  };

  // Function to render the option with profile picture and full name
  const formatOptionLabel = ({ label, photoURL }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {photoURL ? (
        <img
          src={photoURL}
          alt={label}
          style={{ width: 30, height: 30, backgroundColor: 'var(--primary-color)' ,  borderRadius: '50%', marginRight: 10 }}
        />
      ) : (
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: '50%',
            backgroundColor: '#ccc',
            marginRight: 10,
          }}
        ></div>
      )}
      <span>{label}</span>
    </div>
  );

  // Map selected members to react-select options
  const selectedMembers = memberOptions.filter((option) =>
    members.includes(option.value)
  );

  return (
    <form className="teams-modal-form" onSubmit={onSubmit}>
      <div className="teams-input-group">
        <TextField
          label={t('teamForm.nameLabel')}
          name="name"
          value={name}
          onChange={(value) => setName(value)}
          required
          placeholder={t('teamForm.namePlaceholder')}
          size="full"
        />
      </div>
      <div className="teams-input-group">
        <label>{t('teamForm.membersLabel')}</label>
        <Select
          isMulti
          options={memberOptions}
          value={selectedMembers}
          onChange={(selectedOptions) => {
            const values = selectedOptions ? selectedOptions.map((option) => option.value) : [];
            setMembers(values);
          }}
          placeholder={t('teamForm.membersPlaceholder')}
          styles={customStyles}
          formatOptionLabel={formatOptionLabel}
          classNamePrefix="react-select"
        />
      </div>
      {/* Container to center the Save/Update button */}
      <div className="teams-submit-button-container">
        <ActionButton
          type="submit"
          label={isEditing ? t('teamForm.updateButton') : t('teamForm.saveButton')}
          text={isEditing ? t('teamForm.updateButton') : t('teamForm.saveButton')}
          icon="faSave"
          isMobile={isMobile}
          colorType="primary"
          ariaLabel={
            isEditing ? t('teamForm.updateButtonAria') : t('teamForm.saveButtonAria')
          }
        />
      </div>
    </form>
  );
};

TeamForm.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  setMembers: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  membersData: PropTypes.array.isRequired, // Updated prop type
};

export default TeamForm;
