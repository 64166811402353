// src/components/FormBuilder/Fields/RadioButtonField.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './RadioButtonField.css';

const RadioButtonField = ({ label, name, options, value, onChange }) => {
  return (
    <div className="form-group radio-group">
      <label>{label}</label>
      <div className="radio-options">
        {options.map((option) => (
          <div key={option.value} className="radio-option">
            <input
              type="radio"
              id={`${name}_${option.value}`}
              name={name}
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
            />
            <label htmlFor={`${name}_${option.value}`}>{option.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

RadioButtonField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonField;
