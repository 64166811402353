// src/pages/ConfigureFieldsModal.jsx

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import ActionButton from './ActionButton';
import './ConfigureFieldsModal.css';
import Select from 'react-select';
import ContextMenu from './ContextMenu';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

const ConfigureFieldsModal = ({
  show,
  onClose,
  onSave,
  availableFields,
  currentConfig,
}) => {
  const { t } = useTranslation();
  const [configFields, setConfigFields] = useState([]);
  const [contextMenu, setContextMenu] = useState({
    isVisible: false,
    position: { x: 0, y: 0 },
    fieldIndex: null,
  });

  useEffect(() => {
    if (currentConfig) {
      // Ensure that each field has hideInMobile property
      const updatedConfig = currentConfig.map((field) => ({
        ...field,
        hideInMobile: field.hideInMobile !== undefined ? field.hideInMobile : false,
      }));
      setConfigFields(updatedConfig);
    } else {
      setConfigFields([]);
    }
  }, [currentConfig]);

  const handleAddField = (index) => {
    const newField = {
      id: '',
      label: '',
      accessor: [],
      sortable: false,
      type: 'Text', // Default type
      hideInMobile: false,
    };
    const updatedFields = [...configFields];
    if (typeof index === 'number') {
      updatedFields.splice(index, 0, newField);
    } else {
      updatedFields.push(newField);
    }
    setConfigFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...configFields];
    updatedFields.splice(index, 1);
    setConfigFields(updatedFields);
  };

  const handleFieldChange = (index, field, value) => {
    const updatedFields = [...configFields];
    updatedFields[index][field] = value;
    setConfigFields(updatedFields);
  };

  const handleSave = () => {
    onSave(configFields);
  };

  // Prepare options for react-select, excluding fields where type is 'Section'
  const fieldOptions = availableFields
    .filter((field) => field.type !== 'Section') // Exclude 'Section' fields
    .map((field) => ({
      value: field.id,
      label: field.label,
      type: field.type, // Include the type
    }));

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '40px',
      borderRadius: '6px',
      borderColor: '#ced4da',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#333',
      backgroundColor: state.isSelected
        ? 'var(--primary-color)'
        : state.isFocused
        ? '#f0f0f0'
        : '#fff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#fff',
      border: '1px solid #ced4da',
      boxShadow: 'none',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: 'var(--primary-color)',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      ':hover': {
        backgroundColor: '#c82333',
        color: '#fff',
      },
    }),
  };

  // Functions to move fields
  const moveFieldUp = (index) => {
    if (index === 0) return; // Already at the top
    const newFields = [...configFields];
    [newFields[index - 1], newFields[index]] = [
      newFields[index],
      newFields[index - 1],
    ];
    setConfigFields(newFields);
  };

  const moveFieldDown = (index) => {
    if (index === configFields.length - 1) return; // Already at the bottom
    const newFields = [...configFields];
    [newFields[index + 1], newFields[index]] = [
      newFields[index],
      newFields[index + 1],
    ];
    setConfigFields(newFields);
  };

  const moveFieldToTop = (index) => {
    if (index === 0) return; // Already at the top
    const newFields = [...configFields];
    const [movedField] = newFields.splice(index, 1);
    newFields.unshift(movedField);
    setConfigFields(newFields);
  };

  const moveFieldToBottom = (index) => {
    if (index === configFields.length - 1) return; // Already at the bottom
    const newFields = [...configFields];
    const [movedField] = newFields.splice(index, 1);
    newFields.push(movedField);
    setConfigFields(newFields);
  };

  // Handle Context Menu
  const handleContextMenu = (event, index) => {
    event.preventDefault();
    setContextMenu({
      isVisible: true,
      position: { x: event.clientX, y: event.clientY },
      fieldIndex: index,
    });
  };

  const closeContextMenu = () => {
    setContextMenu({
      isVisible: false,
      position: { x: 0, y: 0 },
      fieldIndex: null,
    });
  };

  const getContextMenuOptions = () => {
    if (contextMenu.fieldIndex === null) return [];

    return [
      {
        label: t('moveTop', 'Move to Top'),
        onClick: () => moveFieldToTop(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === 0,
      },
      {
        label: t('moveUp', 'Move Up'),
        onClick: () => moveFieldUp(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === 0,
      },
      {
        label: t('moveDown', 'Move Down'),
        onClick: () => moveFieldDown(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === configFields.length - 1,
      },
      {
        label: t('moveBottom', 'Move to Bottom'),
        onClick: () => moveFieldToBottom(contextMenu.fieldIndex),
        disabled: contextMenu.fieldIndex === configFields.length - 1,
      },
    ];
  };

  // Reference for clicking outside context menu
  const contextMenuRef = useRef(null);

  return (
    <CustomModal
      show={show}
      onClose={onClose}
      title={t('configureFieldsModal.title', 'Configure Table Fields')}
      className="configure-fields-modal"
    >
      <div className="modal-content-wrapper">
        <div className="fields-list">
          <div className="fields-container">
            {configFields.map((fieldConfig, index) => (
              <div
                key={`field-${index}`}
                className="field-config half-width"
                onContextMenu={(e) => handleContextMenu(e, index)}
              >
                <div className="field-details">
                  <div className="field-row">
                    <label>{t('configureFieldsModal.label', 'Label:')}</label>
                    <input
                      type="text"
                      value={fieldConfig.label}
                      onChange={(e) =>
                        handleFieldChange(index, 'label', e.target.value)
                      }
                      placeholder={t(
                        'configureFieldsModal.placeholderLabel',
                        'Enter header label'
                      )}
                    />
                  </div>
                  <div className="field-row">
                    <label>{t('configureFieldsModal.Fields', 'Field(s):')}</label>
                    <Select
                      isMulti
                      options={fieldOptions}
                      value={fieldOptions.filter((option) =>
                        fieldConfig.accessor.includes(option.value)
                      )}
                      onChange={(selectedOptions) => {
                        const values = selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : [];
                        handleFieldChange(index, 'accessor', values);
                        handleFieldChange(index, 'id', camelCase(fieldConfig.label));
                        // Set the type based on the first selected field's type
                        if (selectedOptions && selectedOptions.length > 0) {
                          handleFieldChange(index, 'type', selectedOptions[0].type);
                        } else {
                          handleFieldChange(index, 'type', 'Text');
                        }
                      }}
                      placeholder={t(
                        'configureFieldsModal.placeholderFields',
                        'Type to search and add fields...'
                      )}
                      className="field-select"
                      classNamePrefix="react-select"
                      styles={customStyles}
                    />
                  </div>
                  <div className="field-row">
                    <label>{t('configureFieldsModal.sortable', 'Sortable:')}</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={fieldConfig.sortable}
                        onChange={(e) =>
                          handleFieldChange(index, 'sortable', e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                    <div className="hideMobileBlock"></div>
                    <label>
                      {t('configureFieldsModal.hideInMobile', 'Hide in Mobile:')}
                    </label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={fieldConfig.hideInMobile || false}
                        onChange={(e) =>
                          handleFieldChange(index, 'hideInMobile', e.target.checked)
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <div className="field-actions">
                  <ActionButton
                    onClick={() => handleRemoveField(index)}
                    label={t('delete', 'Delete')}
                    icon="faTrash"
                    colorType="secondary"
                    size="small"
                  />
                </div>
              </div>
            ))}
            {/* Add Button as a Field Config */}
            <div
              className="add-field-btn half-width add-field-config"
              onClick={() => handleAddField(configFields.length)}
            >
              <ActionButton
                onClick={() => handleAddField(configFields.length)}
                text={t('configureFieldsModal.addField', 'Add Field')}
                icon="faPlus"
                colorType="secondary"
                size="large"
                className="add-field-action-button"
                fullWidth={true}
              />
            </div>
          </div>
        </div>
        <div className="modal-actions">
          <ActionButton
            onClick={handleSave}
            text={t('configureFieldsModal.saveTableFields', 'Save Table Fields')}
            icon="faSave"
            colorType="primary"
            size="large"
            className="full-width-button"
          />
          <ActionButton
            onClick={onClose}
            text={t('cancel', 'Cancel')}
            colorType="secondary"
            icon="faTimes"
            size="large"
            className="full-width-button"
          />
        </div>
      </div>
      {/* Context Menu */}
      <ContextMenu
        isVisible={contextMenu.isVisible}
        position={contextMenu.position}
        options={getContextMenuOptions()}
        onClose={closeContextMenu}
      />
    </CustomModal>
  );
};

ConfigureFieldsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  availableFields: PropTypes.array.isRequired,
  currentConfig: PropTypes.array,
};

export default ConfigureFieldsModal;
