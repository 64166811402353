// src/components/Common/ColorSettings.jsx

import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ColorSettingsContext from './ColorSettingsContext';
import { DataContext } from '../../DataContext';

const ColorSettings = ({ children }) => {
  const { appSettings, appSettingsLoading } = useContext(DataContext);

  const [loading, setLoading] = useState(true);

  // Function to calculate a shade of a color
  const shadeColor = (color, percent) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR = (R.toString(16).length === 1 ? '0' : '') + R.toString(16);
    const GG = (G.toString(16).length === 1 ? '0' : '') + G.toString(16);
    const BB = (B.toString(16).length === 1 ? '0' : '') + B.toString(16);

    return `#${RR}${GG}${BB}`;
  };

  useEffect(() => {
    if (!appSettingsLoading && appSettings && appSettings.color) {
      const primaryColor = appSettings.color;
      const secondaryColor = shadeColor(primaryColor, -30);

      // Set CSS variables
      document.documentElement.style.setProperty('--main-color', primaryColor);
      document.documentElement.style.setProperty('--secondary-color', secondaryColor);

      console.log('Set CSS variables:', {
        '--main-color': primaryColor,
        '--secondary-color': secondaryColor,
      });

      // After setting the colors, set loading to false
      setLoading(false);
    }
  }, [appSettings, appSettingsLoading]);

  return (
    <ColorSettingsContext.Provider
      value={{
        appSettings,
        loading: loading || appSettingsLoading,
      }}
    >
      {children}
    </ColorSettingsContext.Provider>
  );
};

export default ColorSettings;
