// src/components/Layout.js

import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import GlobalSpinner from '../Common/GlobalSpinner';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';

const Layout = ({ children }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // Access contexts
  const { appSettings, appSettingsLoading } = useContext(DataContext);
  const { loading: colorLoading } = useColorSettings();
  const { loading: languageLoading } = useLanguageSettings();

  // Determine if initial loading is still happening
  const isInitialLoading = appSettingsLoading || colorLoading || languageLoading;

  // State to manage the current logo and its transition
  const [logo, setLogo] = useState(CatholicoreLogo);
  const [isLogoTransitioning, setIsLogoTransitioning] = useState(false);

  // Update the logo when appSettings are loaded
  useEffect(() => {
    if (!appSettingsLoading) {
      const profilePicture = appSettings?.profilePicture || CatholicoreLogo;

      // Start logo transition
      setIsLogoTransitioning(true);

      // Delay to allow fade-out before changing the logo
      const transitionTimeout = setTimeout(() => {
        setLogo(profilePicture);
        setIsLogoTransitioning(false);
      }, 400); // Match the animationDuration

      return () => clearTimeout(transitionTimeout);
    }
  }, [appSettingsLoading, appSettings]);

  // Handle route changes
  useEffect(() => {
    // Start loading when the route changes
    setLoading(true);
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, [location]);

  // Render the loading screen if initial loading or route loading is happening
  if (isInitialLoading || loading || isLogoTransitioning) {
    return (
      <div className="loading-screen">
        <GlobalSpinner
          show={true}
          overlayColor="#fff"
          animationDuration={0.4}
          opaque={true}
          logo={logo}
          isLogoTransitioning={isLogoTransitioning}
        />
      </div>
    );
  }

  // Render the children when loading is complete
  return <>{children}</>;
};

export default Layout;
