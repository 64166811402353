// src/components/FormBuilder/FillOutForm.jsx

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import TextField from './Fields/TextField';
import NumberField from './Fields/NumberField';
import DatePicker from './Fields/DatePicker';
import TimePicker from './Fields/TimePicker';
import ImagePicker from './Fields/ImagePicker';
import CheckboxField from './Fields/CheckboxField';
import RadioButtonField from './Fields/RadioButtonField';
import TelephoneField from './Fields/TelephoneField';
import EmailField from './Fields/EmailField';
import SelectField from './Fields/SelectField';
import TextAreaField from './Fields/TextAreaField';
import RangeSliderField from './Fields/RangeSliderField';
import MultiRowControl from './Fields/MultiRowControl';
import ToggleSwitch from './Fields/ToggleSwitch';
import ColorPicker from './Fields/ColorPicker';
import ActionButton from '../Common/ActionButton';
import Section from './Fields/Section';
import { DataContext } from '../../DataContext';
import Spinner from '../Common/Spinner';
import './FillOutForm.css';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

// Import default images
import UserIcon from '../../assets/UserIcon.png';
import CatholicoreLogoSimple from '../../assets/CatholicoreLogoSimple.png';

const FillOutForm = ({
  fields,
  formId,
  showToast,
  initialData = null,
  responseId = null,
  onCancel,
  onFormSubmit,
}) => {
  const { t } = useTranslation();
  const {
    organizationData,
    fetchFormDetails,
    fetchCatalogs,
    submitFormResponse,
    updateResponse,
  } = useContext(DataContext);

  // Helper function to parse date strings to Date objects
  const parseDate = (date) => {
    if (!date) return null;
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime()) ? null : parsedDate;
  };

  // Validation and formatting function
  const validateAndFormatPhone = (phone) => {
    if (!phone) return null;

    // Remove all non-digit and non-plus characters
    let cleanedPhone = phone.replace(/[^0-9+]/g, '');

    // Handle possible '+1' prefix
    if (cleanedPhone.startsWith('+1')) {
      cleanedPhone = cleanedPhone.slice(2);
    } else if (cleanedPhone.length === 11 && cleanedPhone.startsWith('1')) {
      cleanedPhone = cleanedPhone.slice(1);
    }

    // After removing prefixes, the number should be exactly 10 digits
    if (cleanedPhone.length === 10) {
      return `+1${cleanedPhone}`;
    } else if (cleanedPhone.length !== 10) {
      return null;
    }

    // Format to E.164 standard (e.g., +11234567890)
    return `${cleanedPhone}`;
  };

  // Initialize form data based on fields and initialData
  const initializeFormState = () => {
    const initialState = {};
    fields.forEach((field) => {
      if (field.type === 'Section') {
        return;
      }
      if (initialData) {
        // Populate formData with initialData if available
        const existingField = initialData.fields.find((f) => f.id === field.id);
        if (existingField) {
          switch (field.type) {
            case 'Checkbox':
              initialState[field.id] =
                existingField.value ||
                field.options.map((option) => ({
                  name: option.value || '',
                  completed: false,
                }));
              break;
            case 'MultiRowControl':
              initialState[field.id] = existingField.value || [];
              break;
            case 'RadioButton':
            case 'Select':
              initialState[field.id] = existingField.value || '';
              break;
            case 'RangeSlider':
              initialState[field.id] =
                existingField.value !== undefined
                  ? Number(existingField.value)
                  : field.min || 0;
              break;
            case 'FileUpload':
              initialState[field.id] = existingField.value || null;
              break;
            case 'DatePicker':
            case 'TimePicker':
              initialState[field.id] = parseDate(existingField.value);
              break;
            case 'ToggleSwitch':
              initialState[field.id] = existingField.value || false;
              break;
            case 'ColorPicker':
              initialState[field.id] = existingField.value || '#000000';
              break;
            default:
              initialState[field.id] = existingField.value || '';
          }
        } else {
          // If no existing field data, initialize as default
          switch (field.type) {
            case 'Checkbox':
              initialState[field.id] = field.options.map((option) => ({
                name: option.value || '',
                completed: false,
              }));
              break;
            case 'MultiRowControl':
              initialState[field.id] = [];
              break;
            case 'RadioButton':
            case 'Select':
              initialState[field.id] = field.value || '';
              break;
            case 'RangeSlider':
              initialState[field.id] =
                field.value !== undefined ? Number(field.value) : field.min || 0;
              break;
            case 'FileUpload':
              initialState[field.id] = field.value || null;
              break;
            case 'DatePicker':
            case 'TimePicker':
              initialState[field.id] = parseDate(field.value);
              break;
            case 'ToggleSwitch':
              initialState[field.id] = field.value || false;
              break;
            case 'ColorPicker':
              initialState[field.id] = field.value || '#000000';
              break;
            default:
              initialState[field.id] = field.value || '';
          }
        }
      } else {
        // If not in edit mode, initialize as usual
        switch (field.type) {
          case 'Checkbox':
            initialState[field.id] = field.options.map((option) => ({
              name: option.value || '',
              completed: false,
            }));
            break;
          case 'MultiRowControl':
            initialState[field.id] = [];
            break;
          case 'RadioButton':
          case 'Select':
            initialState[field.id] = field.value || '';
            break;
          case 'RangeSlider':
            initialState[field.id] =
              field.value !== undefined ? Number(field.value) : field.min || 0;
            break;
          case 'FileUpload':
            initialState[field.id] = field.value || null;
            break;
          case 'DatePicker':
          case 'TimePicker':
            initialState[field.id] = parseDate(field.value);
            break;
          case 'ToggleSwitch':
            initialState[field.id] = field.value || false;
            break;
          case 'ColorPicker':
            initialState[field.id] = field.value || '#000000';
            break;
          default:
            initialState[field.id] = field.value || '';
        }
      }
    });
    return initialState;
  };

  const [formData, setFormData] = useState(initializeFormState());
  const [submitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [catalogData, setCatalogData] = useState({});
  const [formBelongsToGroup, setFormBelongsToGroup] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(true);

  // Update formData when fields or initialData props change
  useEffect(() => {
    setFormData(initializeFormState());
    setFormErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, initialData]);

  // Fetch catalog data for Select fields with catalogId
  useEffect(() => {
    const fetchCatalogData = async () => {
      const catalogIds = fields
        .filter((field) => field.type === 'Select' && field.catalogId)
        .map((field) => field.catalogId);

      const uniqueCatalogIds = [...new Set(catalogIds)];
      const catalogsToFetch = uniqueCatalogIds.filter((id) => !catalogData[id]);

      if (catalogsToFetch.length === 0) return;

      try {
        const fetchedCatalogData = await fetchCatalogs(catalogsToFetch);
        setCatalogData((prevData) => ({ ...prevData, ...fetchedCatalogData }));
      } catch (error) {
        console.error('Error fetching catalogs:', error);
        showToast(t('fillOutForm.errorFetchingCatalogData'), 'error');
      }
    };

    fetchCatalogData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, fetchCatalogs]);

  // Fetch and verify form details
  useEffect(() => {
    const verifyFormDetails = async () => {
      if (!formId) return;

      try {
        const formDataFirestore = await fetchFormDetails(formId);
        if (formDataFirestore) {
          // Verify that the form's groupId matches the current groupId
          if (formDataFirestore.groupId !== organizationData.groupId) {
            setFormBelongsToGroup(false);
            showToast(t('fillOutForm.accessDenied'), 'error');
          } else {
            setFormBelongsToGroup(true);
          }
        } else {
          setFormBelongsToGroup(false);
          showToast(t('fillOutForm.formNotFound'), 'error');
        }
      } catch (error) {
        console.error('Error fetching form details:', error);
        setFormBelongsToGroup(false);
        showToast(t('fillOutForm.errorFetchingFormDetails'), 'error');
      } finally {
        setIsFormLoading(false);
      }
    };

    if (formId) {
      verifyFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, organizationData.groupId, fetchFormDetails, t]);

  // Display spinner while the form is loading
  if (isFormLoading) {
    return (
      <div className="loading-spinner-container">
        <Spinner size="60px" />
      </div>
    );
  }

  if (!formBelongsToGroup) {
    return (
      <div className="access-denied">{t('fillOutForm.accessDeniedMessage')}</div>
    );
  }

  // Handle changes in form fields
  const handleChange = (fieldId, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldId]: value,
    }));
  };

  // Handle field changes
  const handleFieldChange = (fieldId, value) => {
    handleChange(fieldId, value);
  };

  // Function to handle validation from child components
  const handleValidation = (fieldId, errorMessage) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldId]: errorMessage,
    }));
  };

  // Function to sanitize data by replacing undefined with null
  const sanitizeData = (data) => {
    if (Array.isArray(data)) {
      return data.map(sanitizeData);
    } else if (data !== null && typeof data === 'object') {
      const sanitized = {};
      Object.keys(data).forEach((key) => {
        const value = sanitizeData(data[key]);
        if (value !== undefined) {
          sanitized[key] = value;
        }
      });
      return sanitized;
    }
    return data;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for any existing validation errors
    const hasErrors = Object.values(formErrors).some((error) => error);
    if (hasErrors) {
      showToast(t('fillOutForm.fixErrorsBeforeSubmitting'), 'error');
      return;
    }

    // Basic required field validation
    for (let field of fields) {
      if (field.type === 'Section') {
        continue; // Skip validation for Section fields
      }
      if (field.required) {
        const fieldValue = formData[field.id];
        if (field.type === 'Checkbox') {
          // Check if at least one checkbox is completed
          const isAnyCompleted = fieldValue.some((option) => option.completed);
          if (!isAnyCompleted) {
            showToast(
              t('fillOutForm.selectAtLeastOneOption', { label: field.label }),
              'error'
            );
            return;
          }
        } else if (
          field.type !== 'Checkbox' &&
          field.type !== 'MultiRowControl' &&
          field.type !== 'ToggleSwitch' &&
          (fieldValue === '' || fieldValue === null || fieldValue === undefined)
        ) {
          showToast(
            t('fillOutForm.fillOutField', { label: field.label }),
            'error'
          );
          return;
        }
        if (
          field.type === 'MultiRowControl' &&
          Array.isArray(fieldValue) &&
          fieldValue.length === 0
        ) {
          showToast(
            t('fillOutForm.addAtLeastOneRow', { label: field.label }),
            'error'
          );
          return;
        }
        if (
          (field.type === 'DatePicker' || field.type === 'TimePicker') &&
          !fieldValue
        ) {
          showToast(
            t('fillOutForm.selectValueForField', { label: field.label }),
            'error'
          );
          return;
        }
        if (field.type === 'RangeSlider') {
          const { min, max } = field;
          if (
            typeof min === 'number' &&
            typeof max === 'number' &&
            (formData[field.id] < min || formData[field.id] > max)
          ) {
            showToast(
              t('fillOutForm.fieldMustBeBetween', {
                label: field.label,
                min,
                max,
              }),
              'error'
            );
            return;
          }
        }
        // Specific validation for ColorPicker
        if (field.type === 'ColorPicker') {
          const isValidColor = /^#([0-9A-F]{3}){1,2}$/i.test(fieldValue);
          if (!isValidColor) {
            showToast(
              t('fillOutForm.invalidColor', { label: field.label }),
              'error'
            );
            return;
          }
        }
      }
    }

    // Validate Telephone Fields
    const telephoneFields = fields.filter((field) => field.type === 'Telephone');
    let updatedFormData = { ...formData };

    for (let field of telephoneFields) {
      const phoneValue = formData[field.id];
      const formattedPhone = validateAndFormatPhone(phoneValue);

      if (!formattedPhone) {
        showToast(
          t('fillOutForm.invalidPhoneNumber', { label: field.label }),
          'error'
        );
        return; // Prevent form submission
      }

      // Update the phone number with the formatted value
      updatedFormData[field.id] = formattedPhone;
    }

    // Assign Default Image if Necessary
    const profilePictureField = fields.find(
      (field) => field.id === 'profilePicure' || field.id === 'profilePicture'
    );

    if (profilePictureField && profilePictureField.mandatory) {
      const profilePictureValue = updatedFormData[profilePictureField.id];
      if (
        !profilePictureValue ||
        (Array.isArray(profilePictureValue) && profilePictureValue.length === 0) ||
        (typeof profilePictureValue === 'string' &&
          profilePictureValue.trim() === '')
      ) {
        if (formId === 'membersForm') {
          updatedFormData[profilePictureField.id] = UserIcon;
        } else if (formId === 'groupsForm') {
          updatedFormData[profilePictureField.id] = CatholicoreLogoSimple;
        }
      }
    }

    setSubmitting(true);
    try {
      // Transform formData to include label, type, and order
      const responseFields = fields
        .filter((field) => field.type !== 'Section')
        .map((field, index) => {
          let fieldValue = updatedFormData[field.id];

          // Special handling for DatePicker, TimePicker, RangeSlider, and ColorPicker
          if (field.type === 'DatePicker' || field.type === 'TimePicker') {
            fieldValue = fieldValue instanceof Date ? fieldValue.toISOString() : null;
          } else if (field.type === 'RangeSlider') {
            fieldValue =
              fieldValue !== undefined ? Number(fieldValue) : field.min || 0;
          } else if (field.type === 'ColorPicker') {
            fieldValue = fieldValue || '#000000'; // Ensure a default color
          }

          // Handle ImagePicker fields
          if (field.type === 'ImagePicker') {
            // If profilePicture and missing, already assigned above
            // Otherwise, use the provided image
            if (
              (field.id === 'profilePicure' || field.id === 'profilePicture') &&
              (!fieldValue || fieldValue.trim() === '')
            ) {
              if (formId === 'membersForm') {
                fieldValue = UserIcon;
              } else if (formId === 'groupsForm') {
                fieldValue = CatholicoreLogoSimple;
              }
            }
          }

          // Handle Checkbox fields
          if (field.type === 'Checkbox') {
            // Save as a map with name and completed
            // Ensure no undefined values
            fieldValue = fieldValue.map((option) => ({
              name: option.name || '', // Default to empty string if undefined
              completed:
                option.completed !== undefined ? option.completed : false, // Default to false if undefined
            }));
          }

          // Handle MultiRowControl fields (no special handling needed unless you want to transform data)

          return {
            id: field.id,
            label: field.label,
            type: field.type, // Include field type
            order: index + 1, // 1-based indexing for order
            value: fieldValue !== undefined ? fieldValue : null, // Ensure value is not undefined
            // Include min and max for RangeSlider
            ...(field.type === 'RangeSlider' && {
              min: field.min !== undefined ? field.min : 0,
              max: field.max !== undefined ? field.max : 100,
            }),
          };
        });

      // Sanitize responseFields to remove any undefined values
      const sanitizedResponseFields = sanitizeData(responseFields);

      // Prepare the complete responseData
      const responseData = {
        fields: sanitizedResponseFields,
        // Timestamps will be set in DataContext
      };

      // Add groupEntry: only if formId is 'membersForm'
      if (formId === 'membersForm') {
        const groupEntry = {
          groupId: organizationData.groupId,
          memberType: 'member',
          organizationId: organizationData.organizationId,
        };
        responseData.groups = [groupEntry];
      }

      // Submit or update the response
      if (responseId) {
        await updateResponse(formId, responseId, responseData);
        showToast(t('fillOutForm.responseUpdatedSuccessfully'), 'success');
      } else {
        await submitFormResponse(formId, responseData);
        showToast(t('fillOutForm.responseSubmittedSuccessfully'), 'success');
      }

      setFormData(initializeFormState());
      setFormErrors({});

      // If in edit mode, call onCancel to close the form
      if (responseId && onCancel) {
        onCancel();
      }

      if (onFormSubmit) {
        onFormSubmit(); // Notify parent component
      }
    } catch (error) {
      console.error('Error submitting response:', error);
      if (responseId) {
        showToast(t('fillOutForm.errorUpdatingResponse'), 'error');
      } else {
        showToast(t('fillOutForm.errorSubmittingResponse'), 'error');
      }
    } finally {
      setSubmitting(false);
    }
  };

  // Function to group fields into rows
  const groupFieldsIntoRows = (fields) => {
    const rows = [];
    let currentRow = [];

    fields.forEach((field) => {
      const size = field.size || 'full'; // Default to 'full' if size is not specified

      if (field.type === 'Section') {
        if (currentRow.length > 0) {
          rows.push([...currentRow]);
          currentRow = [];
        }
        rows.push([field]);
        return;
      }

      if (size === 'half') {
        currentRow.push(field);
        if (currentRow.length === 2) {
          rows.push([...currentRow]);
          currentRow = [];
        }
      } else {
        if (currentRow.length > 0) {
          rows.push([...currentRow]);
          currentRow = [];
        }
        rows.push([field]);
      }
    });

    // Push any remaining fields in currentRow
    if (currentRow.length > 0) {
      rows.push([...currentRow]);
    }

    return rows;
  };

  const rows = groupFieldsIntoRows(fields);

  // Render individual fields based on their type
  const renderField = (field) => {
    if (field.type === 'Section') {
      return (
        <div key={field.id} className="form-field full-width">
          <Section label={t(camelCase(field.label))} />
        </div>
      );
    }

    const commonProps = {
      label: t(field.id, field.label),
      name: field.name || field.id,
      value: formData[field.id],
      onChange: (value) => handleFieldChange(field.id, value),
      required: field.required,
      size: field.size,
      onValidate: (error) => handleValidation(field.id, error),
      length: field.length || null,
      error: formErrors[field.id] || '',
    };

    let FieldComponent = null;
    switch (field.type) {
      case 'Text':
        FieldComponent = (
          <TextField {...commonProps} placeholder={t('fillOutForm.enterText')} />
        );
        break;
      case 'Number':
        FieldComponent = (
          <NumberField
            {...commonProps}
            placeholder={t('fillOutForm.enterNumber')}
            min={field.min}
            max={field.max}
          />
        );
        break;
      case 'DatePicker':
        FieldComponent = (
          <DatePicker {...commonProps} placeholder={t('fillOutForm.selectDate')} />
        );
        break;
      case 'TimePicker':
        FieldComponent = (
          <TimePicker {...commonProps} placeholder={t('fillOutForm.selectTime')} />
        );
        break;
      case 'ToggleSwitch':
        FieldComponent = (
          <div className="toggle-switch-field">
            <ToggleSwitch
              id={field.id}
              label={t(camelCase(field.label))}
              labelPosition={'left'}
              checked={formData[field.id]}
              onChange={(value) => handleFieldChange(field.id, value)}
              activeColor="#4CAF50" // Customize as needed
            />
          </div>
        );
        break;
      case 'ImagePicker':
        FieldComponent = (
          <ImagePicker
            {...commonProps}
            imageShape={field.imageShape}
            imagePickerText={t(camelCase(field.imagePickerText))}
            maxFileSize={500} // 500KB limit
          />
        );
        break;
      case 'Checkbox':
        FieldComponent = (
          <CheckboxField
            {...commonProps}
            options={field.options}
            onChange={(updatedOptions) => handleFieldChange(field.id, updatedOptions)}
          />
        );
        break;
      case 'RadioButton':
        FieldComponent = <RadioButtonField {...commonProps} options={field.options} />;
        break;
      case 'Telephone':
        FieldComponent = (
          <TelephoneField
            {...commonProps}
            placeholder={t('fillOutForm.telephonePlaceholder')}
            onChange={(value) => handleFieldChange(field.id, value)}
          />
        );
        break;
      case 'Email':
        FieldComponent = (
          <EmailField
            {...commonProps}
            placeholder={t('fillOutForm.emailPlaceholder')}
          />
        );
        break;
      case 'Select':
        let options = [];
        let isLoadingOptions = false;
        if (field.catalogId) {
          const catalog = catalogData[field.catalogId];
          if (catalog && catalog.codes) {
            options = catalog.codes
              .filter((code) => code.active !== false)
              .sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((code) => ({
                label: code.description || code.value,
                value: code.value,
              }));
          } else {
            isLoadingOptions = true;
          }
        } else if (field.options) {
          options = field.options;
        }

        FieldComponent = (
          <SelectField
            {...commonProps}
            placeholder={
              isLoadingOptions
                ? t('fillOutForm.loadingOptions')
                : t('fillOutForm.selectAnOption')
            }
            options={options}
            disabled={isLoadingOptions}
          />
        );
        break;
      case 'TextArea':
        FieldComponent = (
          <TextAreaField
            {...commonProps}
            placeholder={t('fillOutForm.enterMultipleLines')}
            rows={4}
            maxLength={field.length}
          />
        );
        break;
      case 'RangeSlider':
        FieldComponent = (
          <RangeSliderField
            {...commonProps}
            min={field.min}
            max={field.max}
            step={field.step}
            showValue={field.showValue}
          />
        );
        break;
        case 'MultiRowControl':
          FieldComponent = (
            <MultiRowControl
              {...commonProps}
              fields={formData[field.id] || []}
              rowFields={field.rowFields}
              onChange={(updatedRows) => handleChange(field.id, updatedRows)}
              onAddRow={() => {
                const newRow = { id: `row_${Date.now()}` };
                field.rowFields.forEach((f) => {
                  newRow[f.id] = '';
                });
                const currentRows = formData[field.id] || [];
                handleChange(field.id, [...currentRows, newRow]);
              }}
              onRemoveRow={(rowId) => {
                const currentRows = formData[field.id] || [];
                const updatedRows = currentRows.filter((row) => row.id !== rowId);
                handleChange(field.id, updatedRows);
              }}
            />
          );
          break;
      case 'ColorPicker':
        FieldComponent = (
          <ColorPicker
            {...commonProps}
            value={formData[field.id]}
            onChange={(value) => handleFieldChange(field.id, value)}
          />
        );
        break;
      default:
        FieldComponent = null;
    }

    return (
      <div
        key={field.id}
        className={`form-field ${field.size === 'half' ? 'half-width' : 'full-width'}`}
      >
        {FieldComponent}
      </div>
    );
  };

  return (
    <form className="fill-out-form" onSubmit={handleSubmit}>
      {rows.map((row, rowIndex) => (
        <div className="form-row" key={rowIndex}>
          {row.map((field) => renderField(field))}
        </div>
      ))}
      {rows.length > 0 && (
        <div className="form-actions">
          <ActionButton
            type="submit"
            label={responseId ? t('fillOutForm.update') : t('fillOutForm.submit')}
            text={responseId ? t('fillOutForm.update') : t('fillOutForm.submit')}
            icon={responseId ? 'faSave' : 'faPaperPlane'}
            isMobile={false}
            colorType="primary"
            disabled={submitting}
            className="submit-button full-width-button"
          />
          {responseId && onCancel && (
            <ActionButton
              type="button"
              label={t('fillOutForm.cancel')}
              text={t('fillOutForm.cancel')}
              icon="faTimes"
              isMobile={false}
              colorType="secondary"
              disabled={submitting}
              className="cancel-button full-width-button"
              onClick={onCancel}
            />
          )}
        </div>
      )}
      {rows.length === 0 && (
        <div className="info-message">{t('fillOutForm.buildFormToFill')}</div>
      )}
    </form>
  );
};

FillOutForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  formId: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired,
  initialData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      })
    ).isRequired,
  }),
  responseId: PropTypes.string,
  onCancel: PropTypes.func, // Function to call when canceling edit
  onFormSubmit: PropTypes.func,
};

export default FillOutForm;