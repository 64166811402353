// Footer.jsx
import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="app-footer">
      <p>
        Catholicore &copy; {new Date().getFullYear()} {t('footer.copyright')}
      </p>
    </footer>
  );
};

export default Footer;
