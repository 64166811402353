// src/components/FormBuilder/Fields/MultiRowControl.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './MultiRowControl.css';
import ActionButton from '../../Common/ActionButton';
import TextField from './TextField';
import NumberField from './NumberField';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import SelectField from './SelectField';
import CheckboxField from './CheckboxField';
import RadioButtonField from './RadioButtonField';
import TextAreaField from './TextAreaField';
import ToggleSwitch from './ToggleSwitch';
import ColorPicker from './ColorPicker';
import RangeSliderField from './RangeSliderField';

const MultiRowControl = ({
  label,
  name,
  fields,
  rowFields,
  onChange,
  onAddRow,
  onRemoveRow,
}) => {
  // Ensure fields is an array
  const rows = Array.isArray(fields) ? fields : [];

  const handleFieldChange = (rowIndex, fieldName, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      [fieldName]: value,
    };
    onChange(updatedRows);
  };

  const renderFieldComponent = (field, rowIndex, rowId) => {
    const fieldName = field.id;
    const value = rows[rowIndex][fieldName] || '';

    // Include size in commonProps
    const commonProps = {
      label: field.label,
      name: `${fieldName}_${rowId}`,
      value: value,
      onChange: (value) => handleFieldChange(rowIndex, fieldName, value),
      placeholder: field.placeholder || '',
      required: field.required || false,
      disabled: field.disabled || false,
      size: field.size || 'full', // Include size here
      ...(field.length && { maxLength: field.length }),
      ...(field.min !== undefined && { min: field.min }),
      ...(field.max !== undefined && { max: field.max }),
      ...(field.options && { options: field.options }),
      ...(field.step !== undefined && { step: field.step }),
      ...(field.showValue !== undefined && { showValue: field.showValue }),
    };

    let FieldComponent = null;

    switch (field.type) {
      case 'Text':
        FieldComponent = <TextField {...commonProps} />;
        break;
      case 'Number':
        FieldComponent = <NumberField {...commonProps} />;
        break;
      case 'DatePicker':
        FieldComponent = <DatePicker {...commonProps} />;
        break;
      case 'TimePicker':
        FieldComponent = <TimePicker {...commonProps} />;
        break;
      case 'Select':
        FieldComponent = <SelectField {...commonProps} />;
        break;
      case 'Checkbox':
        FieldComponent = (
          <CheckboxField
            {...commonProps}
            onChange={(updatedOptions) =>
              handleFieldChange(rowIndex, fieldName, updatedOptions)
            }
          />
        );
        break;
      case 'RadioButton':
        FieldComponent = <RadioButtonField {...commonProps} />;
        break;
      case 'TextArea':
        FieldComponent = <TextAreaField {...commonProps} />;
        break;
      case 'ToggleSwitch':
        FieldComponent = (
          <ToggleSwitch
            id={commonProps.name}
            label={commonProps.label}
            checked={commonProps.value}
            onChange={commonProps.onChange}
          />
        );
        break;
      case 'ColorPicker':
        FieldComponent = <ColorPicker {...commonProps} />;
        break;
      case 'RangeSlider':
        FieldComponent = <RangeSliderField {...commonProps} />;
        break;
      default:
        FieldComponent = null;
    }

    return FieldComponent;
  };

  return (
    <div className="form-group multi-row-group">
      <label>{label}</label>
      {rows.map((row, index) => (
        <div key={row.id} className="multi-row">
          <div className="multi-row-fields">
            {rowFields.map((field) => (
              <div
                key={field.id}
                className={`multi-row-field ${
                  field.size === 'half' ? 'half-width' : 'full-width'
                }`}
              >
                {renderFieldComponent(field, index, row.id)}
              </div>
            ))}
          </div>
          <div className="multi-row-actions">
            <ActionButton
              onClick={() => onRemoveRow(row.id)}
              label=""
              icon="faTrash"
              isMobile={false}
              size="small"
              colorType="danger"
              className="remove-row-button"
            />
          </div>
        </div>
      ))}
      <div className="add-row-button-container">
        <ActionButton
          onClick={onAddRow}
          label="Add Row"
          icon="faPlus"
          isMobile={false}
          colorType="primary"
          className="add-row-button"
        />
      </div>
    </div>
  );
};

MultiRowControl.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object),
  rowFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      required: PropTypes.bool,
      options: PropTypes.array,
      size: PropTypes.string, // Include size in prop types
      length: PropTypes.number,
      min: PropTypes.number,
      max: PropTypes.number,
      step: PropTypes.number,
      showValue: PropTypes.bool,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onRemoveRow: PropTypes.func.isRequired,
};

MultiRowControl.defaultProps = {
  fields: [],
};

export default MultiRowControl;
