// src/components/Common/ToggleSwitch.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.css'; // Ensure this CSS is updated

const ToggleSwitch = ({
  id,
  checked,
  onChange,
  activeColor,
  label,
  labelPosition,
}) => {
  return (
    <div
      className={`toggle-switch-container ${
        labelPosition === 'left' ? 'label-left' : 'label-right'
      }`}
    >
      {label && labelPosition === 'left' && (
        <span className="toggle-switch-label">{label}</span>
      )}
      <label className="toggle-switch">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span
          className="slider"
          style={{
            backgroundColor: checked ? activeColor : '',
          }}
        ></span>
      </label>
      {label && labelPosition === 'right' && (
        <span className="toggle-switch-label">{label}</span>
      )}
    </div>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  activeColor: PropTypes.string, // New prop for active color
  label: PropTypes.string, // New prop for label
  labelPosition: PropTypes.oneOf(['left', 'right']), // New prop for label position
};

ToggleSwitch.defaultProps = {
  activeColor: '#2196F3', // Default color (blue) if not specified
  label: '', // Default to no label
  labelPosition: 'right', // Default label position
};

export default ToggleSwitch;
