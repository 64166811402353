// src/components/Common/ContextMenu.jsx

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import './ContextMenu.css';

const ContextMenu = ({ isVisible, position, options, onClose }) => {
  const menuRef = React.useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div
      className="context-menu"
      style={{ top: position.y, left: position.x }}
      ref={menuRef}
    >
      {options.map((option, index) => (
        <div
          key={index}
          className={`context-menu-item ${
            option.disabled ? 'disabled' : ''
          }`}
          onClick={() => {
            if (!option.disabled) {
              option.onClick();
              onClose();
            }
          }}
        >
          {option.label}
        </div>
      ))}
    </div>,
    document.getElementById('modal-root') // Ensure this matches your portal root
  );
};

ContextMenu.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

ContextMenu.defaultProps = {
  position: { x: 0, y: 0 },
};

export default ContextMenu;
