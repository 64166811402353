// src/components/Common/LanguageSettings.jsx

import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LanguageSettingsContext from './LanguageSettingsContext';
import { DataContext } from '../../DataContext';
import { useTranslation } from 'react-i18next';

const LanguageSettings = ({ children }) => {
  const { appSettings, appSettingsLoading, updateSettings } = useContext(DataContext);
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!appSettingsLoading && appSettings && appSettings.language) {
      const currentLanguage = appSettings.language;
      console.log('Setting language to:', currentLanguage);
      i18n.changeLanguage(currentLanguage).then(() => {
        // After changing the language, set loading to false
        setLoading(false);
      });
    }
  }, [appSettings, appSettingsLoading, i18n]);

  // Function to update language
  const handleLanguageChange = async (newLanguage) => {
    try {
      await updateSettings({ language: newLanguage });
    } catch (error) {
      console.error('Error updating language settings:', error);
      // Optionally, display a toast or notification
    }
  };

  return (
    <LanguageSettingsContext.Provider
      value={{
        language: appSettings ? appSettings.language : 'en',
        updateLanguage: handleLanguageChange,
        loading: loading || appSettingsLoading,
      }}
    >
      {children}
    </LanguageSettingsContext.Provider>
  );
};

export default LanguageSettings;
