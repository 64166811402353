// src/components/FormBuilder/Fields/TimePicker.jsx

import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './TimePicker.css';

const CustomTimePicker = ({ label, name, value, onChange, placeholder, error }) => {
  // Handler to pass only the Date object to the parent
  const handleTimeChange = (time) => {
    onChange(time);
  };

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <ReactDatePicker
        id={name}
        selected={value}
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        placeholderText={placeholder}
        className={`form-control ${error ? 'input-error' : ''}`}
        isClearable
        aria-invalid={error ? 'true' : 'false'}
        aria-describedby={error ? `${name}-error` : undefined}
      />
      {error && <span id={`${name}-error`} className="error-message">{error}</span>}
    </div>
  );
};

CustomTimePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

CustomTimePicker.defaultProps = {
  value: null,
  placeholder: '',
  error: '',
};

export default CustomTimePicker;
