// Logo.jsx
import React from 'react';
import './Logo.css';
import { useTranslation } from 'react-i18next';
import CatholicoreLogo from '../../assets/CatholicoreLogo.png';

const Logo = ({ profilePicture }) => {
  const { t } = useTranslation();

  return (
    <div className="logo-container">
      <img
        src={
          profilePicture ||
          CatholicoreLogo
        }
        alt={t('logo.alt')}
        className="app-logo"
      />
    </div>
  );
};

export default Logo;
