// src/pages/TeamsPage.jsx

import React, { useContext, useState, useEffect } from 'react';
import TeamsTable from './TeamsTable';
import { DataContext } from '../../DataContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';

import TeamForm from '../Teams/TeamForm';
import './TeamsPage.css';

const TeamsPage = () => {
  const { t } = useTranslation();

  // State variables
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [name, setName] = useState('');
  const [members, setMembers] = useState([]);
  const [editingTeam, setEditingTeam] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [toasts, setToasts] = useState([]);

  // Consume DataContext
  const {
    teams,
    isTeamsLoading,
    addTeam,
    editTeam,
    deleteTeam,
    fetchTeams,
    useResponses,
  } = useContext(DataContext);

  // Fetch members data using useResponses
  const {
    data: responses = [],
    isLoading: membersLoading,
    error: membersError,
    refetch: refetchMembers,
  } = useResponses('membersForm');

  // Map responses to members
  const membersData = responses.map((response) => {
    const member = {};
    response.fields.forEach((field) => {
      member[field.id] = field.value;
    });
    member.id = response.id;
    member.fields = response.fields;
    member.submittedAt = response.submittedAt;
    member.createdAt = response.createdAt;
    member.updatedAt = response.updatedAt;
    member.groupId = response.groups ? response.groups[0].groupId:'';
    return member;
  });

  // Toast Management
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Handle window resize for isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch teams when the component mounts
  useEffect(() => {
    fetchTeams();
  }, []);

  // Loading and Error States
  if (isTeamsLoading || membersLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  // Action Handlers
  const handleAddTeam = () => {
    setModalTitle(t('teams.addTeam'));
    setName('');
    setMembers([]);
    setEditingTeam(null);
    setShowTeamModal(true);
  };

  const handleEditTeam = (team) => {
    setModalTitle(t('teams.editTeam',{
      name: `${team.name}`,
    }));
    setName(team.name);

    // Extract member IDs from DocumentReferences
    const memberIds = (team.membersData || []).map((member) => {
    const memberId = member.id;
    const groupId = member.groups ? member.groups[0].groupId:'';
    return groupId+"_"+memberId;
    });
    setMembers(memberIds);

    setEditingTeam(team);
    setShowTeamModal(true);
  };

  const handleDeleteTeam = (team) => {
    setTeamToDelete(team);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: `${team.name}`,
      })
    );
    setShowDeleteModal(true);
  };

  const confirmDeleteTeam = async () => {
    if (!teamToDelete) return;

    try {
      await deleteTeam(teamToDelete.id);
      showToast(t('teams.deleteSuccess'), 'success');
    } catch (error) {
      console.error('Error deleting team: ', error);
      showToast(`${t('teams.deleteError')}: ${error.message}`, 'error');
    } finally {
      setShowDeleteModal(false);
      setTeamToDelete(null);
    }
  };

  const handleTeamModalSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim()) {
      showToast(t('teams.nameRequired'), 'error');
      return;
    }

    if (!editingTeam) {
      try {
        await addTeam({ name, members });
        showToast(t('teams.addSuccess'), 'success');
        setShowTeamModal(false);
      } catch (error) {
        console.error('Error adding team: ', error);
        showToast(`${t('teams.addError')}: ${error.message}`, 'error');
      }
    } else {
      try {
        await editTeam(editingTeam.id, { name, members });
        showToast(t('teams.updateSuccess'), 'success');
        setShowTeamModal(false);
        setEditingTeam(null);
      } catch (error) {
        console.error('Error updating team: ', error);
        showToast(`${t('teams.updateError')}: ${error.message}`, 'error');
      }
    }
  };

  return (
    <div className="teams-page-container">
      {/* Header Section */}
      <div className="teams-page-header">
        <h2>{t('teamsHeader.title')}</h2>
      </div>

      {/* TeamsTable Component */}
      <TeamsTable
        teams={teams}
        handleAddTeam={handleAddTeam}
        handleEditTeam={handleEditTeam}
        handleDeleteTeam={handleDeleteTeam}
        isTeamsLoading={isTeamsLoading}
        isMobile={isMobile}
      />

      {/* Team Modal for Adding/Editing Teams */}
      {showTeamModal && (
        <CustomModal
          show={showTeamModal}
          onClose={() => setShowTeamModal(false)}
          title={modalTitle}
        >
          <TeamForm
            name={name}
            setName={setName}
            members={members}
            setMembers={setMembers}
            isMobile={isMobile}
            onSubmit={handleTeamModalSubmit}
            isEditing={editingTeam !== null}
            membersData={membersData} // Pass members data to TeamForm
          />
        </CustomModal>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={confirmDeleteTeam}
          onCancel={() => setShowDeleteModal(false)}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
          isMobile={isMobile}
        />
      )}

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default TeamsPage;
