// ImagePreview.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './ImagePreview.css';
import { useTranslation } from 'react-i18next';

const ImagePreview = ({ imagePreviewUrl, removeImage }) => {
  const { t } = useTranslation();

  return (
    <div className="image-container">
      <img src={imagePreviewUrl} alt={t('imagePreview.alt')} />
      <button
        type="button"
        onClick={removeImage}
        className="remove-button"
        aria-label={t('imagePreview.removeAriaLabel')}
      >
        &times;
      </button>
    </div>
  );
};

ImagePreview.propTypes = {
  imagePreviewUrl: PropTypes.string.isRequired,
  removeImage: PropTypes.func.isRequired,
};

export default ImagePreview;
