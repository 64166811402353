import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Authentication/Login';
import MainScreen from './components/Main/MainScreen';
import PasswordRecovery from './components/Authentication/PasswordRecovery';
import ProtectedRoute from './components/Authentication/ProtectedRoute';
import ResetPassword from './components/Authentication/ResetPassword';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import TermsService from './components/Policies/TermsService';
import ColorSettings from './components/Common/ColorSettings';
import LanguageSettings from './components/Common/LanguageSettings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorBoundary from './components/Common/ErrorBoundary';
import './App.css';
import { useColorSettings } from './components/Common/ColorSettingsContext';
import { useLanguageSettings } from './components/Common/LanguageSettingsContext';
import Layout from './components/Common/Layout';
import { useParams } from 'react-router-dom';
import { DataProvider } from './DataContext';
import PublicForm from './components/FormBuilder/PublicForm';
import NotFound from './NotFound';

const queryClient = new QueryClient();
const defaultGroupID = 'LyXhk2BhqBQCsGI4mqJB';

const AppContent = () => {
  const { groupId } = useParams();
  const groupID = groupId || defaultGroupID;

  return (
    <DataProvider groupID={groupID}>
      <LanguageSettings>
        <ColorSettings>
          <Layout>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="main"
                element={
                  <ProtectedRoute>
                    <MainScreen />
                  </ProtectedRoute>
                }
              />
              <Route path="password-recovery" element={<PasswordRecovery />} />
              <Route path="reset-password" element={<ResetPassword />} />
              
              {/* Public Form Route */}
              <Route path="forms/:formId" element={<PublicForm />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </ColorSettings>
      </LanguageSettings>
    </DataProvider>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <Router>
          <Routes>
            {/* Redirect root to default group */}
            <Route path="/" element={<Navigate to={`/${defaultGroupID}/`} />} />

            {/* Policies Accessible at Top-Level */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsService />} />

            {/* Group-Specific Routes */}
            <Route path="/:groupId/*" element={<AppContent />} />

            {/* 404 Not Found Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </QueryClientProvider>
  );
};

export default App;
