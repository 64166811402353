// src/firebase.js

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { FireSQL } from 'firesql';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || '',
};

let firebaseApp;

try {
  // Check if all required config values are present
  const isFirebaseConfigValid = Object.values(firebaseConfig).every((value) => value !== '');

  if (!isFirebaseConfigValid) {
    throw new Error('Firebase configuration is invalid. Please check your environment variables.');
  }

  // Initialize Firebase only if it hasn't been initialized yet
  if (!firebase.apps.length) {
    firebaseApp = firebase.initializeApp(firebaseConfig);
    console.log('Firebase initialized successfully.');
  } else {
    firebaseApp = firebase.app(); // Use the existing initialized app
    console.log('Firebase app already initialized.');
  }
} catch (error) {
  console.error('Firebase initialization error:', error);
  // Optionally, you can display an alert or set up a UI to notify the user
  alert('Error initializing Firebase. Please check your configuration.');
  // You might also want to handle the error by redirecting the user or disabling certain features
}

// Export Firestore, Auth, and Storage if initialization was successful
export const firestore = firebaseApp ? firebaseApp.firestore() : null;
export const auth = firebaseApp ? firebaseApp.auth() : null;
export const storage = firebaseApp ? firebaseApp.storage() : null;

// Initialize FireSQL if Firestore is available
export const fireSQL = firestore ? new FireSQL(firestore) : null;

// Enable Firestore Offline Persistence
if (firestore) {
  firestore.enablePersistence()
    .then(() => {
      console.log('Firestore offline persistence enabled.');
    })
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled in one tab at a time.
        console.warn('Firestore persistence failed: Multiple tabs open.');
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the features required to enable persistence
        console.warn('Firestore persistence is not available in this browser.');
      } else {
        console.error('Error enabling Firestore persistence:', err);
      }
    });
}
