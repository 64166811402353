// src/components/FormBuilder/RowFieldsBuilder.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './RowFieldsBuilder.css';
import ActionButton from '../Common/ActionButton';
import FieldSelector from './FieldSelector';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';

const RowFieldsBuilder = ({ rowFields, setRowFields }) => {
  const { t } = useTranslation();
  const [editingRowFieldId, setEditingRowFieldId] = useState(null);

  const handleSaveRowField = (fieldData) => {
    let updatedFields;

    if (editingRowFieldId) {
      if (editingRowFieldId === 'new') {
        // Adding a new field
        const fieldId = camelCase(fieldData.label.trim());
        const existingField = rowFields.find((field) => field.id === fieldId);
        if (existingField) {
          alert(t('fieldSelector.alertDuplicateFieldName'));
          return;
        }
        const newField = {
          id: fieldId,
          ...fieldData,
        };
        updatedFields = [...rowFields, newField];
      } else {
        // Editing an existing field
        updatedFields = rowFields.map((field) =>
          field.id === editingRowFieldId ? { ...field, ...fieldData } : field
        );
      }
      setEditingRowFieldId(null);
    }
    setRowFields(updatedFields);
  };

  const handleEditRowField = (fieldId) => {
    setEditingRowFieldId(fieldId);
  };

  const handleRemoveRowField = (fieldId) => {
    const updatedFields = rowFields.filter((field) => field.id !== fieldId);
    setRowFields(updatedFields);
  };

  const handleCancelEdit = () => {
    setEditingRowFieldId(null);
  };

  const handleAddRowField = () => {
    setEditingRowFieldId('new');
  };

  return (
    <div className="row-fields-builder">
      <div className="row-fields-header">
        <h3>{t('fieldSelector.rowFields')}</h3>
      </div>

      <div className="row-fields-list">
        {rowFields.length > 0 ? (
          rowFields.map((field, index) =>
            editingRowFieldId === field.id ? (
              <div key={field.id} className="row-field-selector">
                <FieldSelector
                  onAddField={handleSaveRowField}
                  onCancel={handleCancelEdit}
                  initialField={field}
                />
              </div>
            ) : (
              <div key={field.id} className="row-field-item">
                <div className="row-field-info">
                  <span className="field-label">{field.label}</span>
                  <span className="field-type">{t(`fieldTypes.${field.type}`)}</span>
                </div>
                <div className="row-field-actions">
                  <ActionButton
                    onClick={() => handleEditRowField(field.id)}
                    label={t('fieldSelector.edit')}
                    icon="faEdit"
                    isMobile={false}
                    colorType="secondary"
                    className="edit-row-field-button"
                  />
                  <ActionButton
                    onClick={() => handleRemoveRowField(field.id)}
                    label={t('fieldSelector.remove')}
                    icon="faTrash"
                    isMobile={false}
                    colorType="danger"
                    className="remove-row-field-button"
                  />
                </div>
              </div>
            )
          )
        ) : (
          <p className="no-row-fields-message">{t('fieldSelector.noRowFieldsAdded')}</p>
        )}

        {editingRowFieldId === 'new' && (
          <div className="row-field-selector">
            <FieldSelector
              onAddField={handleSaveRowField}
              onCancel={handleCancelEdit}
              initialField={null}
            />
          </div>
        )}
      </div>
    
      {!editingRowFieldId && (
        <ActionButton
          onClick={handleAddRowField}
          label={t('fieldSelector.addRowField')}
          text={t('fieldSelector.addRowField')}
          icon="faPlus"
          isMobile={false}
          colorType="primary"
          spacing="8px"
          className="action-button size-medium primary full-width-button"
        />
      )}

    </div>
  );
};

RowFieldsBuilder.propTypes = {
  rowFields: PropTypes.array.isRequired,
  setRowFields: PropTypes.func.isRequired,
};

export default RowFieldsBuilder;
