// src/components/FormBuilder/Fields/ImagePicker.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../Common/Spinner';
import placeholderImage from '../../../assets/placeholder.png'; // Adjust the path as needed
import './ImagePicker.css';

const ImagePicker = ({
  label,
  name,
  value,
  onChange,
  required,
  imageShape = 'circular',
  imagePickerText = '',
  maxFileSize = 500, // Max file size in KB
}) => {
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (value && typeof value === 'string') {
      setPreview(value);
    } else {
      setPreview(placeholderImage);
    }
  }, [value]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file size
      const fileSizeKB = file.size / 1024;
      if (fileSizeKB > maxFileSize) {
        alert(
          `The selected file exceeds the maximum size of ${maxFileSize}KB. Please choose a smaller file.`
        );
        return;
      }

      setLoading(true);
      setError('');

      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result;
          setPreview(base64String);
          onChange(base64String); // Pass Base64 string to parent
          setLoading(false);
        };
        reader.onerror = () => {
          console.error('Error reading file');
          setError('Failed to read file.');
          setLoading(false);
        };
        reader.readAsDataURL(file);
      } catch (err) {
        console.error('Error uploading photo:', err);
        setError('An unexpected error occurred.');
        setLoading(false);
      }
    } else {
      onChange(null);
      setPreview(placeholderImage);
      setError('');
    }
  };

  return (
    <div className="image-picker-container">
      <div className="image-picker">
        <input
          type="file"
          id={name}
          name={name}
          accept="image/*"
          onChange={handleImageChange}
          className="image-picker-input"
        />
        <label htmlFor={name} className="image-picker-label">
          <div
            className={`image-picker-icon ${
              imageShape === 'circular' ? 'circular' : 'square'
            }`}
          >
            {loading && (
              <div className="image-spinner">
                <Spinner size="50px" fullScreen={false} />
              </div>
            )}
            <img
              src={preview}
              alt={label}
              onLoad={() => setLoading(false)}
              onError={() => {
                setPreview(placeholderImage);
                setLoading(false);
                setError('Failed to load image.');
              }}
            />
          </div>
          <span className="image-picker-text">
            {imagePickerText || label}
            {required && <span className="required-asterisk">*</span>}
          </span>
        </label>
      </div>
      {error && <p className="image-picker-error">{error}</p>}
    </div>
  );
};

ImagePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string, // Now expects a Base64 string or null
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  imageShape: PropTypes.oneOf(['circular', 'square']),
  imagePickerText: PropTypes.string,
  maxFileSize: PropTypes.number, // in KB
};

ImagePicker.defaultProps = {
  value: null,
  required: false,
  imageShape: 'circular',
  imagePickerText: '',
  maxFileSize: 500, // Default to 500KB
};

export default ImagePicker;