// src/components/ColorPickerGroup.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './ColorPickerGroup.css';
import { useTranslation } from 'react-i18next';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import Spinner from '../Common/Spinner';

const ColorPickerGroup = ({ primaryColor, setPrimaryColor }) => {
  const { t } = useTranslation();

  const { language, updateLanguage, loading: languageLoading } = useLanguageSettings();
  const { appSettings, loading: colorLoading } = useColorSettings();

  if (colorLoading || languageLoading) {
    return (
      <div className="form-group color-picker-group">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="form-group color-picker-group">
      <label htmlFor="primaryColor">{t('colorPickerGroup.label')}:</label>
      <input
        type="color"
        id="primaryColor"
        value={primaryColor}
        onChange={(e) => setPrimaryColor(e.target.value)}
        required
        aria-required="true"
      />
    </div>
  );
};

ColorPickerGroup.propTypes = {
  primaryColor: PropTypes.string.isRequired,
  setPrimaryColor: PropTypes.func.isRequired,
};

export default ColorPickerGroup;
