// src/components/Common/GlobalSpinner.jsx

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import './GlobalSpinner.css';

// Helper function to convert hex color to rgba
const hexToRGBA = (hex, opacity) => {
  let c;
  const hexPattern = /^#([A-Fa-f0-9]{3}){1,2}$/;
  if (hexPattern.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      opacity +
      ')'
    );
  }
  return hex; // If not a valid hex, return the original value
};

const GlobalSpinner = ({
  show,
  size,
  color,
  extraDelay,
  opaque,
  overlayColor,
  overlayOpacity,
  animationDuration,
  logo,
  isLogoTransitioning, // New prop
}) => {
  const [shouldRender, setShouldRender] = useState(show);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    let timer1;
    let timer2;

    if (show) {
      setShouldRender(true);
      // Trigger fade-in animation
      setTimeout(() => {
        setAnimationClass('fade-in-active');
      }, 10); // Delay to allow CSS transition
    } else if (shouldRender) {
      // Wait for extraDelay before starting fade-out
      timer1 = setTimeout(() => {
        // Trigger fade-out animation
        setAnimationClass('fade-out-active');

        // Wait for the animation to complete before unmounting
        timer2 = setTimeout(() => {
          setShouldRender(false);
          setAnimationClass('');
        }, animationDuration * 1000); // Duration should match CSS transition duration
      }, extraDelay);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [show, shouldRender, extraDelay, animationDuration]);

  if (!shouldRender) return null;

  // Create a portal root if it doesn't exist
  const spinnerRoot =
    document.getElementById('spinner-root') || createSpinnerRoot();

  // Calculate the background color with opacity
  const backgroundColor = opaque
    ? hexToRGBA(overlayColor, 1)
    : hexToRGBA(overlayColor, overlayOpacity);

  // Build inline styles for the overlay and content
  const overlayStyle = {
    backgroundColor,
    transition: `opacity ${animationDuration}s ease-in-out`,
  };

  const contentStyle = {
    transition: `opacity ${animationDuration}s ease-in-out`,
  };

  // Determine overlay class based on animation state
  const overlayClassName = `global-spinner-overlay ${animationClass}`;
  const contentClassName = `global-spinner-content ${animationClass}`;

  // Logo transition classes
  const logoClassName = isLogoTransitioning
    ? 'global-spinner-logo logo-fade-out'
    : 'global-spinner-logo logo-fade-in';

  return ReactDOM.createPortal(
    <div
      className={overlayClassName}
      role="alert"
      aria-busy="true"
      style={overlayStyle}
    >
      <div className={contentClassName} style={contentStyle}>
        {logo && (
          <img
            src={logo}
            alt="Logo"
            className={logoClassName}
            style={{ animationDuration: `${animationDuration}s` }}
          />
        )}
        <Spinner size={size} color={color} />
      </div>
    </div>,
    spinnerRoot
  );
};

// Function to create a spinner root if it doesn't exist
const createSpinnerRoot = () => {
  const spinnerRoot = document.createElement('div');
  spinnerRoot.setAttribute('id', 'spinner-root');
  document.body.appendChild(spinnerRoot);
  return spinnerRoot;
};

GlobalSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  extraDelay: PropTypes.number,
  opaque: PropTypes.bool,
  overlayColor: PropTypes.string, // Hexadecimal color for overlay background
  overlayOpacity: PropTypes.number, // Opacity value between 0 and 1
  animationDuration: PropTypes.number, // Duration in seconds
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isLogoTransitioning: PropTypes.bool,
};

GlobalSpinner.defaultProps = {
  size: '100px',
  color: null,
  extraDelay: 0,
  opaque: false,
  overlayColor: '#000000',
  overlayOpacity: 0.5,
  animationDuration: 0.3,
  logo: null,
  isLogoTransitioning: false,
};

export default GlobalSpinner;
