// ImageUpload.jsx
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import './ImageUpload.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ImageUpload = ({ setImage }) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setImage(file);
      }
    },
    [setImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [], // Correctly formatted accept
    },
    maxSize: 3 * 1024 * 1024, // 3MB in bytes, adjust as needed
    onDrop,
  });

  return (
    <div className="form-group image-upload-group">
      <label htmlFor="imageUpload">{t('imageUpload.label')}:</label>
      <div
        {...getRootProps({
          className: `dropzone ${isDragActive ? 'is-drag-active' : ''}`,
        })}
        id="imageUpload"
      >
        <input {...getInputProps()} aria-label={t('imageUpload.ariaLabel')} />
        {isDragActive ? (
          <p>
            <FontAwesomeIcon icon={faCloudUploadAlt} /> {t('imageUpload.dropHere')}
          </p>
        ) : (
          <p>
            <FontAwesomeIcon icon={faCloudUploadAlt} /> {t('imageUpload.dragOrClick')}
          </p>
        )}
      </div>
    </div>
  );
};

ImageUpload.propTypes = {
  setImage: PropTypes.func.isRequired,
};

export default ImageUpload;
