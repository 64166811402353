// src/components/FormBuilder/ShareTab.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeCanvas } from 'qrcode.react'; // Import QRCodeCanvas
import ActionButton from '../Common/ActionButton';
import './ShareTab.css';
import { useTranslation } from 'react-i18next';

const ShareTab = ({ formId, formName, groupId, showToast }) => {
  const { t } = useTranslation();

  const formUrl = `${window.location.origin}/${groupId}/forms/${formId}`;

  const downloadQRCode = () => {
    const canvas = document.getElementById('form-qr-code');
    if (canvas) {
      // Get the data URL of the QR code image
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

      // Create a download link and trigger it
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${formName || 'form'}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      showToast(t('shareTab.qrCodeDownloaded'), 'success');
    } else {
      showToast(t('shareTab.qrCodeDownloadFailed'), 'error');
    }
  };

  return (
    <div className="share-tab-container">
      <h2 className="share-tab-title">{t('shareTab.title')}</h2>
      <p className="share-tab-description">
        {t('shareTab.description')}
      </p>

      <div className="share-methods">
        {/* Share via URL */}
        <div className="share-method-card">
          <h3 className="share-method-title">{t('shareTab.shareViaLink')}</h3>
          <div className="share-url-container">
            <input
              type="text"
              value={formUrl}
              readOnly
              className="share-url-input"
              aria-label={t('shareTab.formUrl')}
            />
          </div>
          <ActionButton
            onClick={() => {
              navigator.clipboard.writeText(formUrl);
              showToast(t('shareTab.urlCopied'), 'success');
            }}
            label={t('shareTab.copyUrl')}
            icon="faCopy"
            text={t('shareTab.copyUrl')}
            isMobile={false}
            colorType="secondary"
            spacing="10px" // Adds margin around the button
            ariaLabel={t('shareTab.copyUrlAriaLabel')}
          />
        </div>

        {/* Share via QR Code */}
        <div className="share-method-card">
          <h3 className="share-method-title">{t('shareTab.shareViaQRCode')}</h3>
          <div className="qr-code-container">
            <QRCodeCanvas
              id="form-qr-code"
              value={formUrl}
              size={1000} // Larger size for high-resolution download
              style={{ width: '170px', height: '170px' }} // Display size
              level={'H'}
              includeMargin={true}
              bgColor="#ffffff"
              fgColor="#000000"
              imageSettings={{
                src: '/logo192.png',
                height: 180, // Adjusted proportionally for larger size
                width: 180,
                excavate: true,
              }}
            />
          </div>
          <ActionButton
            onClick={downloadQRCode}
            text={t('shareTab.download')}
            label={t('shareTab.downloadQRCode')}
            icon="faDownload" // Changed icon to download icon
            isMobile={false}
            colorType="primary"
            spacing="10px" // Adds margin around the button
            ariaLabel={t('shareTab.downloadQRCodeAriaLabel')}
          />
        </div>
      </div>
    </div>
  );
};

ShareTab.propTypes = {
  formId: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  showToast: PropTypes.func.isRequired,
};

export default ShareTab;
