// ErrorMessage.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './ErrorMessage.css';
import { useTranslation } from 'react-i18next';

const ErrorMessage = ({ error }) => {
  const { t } = useTranslation();

  return (
    <div className="error-message" role="alert">
      {t(error)}
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ErrorMessage;
