// src/components/LogoUploadGroup.jsx

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import './LogoUploadGroup.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import Spinner from '../Common/Spinner';
import { useDropzone } from 'react-dropzone';

const LogoUploadGroup = ({ setLogoFile, logoPreviewUrl, setLogoPreviewUrl }) => {
  const { t } = useTranslation();

  const { language, updateLanguage, loading: languageLoading } = useLanguageSettings();
  const { appSettings, loading: colorLoading } = useColorSettings();

  // Handle image upload using react-dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) return; // No files selected
      const file = acceptedFiles[0];
      setLogoFile(file);
      setLogoPreviewUrl(URL.createObjectURL(file)); // Show preview
    },
    [setLogoFile, setLogoPreviewUrl]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    maxSize: 5 * 1024 * 1024, // 5MB limit
    onDrop,
  });

  // Conditional rendering after all hooks are called
  if (colorLoading || languageLoading) {
    return (
      <div className="form-group logo-upload-group">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="form-group logo-upload-group">
      <label htmlFor="logoUpload">{t('logoUploadGroup.label')}</label>
      <div
        {...getRootProps({ className: `dropzone ${isDragActive ? 'is-drag-active' : ''}` })}
        id="logoUpload"
      >
        <input {...getInputProps()} aria-label={t('logoUploadGroup.uploadAriaLabel')} />
        <p>
          <FontAwesomeIcon icon={faCloudUploadAlt} />{' '}
          {isDragActive ? t('logoUploadGroup.dropImage') : t('logoUploadGroup.dragDrop')}
        </p>
      </div>
      {/* Image Preview */}
      {logoPreviewUrl && (
        <div className="logo-preview">
          <img src={logoPreviewUrl} alt={t('logoUploadGroup.logoPreviewAlt')} />
        </div>
      )}
    </div>
  );
};

export default LogoUploadGroup;
