// src/components/FormBuilder/Fields/RangeSliderField.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './RangeSliderField.css';

const RangeSliderField = ({
  label,
  name,
  value,
  onChange,
  min,
  max,
  step,
  showValue,
  error, // Ensure error prop is received
}) => {
  // Handler to extract and pass the numerical value
  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    onChange(newValue);
  };

  return (
    <div className={`form-group range-slider-group ${name}`}>
      <label htmlFor={name}>{label}</label>
      <div className="slider-container">
        <input
          type="range"
          id={name}
          name={name}
          value={value}
          onChange={handleSliderChange} // Use the new handler
          min={min}
          max={max}
          step={step}
          className={`range-slider ${error ? 'input-error' : ''}`} // Apply error class if any
        />
        {showValue && <span className="slider-value">{value}</span>}
      </div>
      {error && <span id={`${name}-error`} className="error-message">{error}</span>}
    </div>
  );
};

RangeSliderField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  showValue: PropTypes.bool,
  error: PropTypes.string, // Optional error message
};

RangeSliderField.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  showValue: true,
  error: '', // Default no error
};

export default RangeSliderField;
