// src/components/Settings.js

import React, { useState, useEffect, useContext } from 'react';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import './Settings.css';
import ToastContainer from '../Common/ToastContainer';

import LogoUploadGroup from './LogoUploadGroup';
import ColorPickerGroup from './ColorPickerGroup';
import ProgressBar from './ProgressBar';
import ErrorMessage from './ErrorMessage';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../DataContext'; // Import DataContext

import ActionButton from '../Common/ActionButton'; // Import ActionButton

const Settings = () => {
  const { t } = useTranslation();
  const { appSettings } = useColorSettings();
  const { language } = useLanguageSettings();

  // Import updateSettings and uploadLogo from DataContext
  const { updateSettings, organizationData, uploadLogo } = useContext(DataContext);

  const [logoFile, setLogoFile] = useState(null);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(appSettings.profilePicture || '');
  const [primaryColor, setPrimaryColor] = useState(appSettings.color || '#bb8300');
  const [tempLanguage, setTempLanguage] = useState(language || 'en');
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState('');
  // Toasts state
  const [toasts, setToasts] = useState([]);

  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  useEffect(() => {
    if (appSettings) {
      if (appSettings.profilePicture !== undefined) {
        setLogoPreviewUrl(appSettings.profilePicture || '');
      }
      if (appSettings.color !== undefined) {
        setPrimaryColor(appSettings.color || '#bb8300');
      }
    }
  }, [appSettings]);

  useEffect(() => {
    return () => {
      if (logoPreviewUrl && typeof logoPreviewUrl === 'string') {
        URL.revokeObjectURL(logoPreviewUrl);
      }
    };
  }, [logoPreviewUrl]);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setTempLanguage(selectedLanguage);
  };

  // src/components/Settings.js

const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  setError('');
  setUploadProgress(0);

  let uploadedProfilePicture = appSettings.profilePicture || '';

  try {
    if (!organizationData.groupId) {
      throw new Error('Missing groupId in organizationData.');
    }

    if (logoFile) {
      // Use uploadLogo from DataContext
      uploadedProfilePicture = await uploadLogo(logoFile, (progress) => {
        setUploadProgress(progress);
      });
    }

    // Prepare new settings object
    const newSettings = {
      profilePicture: uploadedProfilePicture,
      color: primaryColor,
    };

    // Include language change if it has changed
    if (tempLanguage !== language) {
      newSettings.language = tempLanguage;
    }

    // Use updateSettings from context
    await updateSettings(newSettings);

    // Optionally, update the language in the i18n instance immediately
    /*if (tempLanguage !== language) {
      i18n.changeLanguage(tempLanguage);
    }*/

    showToastMessage(t('settingsUpdatedSuccess', 'Settings updated successfully'), 'success');
    setLogoFile(null);
    setUploadProgress(0);
  } catch (error) {
    console.error('Error updating settings:', error);
    setError(t('settingsUpdatedError', 'Error updating settings.'));
    showToastMessage(t('settingsUpdatedError', 'Error updating settings'), 'error');
  } finally {
    setLoading(false);
  }
};

  return (
    <div className="settings-container">
      <h2>{t('applicationSettingsTitle', 'Application Settings')}</h2>
      <form onSubmit={handleSubmit} className="settings-form">
        <div className="settings-form-row">
          <ColorPickerGroup primaryColor={primaryColor} setPrimaryColor={setPrimaryColor} />
          <LanguageSelector language={tempLanguage} handleLanguageChange={handleLanguageChange} />
        </div>
        <div className="settings-form-row">
          <LogoUploadGroup
            setLogoFile={setLogoFile}
            logoPreviewUrl={logoPreviewUrl}
            setLogoPreviewUrl={setLogoPreviewUrl}
          />
        </div>

        {uploadProgress > 0 && uploadProgress < 100 && (
          <ProgressBar uploadProgress={uploadProgress} />
        )}

        {error && <ErrorMessage error={error} />}

          <ActionButton
            type="submit"
            text={loading ? t('saving', 'Saving...') : t('saveSettings', 'Save Settings')}
            icon={loading ? 'faSpinner' : 'faSave'}
            disabled={loading}
            size="large"
            fullWidth={true}
            colorType="primary"
          />

      </form>
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default Settings;
