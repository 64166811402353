// src/components/FormBuilder/Fields/DatePicker.jsx

import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';

const CustomDatePicker = ({ label, name, value, onChange, placeholder }) => {
  // Convert the value to a Date object if it's a string, else keep it as is
  const selectedDate = value && typeof value === 'string' ? new Date(value) : value;

  // Handler to pass the selected date back to the parent
  const handleDateChange = (date) => {
    onChange(date);
  };

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <ReactDatePicker
        id={name}
        selected={selectedDate}
        onChange={handleDateChange}
        placeholderText={placeholder}
        className="form-control"
        dateFormat="MM/dd/yyyy"
        isClearable
      />
    </div>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string, // Allow string to handle initial empty state or string values
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  value: null,
  placeholder: '',
};

export default CustomDatePicker;
