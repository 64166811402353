// src/components/Common/ColorSettingsContext.js

import React, { createContext, useContext } from 'react';

// Create the ColorSettingsContext
const ColorSettingsContext = createContext();

// Custom hook to use the ColorSettingsContext
export const useColorSettings = () => {
  const context = useContext(ColorSettingsContext);
  if (!context) {
    throw new Error('useColorSettings must be used within a ColorSettingsProvider');
  }
  return context;
};

export default ColorSettingsContext;
