// src/components/UserMenu.jsx

import React, { useRef, useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faKey, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './UserMenu.css';
import ResetPasswordConfirmationModal from '../Common/ResetPasswordConfirmationModal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../../DataContext';
import UserIcon from '../../assets/UserIcon.png';

const UserMenu = ({ onLogout, onResetPassword, isTransitioning }) => {
  const { t } = useTranslation();
  const { userData } = useContext(DataContext);
  const [showMenu, setShowMenu] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [userToReset, setUserToReset] = useState(null);
  const menuRef = useRef(null);
  const defaultUserPhotoURL = UserIcon;

  const toggleMenu = () => setShowMenu(!showMenu);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  const handleResetPasswordClick = () => {
    setUserToReset(userData);
    setShowResetModal(true);
    setShowMenu(false);
  };

  const handleConfirmResetPassword = () => {
    if (userToReset) {
      onResetPassword(userToReset);
      setShowResetModal(false);
      setUserToReset(null);
    }
  };

  const handleCancelResetPassword = () => {
    setShowResetModal(false);
    setUserToReset(null);
  };

  if (!userData) {
    return null;
  }

  return (
    <div className="user-menu" ref={menuRef}>
      <button
        className="user-menu-button"
        onClick={toggleMenu}
        disabled={isTransitioning}
        aria-label={t('userMenu.ariaLabel')}
      >
        <div className="user-info">
          {userData.photoURL ? (
            <img src={userData.photoURL} alt={t('userMenu.altUser')} className="user-photo" />
          ) : (
            <img src={defaultUserPhotoURL} alt={t('userMenu.altUser')} className="user-photo" />
          )}
          <span className="user-full-name">
            {userData.fullName || t('userMenu.defaultUserName')}
          </span>
        </div>
        <FontAwesomeIcon icon={faCaretDown} className={`caret-icon ${showMenu ? 'open' : ''}`} />
      </button>
      <div className={`user-menu-dropdown ${showMenu ? 'slide-down' : 'slide-up'}`}>
        <button className="dropdown-button" onClick={handleResetPasswordClick}>
          <FontAwesomeIcon icon={faKey} className="menu-icon" /> {t('userMenu.resetPassword')}
        </button>
        <button className="dropdown-button" onClick={onLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="menu-icon" /> {t('userMenu.logout')}
        </button>
      </div>

      {showResetModal && (
        <ResetPasswordConfirmationModal
          show={showResetModal}
          onConfirm={handleConfirmResetPassword}
          onCancel={handleCancelResetPassword}
          record={userToReset}
          isMobile={false}
        />
      )}
    </div>
  );
};

UserMenu.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
  isTransitioning: PropTypes.bool.isRequired,
};

export default UserMenu;
