// src/components/FormBuilder/Tabs.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './Tabs.css';

const Tabs = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  const tabs = [
    { name: 'general', label: t('tabs.general') },
    { name: 'builder', label: t('tabs.builder') },
    { name: 'fill', label: t('tabs.preview') },
    { name: 'responses', label: t('tabs.responses') },
    { name: 'share', label: t('tabs.share') },
  ];

  return (
    <div className="tabs">
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={`tab ${activeTab === tab.name ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.name)}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setActiveTab(tab.name);
            }
          }}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default Tabs;
