// src/components/UserForm.jsx

import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from '../Common/ActionButton';
import './UserForm.css';
import { useTranslation } from 'react-i18next';
import { useLanguageSettings } from '../Common/LanguageSettingsContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import Spinner from '../Common/Spinner';

// Import custom field components
import TextField from '../FormBuilder/Fields/TextField';
import EmailField from '../FormBuilder/Fields/EmailField';
import SelectField from '../FormBuilder/Fields/SelectField';

const UserForm = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  roles,
  role,
  setRole,
  isMobile,
  onSubmit,
  isEditing,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  // Consume LanguageSettings and ColorSettings contexts
  const { loading: languageLoading } = useLanguageSettings();
  const { loading: colorLoading } = useColorSettings();

  // Combine loading states
  if (colorLoading || languageLoading) {
    return (
      <div className="users-modal-form">
        <Spinner />
      </div>
    );
  }

  return (
    <form className="users-modal-form" onSubmit={onSubmit}>
      <div className="users-input-group">
        <TextField
          label={t('userForm.firstNameLabel')}
          name="firstName"
          value={firstName}
          onChange={(value) => setFirstName(value)}
          required
          placeholder={t('userForm.firstNamePlaceholder')}
          size="full"
        />
      </div>
      <div className="users-input-group">
        <TextField
          label={t('userForm.lastNameLabel')}
          name="lastName"
          value={lastName}
          onChange={(value) => setLastName(value)}
          required
          placeholder={t('userForm.lastNamePlaceholder')}
          size="full"
        />
      </div>
      <div className="users-input-group">
        <EmailField
          label={t('userForm.emailLabel')}
          name="email"
          value={email}
          onChange={(value) => setEmail(value)}
          required
          disabled={isEditing}
          style={isEditing ? { backgroundColor: '#e9ecef' } : {}}
          placeholder={t('userForm.emailPlaceholder')}
          size="full"
        />
      </div>
      <div className="users-input-group">
        <SelectField
          label={t('userForm.roleLabel')}
          name="role"
          value={role}
          onChange={(value) => setRole(value)}
          required
          options={roles.map((role) => ({
            label: role.name,
            value: role.id,
          }))}
          placeholder={t('userForm.rolePlaceholder')}
          size="full"
        />
      </div>
      {/* Container to center the Save/Update button */}
      <div className="users-submit-button-container">
        <ActionButton
          type="submit"
          label={
            isSubmitting
              ? t('userForm.submitting')
              : isEditing
              ? t('userForm.updateButton')
              : t('userForm.saveButton')
          }
          text={
            isSubmitting
              ? t('userForm.submitting')
              : isEditing
              ? t('userForm.updateButton')
              : t('userForm.saveButton')
          }
          icon={isSubmitting ? 'faSpinner' : 'faSave'}
          spin={isSubmitting}
          disabled={isSubmitting}
          isMobile={isMobile}
          colorType="primary"
          ariaLabel={
            isEditing
              ? t('userForm.updateButtonAria')
              : t('userForm.saveButtonAria')
          }
        />
      </div>
    </form>
  );
};

UserForm.propTypes = {
  firstName: PropTypes.string.isRequired,
  setFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.string.isRequired,
  setLastName: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  role: PropTypes.string.isRequired,
  setRole: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default UserForm;
