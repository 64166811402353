// src/pages/MembersPage.jsx

import React, { useContext, useState, useEffect } from 'react';
import MembersTable from './MembersTable';
import { DataContext } from '../../DataContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../Common/Spinner';
import FillOutForm from '../FormBuilder/FillOutForm';
import MessageSender from '../Communications/MessageSender';
import CustomModal from '../Common/CustomModal';
import DeleteConfirmationModal from '../Common/DeleteConfirmationModal';
import ToastContainer from '../Common/ToastContainer';
import ActionButton from '../Common/ActionButton';
import ConfigureFieldsModal from '../Common/ConfigureFieldsModal'; // New import
import './MembersPage.css';

import {
  generateCSV,
  downloadCSV,
} from '../FormBuilder/CSVUtility';

const MembersPage = () => {
  const { t } = useTranslation();

  // Consume DataContext
  const {
    useResponses,
    userRole,
    deleteResponse,
    useFormFields,
    getTableFields,         // New function to fetch tableFields
    saveTableFields,        // New function to save tableFields
  } = useContext(DataContext);

  // Use the useResponses hook to fetch members data for "membersForm"
  const {
    data: responses = [],
    isLoading,
    error,
    refetch, // Function to refetch data
  } = useResponses('membersForm');

  const {
    data: formFieldsData = {},
    isLoading: formFieldsLoading,
    refetch: refetchFormFields,
  } = useFormFields('membersForm');

  // Fetch tableFields configuration
  const [tableFields, setTableFields] = useState([]);
  const [loadingTableFields, setLoadingTableFields] = useState(true);

  useEffect(() => {
    const fetchTableFields = async () => {
      try {
        const fields = await getTableFields('membersForm');
        if (fields && fields.length > 0) {
          setTableFields(fields);
        } else {
          // If no configuration exists, set default fields
          setTableFields([
            {
              id: 'fullName',
              label: 'Full Name',
              accessor: ['firstName', 'lastName'],
              sortable: true,
              hideInMobile: false,
              type: 'Text',
            },
            {
              id: 'location',
              label: 'Location',
              accessor: ['city', 'state'],
              sortable: true,
              hideInMobile: true,
              type: 'Text',
            },
            {
              id: 'dateOfBirth',
              label: 'Date of Birth',
              accessor: 'dateOfBirth',
              sortable: true,
              hideInMobile: false,
              type: 'DatePicker',
            },
            {
              id: 'phoneNumber',
              label: 'Phone Number',
              accessor: 'phoneNumber',
              sortable: true,
              hideInMobile: false,
              type: 'Telephone',
            },
          ]);
        }
      } catch (error) {
        console.error('Error fetching table fields:', error);
      } finally {
        setLoadingTableFields(false);
      }
    };

    fetchTableFields();
  }, [getTableFields]);

  // Function to handle exporting responses to CSV
  const handleExportCSV = () => {
    if (responses.length === 0) {
      showToast(t('responsesTab.noResponsesToExport'), 'error');
      return;
    }

    try {
      const csvContent = generateCSV(responses);
      downloadCSV(csvContent, 'members.csv');
      showToast(t('responsesTab.csvExportSuccess'), 'success');
    } catch (error) {
      showToast(t('responsesTab.csvExportError'), 'error');
      console.error('Error exporting CSV:', error);
    }
  };

  // Map responses to members
  const members = responses.map((response) => {
    const member = {};
    response.fields.forEach((field) => {
      member[field.id] = field.value;
    });
    member.id = response.id;
    member.fields = response.fields;
    member.submittedAt = response.submittedAt;
    member.createdAt = response.createdAt;
    member.updatedAt = response.updatedAt;
    member.groups = response.groups ? response.groups.map((groupRef) => groupRef.id) : [];
    return member;
  });

  // Local state for modals and toast notifications
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleteModalMessage, setDeleteModalMessage] = useState('');
  const [toasts, setToasts] = useState([]);
  const [showConfigModal, setShowConfigModal] = useState(false); // New state for config modal

  // Toast Management
  const showToast = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
    setTimeout(() => removeToast(id), duration);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Action Handlers
  const handleAdd = () => {
    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="membersForm"
        showToast={showToast}
        initialData={null}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch(); // Refresh the members list after adding
        }}
      />
    );
    setModalTitle(t("membersTable.addMember","Add Member"));
    setShowModal(true);
  };

  const handleEdit = (record) => {
    setModalContent(
      <FillOutForm
        fields={formFieldsData.fields}
        formId="membersForm"
        showToast={showToast}
        initialData={record} // Pass initial data for editing
        responseId={record.id}
        onCancel={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
          refetch(); // Refresh the members list after editing
        }}
      />
    );
    setModalTitle(t('membersTable.editMember',{name:`${record.firstName} ${record.lastName}`}));
    setShowModal(true);
  };

  const handleSendMessage = (record) => {
    setModalContent(
      <MessageSender
        single={true}
        recipient={record.phoneNumber}
        showToast={showToast}
      />
    );
    setModalTitle(
      t('membersTable.sendMessageTo', {
        name: `${record.firstName} ${record.lastName}`,
      })
    );
    setShowModal(true);
  };

  const handleCall = (record) => {
    window.location.href = `tel:${record.phoneNumber}`;
  };

  const handleSendEmail = (record) => {
    window.location.href = `mailto:${record.email}`;
  };

  const handleDelete = (record) => {
    setRecordToDelete(record);
    setDeleteModalMessage(
      t('deleteConfirmation.message', {
        item: `${record.firstName} ${record.lastName}`,
      })
    );
    setShowDeleteModal(true);
  };
  const handleConfirmDelete = async () => {
    if (!recordToDelete) return;
    try {
      await deleteResponse('membersForm', recordToDelete.id); // Delete member via DataContext
      showToast(t('memberTable.recordDeleted',"Member has been deleted successfully"), 'success');
      setShowDeleteModal(false);
      setRecordToDelete(null);
      refetch(); // Refresh the members list
    } catch (error) {
      console.error('Error deleting member:', error);
      showToast(t('memberTable.deleteError',"Error deleting member"), 'error');
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setRecordToDelete(null);
  };

  // Define the actions you want to perform on each row
  const actions = [
    {
      label: t('edit',"Edit"),
      icon: 'faEdit',
      callback: handleEdit,
      variant: 'secondary',
      condition: () => true,
    },
    {
      label: t('sendMessage',"Send Message"),
      icon: 'faPaperPlane',
      callback: handleSendMessage,
      variant: 'primary',
      condition: (record) => !!record.phoneNumber,
    },
    {
      label: t('call',"Call"),
      icon: 'faPhone',
      callback: handleCall,
      variant: 'secondary',
      condition: (record) => !!record.phoneNumber,
      as: 'a',
      hrefAccessor: (record) => `tel:${record.phoneNumber}`,
    },
    {
      label: t('sendEmail',"Send Email"),
      icon: 'faEnvelope',
      callback: handleSendEmail,
      variant: 'info',
      disabledAccessor: (record) => !record.email,
    },
    {
      label: t('delete',"Delete"),
      icon: 'faTrash',
      callback: handleDelete,
      variant: 'danger',
      condition: () => userRole.roleName === 'Admin',
    },
  ];

  // Handle opening the configuration modal
  const handleOpenConfigModal = () => {
    setShowConfigModal(true);
  };

  // Handle saving the configuration
  const handleSaveConfig = async (configuredFields) => {
    try {
      await saveTableFields('membersForm', configuredFields);
      setTableFields(configuredFields);
      setShowConfigModal(false);
      showToast(t("tableConfigurationSuccessful","Table configuration saved successfully"), 'success');
    } catch (error) {
      console.error('Error saving table configuration:', error);
      showToast(t("tableConfigurationFailed","Failed to save table configuration"), 'error');
    }
  };

  // Loading and Error States
  if (isLoading || loadingTableFields || formFieldsLoading) {
    return (
      <div className="loading-container">
        <Spinner size="100px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        {t('membersPage.errorFetchingMembers')}
      </div>
    );
  }

  return (
    <div className="members-page-container">
      {/* Header Container */}
      <div className="header-container">
        <h2>{t('membersPage.title',"Members")}</h2>

        {/* Configuration Button */}
        <div className="config-button">
          <ActionButton
            onClick={handleOpenConfigModal}
            icon="faCog"
            variant="secondary"
            label={t("configureTableField","Configure Table Fields")}
          />
        </div>
      </div>

      <MembersTable
        formId="membersForm"
        fields={tableFields}
        actions={actions}
        members={members}
        userRole={userRole}
        addMember={handleAdd}
        exportMember={handleExportCSV}
      />

      {/* Custom Modal for Editing or Messaging */}
      <CustomModal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setModalContent(null);
          setModalTitle('');
        }}
        title={modalTitle}
      >
        {modalContent}
      </CustomModal>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          show={showDeleteModal}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          title={t('deleteConfirmation.title')}
          message={deleteModalMessage}
        />
      )}

      {/* Configuration Modal */}
      {showConfigModal && (
        <ConfigureFieldsModal
          show={showConfigModal}
          onClose={() => setShowConfigModal(false)}
          onSave={handleSaveConfig}
          availableFields={formFieldsData.fields}
          currentConfig={tableFields}
        />
      )}

      {/* Toast Notifications */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </div>
  );
};

export default MembersPage;
