// src/components/Common/Spinner.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';
import { useColorSettings } from './ColorSettingsContext'; // Import from the correct context
import { useTranslation } from 'react-i18next';

const Spinner = ({ size, color, fullScreen }) => {
  const { appSettings } = useColorSettings();
  const { t } = useTranslation();

  const spinnerColor = color || (appSettings && appSettings.color) || '#bb8300';

  const spinnerStyle = {
    width: size,
    height: size,
    borderTopColor: spinnerColor,
  };

  // Removed containerClass and any positioning
  // Since the spinner will be positioned by its parent container (e.g., GlobalSpinner)
  return (
    <div className="custom-spinner" style={spinnerStyle} aria-label={t('spinner.loading')}></div>
  );
};

Spinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  fullScreen: PropTypes.bool,
};

Spinner.defaultProps = {
  size: '50px',
  color: null,
  fullScreen: false,
};

export default Spinner;
