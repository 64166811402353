// src/components/FormBuilder/Fields/ColorPicker.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './ColorPicker.css'; // Ensure this CSS file matches ColorPickerGroup.css
import { useTranslation } from 'react-i18next';

const ColorPicker = ({
  label,
  value,
  onChange,
  required,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <div className="form-group color-picker-group">
      <label htmlFor={label}>
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <input
        type="color"
        id={label}
        name={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        aria-required={required}
      />
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};

ColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
};

ColorPicker.defaultProps = {
  required: false,
  error: '',
};

export default ColorPicker;
