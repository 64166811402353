// src/components/FormBuilder/Fields/TelephoneField.jsx

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './TelephoneField.css';

const TelephoneField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  onBlur,
  required,
  size,
  error, // Ensure error prop is received
}) => {
  const [localError, setLocalError] = useState('');

  // Validation and formatting function
  const validateAndFormatPhone = (phone) => {
    // Remove all non-digit and non-plus characters
    let cleanedPhone = phone.replace(/[^0-9+]/g, '');

    // Handle possible '+1' prefix
    if (cleanedPhone.startsWith('+1')) {
      cleanedPhone = cleanedPhone.slice(1);
    }

    // After removing prefixes, the number should be exactly 11 digits
    if (cleanedPhone.length == 10) {
      return `1${cleanedPhone}`;
    }
    else if(cleanedPhone.length !== 11)
    {
      return null;
    }

    // Format to E.164 standard
    return `${cleanedPhone}`;
  };

  // Handle onBlur event to validate and format phone number
  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    const formattedPhone = validateAndFormatPhone(value);
    if (formattedPhone) {
      setLocalError('');
      onChange(formattedPhone);
    } else {
      setLocalError('Please enter a valid telephone number (e.g., 123-456-7890, 1234567890, or +11234567890).');
    }
  };

  // Handle onChange event
  const handleChange = (e) => {
    setLocalError(''); // Clear local error on change
    onChange(e.target.value); // Pass only the value, not the event
  };

  return (
    <div className={`form-group`}>
      <label htmlFor={name}>
        {label} {required && <span className="required-asterisk">*</span>}
      </label>
      <input
        type="tel"
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        className={`form-control ${localError || error ? 'input-error' : ''}`}
        title="Enter a valid telephone number (e.g., 123-456-7890, 1234567890, or +11234567890)"
        required={required}
        aria-invalid={localError || error ? 'true' : 'false'}
        aria-describedby={localError || error ? `${name}-error` : undefined}
      />
      {(localError || error) && (
        <span id={`${name}-error`} className="error-message">
          {localError || error}
        </span>
      )}
    </div>
  );
};

TelephoneField.propTypes = {
  label: PropTypes.string.isRequired, // Label for the input field
  name: PropTypes.string.isRequired, // Name attribute for the input
  value: PropTypes.string, // Current value of the input
  onChange: PropTypes.func.isRequired, // Handler for change events
  placeholder: PropTypes.string, // Placeholder text
  onBlur: PropTypes.func, // Handler for blur events
  required: PropTypes.bool, // Indicates if the field is required
  size: PropTypes.oneOf(['full', 'half']), // Determines the width of the field
  error: PropTypes.string, // Error message passed from parent
};

TelephoneField.defaultProps = {
  value: '',
  placeholder: '123-456-7890',
  onBlur: null, // Make onBlur optional
  required: false,
  size: 'full', // Default size is full width
  error: '', // Default no error
};

export default TelephoneField;
