// src/components/FormBuilder/Fields/Section.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './Section.css';

const Section = ({ label }) => {
  return (
    <div className="form-section">
      <h3 className="form-section-title">{label}</h3>
    </div>
  );
};

Section.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Section;
