// src/components/ProtectedRoute.jsx

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { DataContext } from '../../DataContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser, currentUserLoading } = useContext(DataContext);

  return (
    <>
      {!currentUserLoading && !currentUser && <Navigate to="/" replace />}
      {!currentUserLoading && currentUser && children}
    </>
  );
};

export default ProtectedRoute;
