// src/components/FormBuilder/PublicForm.jsx

import React, { useState, useEffect, useContext } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FillOutForm from './FillOutForm';
import LoadingSpinner from '../Common/GlobalSpinner';
import './PublicForm.css';
import { DataContext } from '../../DataContext';


const PublicForm = () => {
  const { t } = useTranslation();
  const { groupId, formId } = useParams();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isClosed, setIsClosed] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { fetchFormDetails, appSettings } = useContext(DataContext);

  const showToast = (message, type) => {
    console.log(`${type.toUpperCase()}: ${message}`);
  };

  const primaryColor = appSettings?.color || '#ffffff';
  const profilePicture = appSettings?.profilePicture || '';

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const formData = await fetchFormDetails(formId);
        if (formData) {
          if (formData.groupId === groupId) {
            setForm({
              id: formId,
              ...formData,
            });
            setIsClosed(formData.isClosed || false);
          } else {
            console.error('Form does not belong to this group.');
            setNotFound(true);
            showToast(t('publicForm.formNotFound'), 'error');
          }
        } else {
          console.error('Form does not exist.');
          setNotFound(true);
          showToast(t('publicForm.formNotFound'), 'error');
        }
      } catch (error) {
        console.error('Error fetching form:', error);
        showToast(t('publicForm.fetchError'), 'error');
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };

    fetchForm();
  }, [formId, groupId, fetchFormDetails, t]);

  const handleFormSubmit = () => {
    setIsSubmitted(true);
  };

  const handleFillAgain = () => {
    setIsSubmitted(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (notFound) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <div className="public-form-background">
      <div
        className="public-form-container"
        style={{ backgroundColor: primaryColor }}
      >
        {profilePicture && (
          <div className="logo-container">
            <img src={profilePicture} alt={t('publicForm.logoAlt')} className="form-logo" />
          </div>
        )}
        <h2>{form.name}</h2>
        {isClosed ? (
          <div className="form-closed-message">
            <p>{t('publicForm.formClosedMessage')}</p>
          </div>
        ) : isSubmitted ? (
          <div className="form-submitted-message">
            <p>{t('publicForm.formSubmittedMessage')}</p>
            <button onClick={handleFillAgain} className="fill-again-button">
              {t('publicForm.fillAgainButtonText')}
            </button>
          </div>
        ) : (
          <FillOutForm
            fields={form.fields}
            formId={form.id}
            showToast={showToast}
            onFormSubmit={handleFormSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default PublicForm;
