// src/components/Common/CustomTooltip.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './CustomTooltip.css';

const CustomTooltip = ({ text, children }) => {
  return (
    <div className="tooltip-wrapper">
      {children}
      {text && <span className="custom-tooltip">{text}</span>}
    </div>
  );
};

CustomTooltip.propTypes = {
  text: PropTypes.string.isRequired, // Tooltip text
  children: PropTypes.node.isRequired, // The element the tooltip is attached to
};

export default CustomTooltip;
