// src/components/FormBuilder/Fields/NumberField.jsx

import React from 'react';
import PropTypes from 'prop-types';
import './NumberField.css';

const NumberField = ({ label, name, value, onChange, placeholder, min, max }) => {
  // Modify the onChange handler to pass only the value
  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type="number"
        id={name}
        name={name}
        value={value}
        onChange={handleInputChange} // Use the modified handler
        placeholder={placeholder}
        className="form-control"
        min={min}
        max={max}
      />
    </div>
  );
};

NumberField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

NumberField.defaultProps = {
  value: '',
  placeholder: '',
  min: undefined,
  max: undefined,
};

export default NumberField;
