// src/components/MainContent.jsx
import React from 'react';
import './MainContent.css';

const MainContent = ({ currentTab, isTransitioning, components }) => (
  <main
    className={`main-content ${isTransitioning ? 'fade-out' : 'fade-in'}`}
    aria-live="polite"
  >
    {components[currentTab]}
  </main>
);

export default MainContent;
