// src/components/ProgressBar.jsx

import React from 'react';
import { useTranslation } from 'react-i18next';
import './ProgressBar.css';

const ProgressBar = ({ uploadProgress }) => {
  const { t } = useTranslation();

  return (
    <div
      className="progress-bar"
      aria-label={t('progressBar.uploading', { progress: Math.round(uploadProgress) })}
    >
      <div
        className="progress-bar-inner"
        style={{ width: `${uploadProgress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
