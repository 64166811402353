// src/components/Main/Header.jsx

import React, { useContext, useState } from 'react';
import UserMenu from './UserMenu';
import Logo from './Logo';
import TabNavigation from './TabNavigation';
import './Header.css';
import CatholicoreLogoWhite from '../../assets/CatholicoreLogoWhite.png';
import { DataContext } from '../../DataContext';
import { useColorSettings } from '../Common/ColorSettingsContext';
import { useNavigate } from 'react-router-dom';
import ToastContainer from '../Common/ToastContainer';
import { useTranslation } from 'react-i18next';

const Header = ({
  currentTab,
  onTabChange,
  isTransitioning,
  showGroupsTab,
}) => {
  const { t } = useTranslation();
  const {
    userData,
    userRole,
    organizationData,
    currentUser,
    signOut,
    sendPasswordResetEmail,
  } = useContext(DataContext);
  const { appSettings } = useColorSettings();
  const navigate = useNavigate();

  const [toasts, setToasts] = useState([]);

  const showToastMessage = (message, type = 'success', duration = 5000) => {
    const id = Date.now();
    setToasts((prevToasts) => [...prevToasts, { id, message, type, duration }]);
  };

  const removeToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/' + organizationData.groupId);
    } catch (error) {
      console.error('Error signing out:', error);
      showToastMessage(t('header.logoutError'), 'error');
    }
  };

  const handleResetPassword = async () => {
    try {
      const email = userData.email || currentUser.email;
      if (email) {
        await sendPasswordResetEmail(email);
        showToastMessage(t('header.resetEmailSent'), 'success');
      } else {
        showToastMessage(t('header.noEmailAssociated'), 'error');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      showToastMessage(t('header.resetEmailError'), 'error');
    }
  };

  return (
    <header className="app-header">
      {/* White Logo Positioned at Top Left */}
      <div className="header-white-logo">
        <img src={CatholicoreLogoWhite} alt="Catholicore White Logo" />
      </div>
      <UserMenu
        userData={userData}
        onLogout={handleLogout}
        onResetPassword={handleResetPassword}
        isTransitioning={isTransitioning}
      />
      <Logo className="organization-logo" profilePicture={appSettings.profilePicture} />
      <TabNavigation
        currentTab={currentTab}
        onTabChange={onTabChange}
        userRole={userRole}
        isTransitioning={isTransitioning}
        showGroupsTab={showGroupsTab}
      />

      {/* Toasts */}
      <ToastContainer toasts={toasts} removeToast={removeToast} />
    </header>
  );
};

export default Header;
