// src/components/FormBuilder/FormTable.jsx

import React, { useEffect, useMemo, useState } from 'react';
import './FormTable.css';
import SearchBar from '../Common/SearchBar';
import PaginationControls from '../Common/PaginationControls';
import Table from '../Common/Table';
import ActionButton from '../Common/ActionButton';
import { useTranslation } from 'react-i18next';

const FormTable = ({
  forms,
  searchTerm,
  setSearchTerm,
  sortField,
  sortOrder,
  setSorting,
  onAddForm,
  onEditForm,
  onCopyForm,
  onDeleteForm,
  onOpenFormUrl,
  onViewResponses,
  userRole,
}) => {
  const { t } = useTranslation();

  // Initialize pagination state within FormTable
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // You can adjust the default value

  // Debounce search input to optimize performance
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }, 300); // 300ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filter forms based on debouncedSearchTerm
  const filteredForms = useMemo(() => {
    if (!debouncedSearchTerm) return forms || [];

    const lowercasedTerm = debouncedSearchTerm.toLowerCase();

    return (forms || []).filter((form) => {
      const formName = form.name ? form.name.toLowerCase() : '';
      return formName.includes(lowercasedTerm);
    });
  }, [debouncedSearchTerm, forms]);

  // Calculate total pages based on filteredForms
  const totalPages = useMemo(() => {
    return filteredForms.length > 0
      ? Math.ceil(filteredForms.length / itemsPerPage)
      : 0;
  }, [filteredForms.length, itemsPerPage]);

  // Ensure currentPage is within the new totalPages
  useEffect(() => {
    if (totalPages === 0 && currentPage !== 1) {
      setCurrentPage(1);
    } else if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  // Paginate the filtered forms
  const paginatedForms = useMemo(() => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    return filteredForms.slice(startIdx, endIdx);
  }, [filteredForms, currentPage, itemsPerPage]);

  // Determine if the device is mobile (you can adjust this logic as needed)
  const isMobile = window.innerWidth <= 768;

  // Define columns for the Form Table
  const columns = [
    {
      id: 'name',
      label: t('formTable.formName'),
      accessor: 'name',
      sortable: true,
    },
    {
      id: 'responseCount',
      label: t('formTable.responseCount'),
      accessor: 'responseCount',
      sortable: true,
    },
    {
      id: 'lastResponseDate',
      label: t('formTable.lastResponseSubmitted'),
      accessor: (row) => {
        if (!row.lastResponseDate) return t('table.noData');
        const date = row.lastResponseDate.toDate
          ? row.lastResponseDate.toDate()
          : new Date(row.lastResponseDate);
        return date.toLocaleString();
      },
      sortable: true,
    },
  ];

  // Define table actions
  const actions = [
    {
      label: t('formTable.edit'),
      icon: 'faPencil',
      callback: onEditForm,
      condition: () => true,
      colorType: 'secondary',
    },
    {
      label: t('formTable.copy'),
      icon: 'faCopy',
      callback: onCopyForm,
      condition: () => true,
      colorType: 'secondary',
    },
    {
      label: t('formTable.viewResponses'),
      icon: 'faEye',
      callback: onViewResponses,
      condition: () => true,
      colorType: 'secondary',
    },
    {
      label: t('formTable.openFormUrl'),
      icon: 'faExternalLinkAlt',
      callback: onOpenFormUrl,
      condition: () => true,
      colorType: 'secondary',
    },
    {
      label: t('formTable.delete'),
      icon: 'faTrash',
      callback: onDeleteForm,
      condition: () => true,
      colorType: 'danger',
    },
  ];

  const handleSort = (field) => {
    setSorting(field);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleItemsPerPageChange = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="form-table-header">
        <SearchBar
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          placeholder={t('formTable.searchPlaceholder', 'Search forms...')}
        />
        {/* Add Form Button */}
        <ActionButton
          onClick={onAddForm}
          label={t('formTable.addNewForm')}
          icon={'faPlus'}
          isMobile={false}
          colorType="primary"
          spacing="10px"
          ariaLabel={t('formTable.addNewFormAriaLabel')}
        />
      </div>

      <div className="record-count" aria-live="polite">
        {filteredForms.length === 1
          ? t('formTable.recordsFound', { count: filteredForms.length })
          : t('formTable.recordsFound_plural', { count: filteredForms.length })}
      </div>

      <Table
        columns={columns}
        data={paginatedForms}
        actions={actions}
        setSorting={handleSort}
        sortField={sortField}
        sortOrder={sortOrder}
        userRole={userRole}
        isMobile={isMobile}
        noRecordsMessage={t('formTable.noRecords')}
        actionsHeaderLabel={t('actions')}
      />

      {/* Pagination Controls */}
      {totalPages > 0 && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPrev={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          onNext={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          onPageClick={(page) => setCurrentPage(page)}
          itemsPerPage={itemsPerPage}
          itemsPerPageLabel={t('formTable.itemsPerPageLabel', 'Forms per page:')}
          noun={t('formTable.forms')}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default FormTable;
