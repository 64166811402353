// MessageInput.jsx
import React from 'react';
import PropTypes from 'prop-types';
import './MessageInput.css';
import { useTranslation } from 'react-i18next';

const MessageInput = ({ message, setMessage }) => {
  const { t } = useTranslation();

  return (
    <textarea
      value={message}
      className="textarea-message"
      onChange={(e) => setMessage(e.target.value)}
      placeholder={t('messageInput.placeholder')}
      required
      aria-label={t('messageInput.ariaLabel')}
    />
  );
};

MessageInput.propTypes = {
  message: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
};

export default MessageInput;
