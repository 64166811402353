// src/DataContext.js

import React, { createContext, useState, useEffect, useMemo } from 'react';
import { firestore, auth } from './firebase';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  orderBy,
  setDoc,
  query,
  where,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  writeBatch,
  getCountFromServer,
  Timestamp,
  limit,
} from 'firebase/firestore';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import CatholicoreLogo from './assets/CatholicoreLogo.png';
import UserIcon from './assets/UserIcon.png';

// Create the DataContext
export const DataContext = createContext();

// DataProvider component to wrap around parts of the app that need access to the context
export const DataProvider = ({ children, groupID }) => {
  const queryClient = useQueryClient();

  // User Data State
  const [userRole, setUserRole] = useState({
    roleName: 'User',
  });
  const [organizationData, setOrganizationData] = useState({
    organizationId: '', // Initially empty, will be set after fetching
    groupId: groupID,
  });
  const [userData, setUserData] = useState({
    fullName: '',
    photoURL: '',
  });

  // State to track the current authenticated user
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserLoading, setCurrentUserLoading] = useState(true);

  // Sorting State
  const [sortField, setSortField] = useState('fullName'); // Default sort field
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  // Users and Roles State
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [rolesMap, setRolesMap] = useState({});
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isUsersError, setIsUsersError] = useState(null);

  const [teams, setTeams] = useState([]);
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);
  const [isTeamsError, setIsTeamsError] = useState(null);

  // Utility function to extract the last segment of a Firestore document path.
  function getLastPathSegment(idObj) {
    if (
      idObj &&
      idObj._key &&
      idObj._key.path &&
      Array.isArray(idObj._key.path.segments)
    ) {
      const segments = idObj._key.path.segments;
      return segments[segments.length - 1];
    } else {
      throw new Error('Invalid ID object structure.');
    }
  }

  // Fetch Organization Data (organizationId) based on groupId.
  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (firestore && organizationData.groupId) {
        const groupDocRef = doc(firestore, 'groups', organizationData.groupId);
        const groupSnapshot = await getDoc(groupDocRef);
        if (groupSnapshot.exists()) {
          const groupData = groupSnapshot.data();
          setOrganizationData((prevData) => ({
            ...prevData,
            organizationId:
              typeof groupData.organizationId === 'object' && groupData.organizationId !== null
                ? getLastPathSegment(groupData.organizationId)
                : groupData.organizationId || prevData.groupId,
          }));
        } else {
          console.error('Group document does not exist.');
        }
      }
    };

    fetchOrganizationData();
  }, [firestore, organizationData.groupId]);

  // Set up authentication state listener.
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        // Invalidate queries to fetch fresh data that require authentication
        queryClient.invalidateQueries(['members', 'formGroups']);
      } else {
        // Remove queries that require authentication
        queryClient.removeQueries(['members', 'formGroups']);
        // Do not clear appSettings so that unauthenticated users can access it
      }
    });

    return () => unsubscribe();
  }, [queryClient]);

  // Fetch User Role and Data based on `currentUser` and `organizationData.groupId`.
  useEffect(() => {
    const fetchUserRoleAndData = async () => {
      if (currentUser && firestore && organizationData.groupId) {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDocRef);
        if (userSnapshot.exists()) {
          const userDataFirestore = userSnapshot.data();

          // Get the user's roles
          const userRoles = userDataFirestore.roles || [];

          // Find the role entry where groupId matches organizationData.groupId
          const userRoleEntry = userRoles.find((roleEntry) => {
            const groupIdRef = roleEntry.groupId;
            const groupId = groupIdRef && groupIdRef.id;
            return groupId === organizationData.groupId;
          });

          if (userRoleEntry && userRoleEntry.role) {
            const roleSnapshot = await getDoc(userRoleEntry.role);
            if (roleSnapshot.exists()) {
              const roleData = roleSnapshot.data();
              setUserRole({
                roleName: roleData.name,
              });
            } else {
              setUserRole({
                roleName: 'User',
              });
            }
          } else {
            setUserRole({
              roleName: 'User',
            });
          }

          setUserData({
            fullName:
              (userDataFirestore.firstName || '') +
              ' ' +
              (userDataFirestore.lastName || '') || currentUser.email,
            photoURL: userDataFirestore.photoURL || '',
          });
        } else {
          setUserData({
            fullName: currentUser.email,
            photoURL: '',
          });
        }
      } else {
        setUserRole({ roleName: 'User' });
        setUserData({
          fullName: '',
          photoURL: '',
        });
      }
    };

    fetchUserRoleAndData();
  }, [currentUser, organizationData.groupId, firestore]);

  // Updated fetchUsersAndRoles function
const fetchUsersAndRoles = async () => {
  if (!organizationData || !organizationData.groupId) {
    console.error('Organization data is not available.');
    return;
  }
  setIsUsersLoading(true);
  try {
    // Fetch roles from roleGroups/{groupId}/roles
    const rolesCollectionRef = collection(
      firestore,
      'roleGroups',
      organizationData.groupId,
      'roles'
    );
    const rolesSnapshot = await getDocs(rolesCollectionRef);
    const rolesList = rolesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const rolesMapTemp = {};
    rolesList.forEach((role) => {
      rolesMapTemp[role.id] = role;
    });
    setRolesMap(rolesMapTemp);
    setRoles(rolesList);

    // Fetch users
    const usersCollectionRef = collection(firestore, 'users');
    const usersSnapshot = await getDocs(usersCollectionRef);
    const usersList = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Filter users
    const filteredUsers = usersList.filter((user) => {
      return user.roles?.some((roleEntry) => {
        const groupIdRef = roleEntry.groupId;
        const groupId = groupIdRef && groupIdRef.id;
        return groupId === organizationData.groupId;
      });
    });
    
    // Map users with role names
    const usersWithRoles = filteredUsers.map((user) => {
      const roleNames = user.roles
        .filter((roleEntry) => {
          const groupIdRef = roleEntry.groupId;
          const groupId = groupIdRef && groupIdRef.id;
          return groupId === organizationData.groupId;
        })
        .map((roleEntry) => {
          const roleRef = roleEntry.role;
          const roleId = roleRef && roleRef.id;
          return rolesMapTemp[roleId]?.name || 'Unknown Role';
        });

      return {
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        roleNames,
        ...user,
      };
    });

    setUsers(usersWithRoles);
  } catch (error) {
    console.error('Error fetching users and roles:', error);
    setIsUsersError(error);
  } finally {
    setIsUsersLoading(false);
  }
};

// Updated addUser function
const addUser = async ({ email, firstName, lastName, roleId }) => {
  if (!email || !firstName || !lastName || !roleId) {
    throw new Error('Missing required fields');
  }

  try {
    // Check if user exists
    const usersRef = collection(firestore, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      // User exists, update roles
      const existingUserDoc = querySnapshot.docs[0];
      const existingUserData = existingUserDoc.data();
      let rolesArray = existingUserData.roles || [];

      const roleExists = rolesArray.some(
        (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
      );

      if (roleExists) {
        rolesArray = rolesArray.map((roleEntry) => {
          if (roleEntry.groupId?.id === organizationData.groupId) {
            return {
              ...roleEntry,
              role: doc(
                firestore,
                'roleGroups',
                organizationData.groupId,
                'roles',
                roleId
              ),
              organizationId: organizationData.organizationId,
            };
          }
          return roleEntry;
        });
      } else {
        rolesArray.push({
          groupId: doc(firestore, 'groups', organizationData.groupId),
          organizationId: organizationData.organizationId,
          role: doc(
            firestore,
            'roleGroups',
            organizationData.groupId,
            'roles',
            roleId
          ),
        });
      }

      const userDocRef = doc(firestore, 'users', existingUserDoc.id);
      await updateDoc(userDocRef, {
        firstName,
        lastName,
        roles: rolesArray,
      });

      await fetchUsersAndRoles();
      return { action: 'updated', userId: existingUserDoc.id };
    } else {
      // Create new user via cloud function
      const currentUser = auth.currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();

        const response = await fetch('https://create-user-lgfph5hmwq-uc.a.run.app', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            email,
            firstName,
            lastName,
            groupId: organizationData.groupId,
            roles: [
              {
                groupId: `groups/${organizationData.groupId}`,
                organizationId: organizationData.organizationId,
                role: `roleGroups/${organizationData.groupId}/roles/${roleId}`,
              },
            ],
            active: true,
            language: appSettingsData.language,
            pictureProfile: UserIcon,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          const sendEmail = await resetUserPassword(email);
          await fetchUsersAndRoles();
          return { action: 'created', userId: data.uid };
        } else {
          throw new Error(`Error creating user: ${data.message || data}`);
        }
      } else {
        throw new Error('User not authenticated');
      }
    }
  } catch (error) {
    console.error('Error adding user:', error);
    throw error;
  }
};


// Updated editUser function
const editUser = async (userId, { firstName, lastName, roleId }) => {
  if (!userId || !firstName || !lastName || !roleId) {
    throw new Error('Missing required fields');
  }

  try {
    const userDocRef = doc(firestore, 'users', userId);
    const userSnapshot = await getDoc(userDocRef);
    if (userSnapshot.exists()) {
      const existingUserData = userSnapshot.data();
      let rolesArray = existingUserData.roles || [];

      const roleExists = rolesArray.some(
        (roleEntry) => roleEntry.groupId?.id === organizationData.groupId
      );

      if (roleExists) {
        rolesArray = rolesArray.map((roleEntry) => {
          if (roleEntry.groupId?.id === organizationData.groupId) {
            return {
              ...roleEntry,
              role: doc(
                firestore,
                'roleGroups',
                organizationData.groupId,
                'roles',
                roleId
              ),
              organizationId: organizationData.organizationId,
            };
          }
          return roleEntry;
        });
      } else {
        rolesArray.push({
          groupId: doc(firestore, 'groups', organizationData.groupId),
          organizationId: organizationData.organizationId,
          role: doc(
            firestore,
            'roleGroups',
                organizationData.groupId,
                'roles',
                roleId
              ),
            });
          }

          // Get the current authenticated user
          const currentUser = auth.currentUser;
          if (!currentUser) {
            throw new Error('User not authenticated');
          }

          // Ensure appSettingsData is available
          if (!appSettingsData || !appSettingsData.language) {
            throw new Error('App settings data not available');
          }

          // Update the user document with additional fields
          await updateDoc(userDocRef, {
            firstName,
            lastName,
            roles: rolesArray,
            active: true,
            language: appSettingsData.language,
            pictureProfile: UserIcon,
          });

          await fetchUsersAndRoles();
          return { action: 'updated', userId };
        } else {
          throw new Error('User does not exist');
        }
      } catch (error) {
        console.error('Error editing user:', error);
        throw error;
      }
    };

  // Function to delete a user
  const deleteUser = async (userId) => {
    if (!userId) {
      throw new Error('User ID is required');
    }

    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        const response = await fetch('https://delete-user-lgfph5hmwq-uc.a.run.app', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ uid: userId, groupId: organizationData.groupId  }),
        });

        const data = await response.json();

        if (response.ok) {
          await fetchUsersAndRoles();
          return { action: 'deleted', userId };
        } else {
          throw new Error(`Error deleting user: ${data.message || data}`);
        }
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  };

  // Function to reset user password
  const resetUserPassword = async (email) => {
    if (!email) {
      throw new Error('Email is required');
    }

    try {
      await auth.sendPasswordResetEmail(email);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  // Remove 'initialData' and add 'keepPreviousData: true'
const {
  data: appSettingsData,
  isLoading: appSettingsLoading,
  error: appSettingsError,
  refetch: refetchAppSettings,
} = useQuery({
  queryKey: ['appSettings', organizationData.groupId],
  queryFn: async () => {
    const settingsDocRef = doc(firestore, 'groups', organizationData.groupId);
    const docSnapshot = await getDoc(settingsDocRef);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const appSettings = {
        profilePicture: data.profilePicture,
        color: data.color,
        language: data.language,
      };
      return appSettings;
    } else {
      const defaultSettings = {
        profilePicture: null,
        color: '#bb8300',
        language: 'en',
      };
      await setDoc(settingsDocRef, defaultSettings);
      return defaultSettings;
    }
  },
  enabled: !!firestore && !!organizationData.groupId,
  keepPreviousData: true, // Add this line
  onError: (err) => {
    console.error('Error fetching appSettings:', err);
  },
});

  // Mutation to update appSettings
const updateAppSettingsMutation = useMutation({
  mutationFn: async (newSettings) => {
    const settingsDocRef = doc(firestore, 'groups', organizationData.groupId);
    await setDoc(settingsDocRef, newSettings, { merge: true }); // Ensure merge is true
  },
  onMutate: async (newSettings) => {
    await queryClient.cancelQueries(['appSettings', organizationData.groupId]);

    const previousAppSettings = queryClient.getQueryData(['appSettings', organizationData.groupId]);

    queryClient.setQueryData(['appSettings', organizationData.groupId], (oldAppSettings) => ({
      ...oldAppSettings,
      ...newSettings,
    }));

    return { previousAppSettings };
  },
  onError: (err, newSettings, context) => {
    queryClient.setQueryData(['appSettings', organizationData.groupId], context.previousAppSettings);
    console.error('Error updating appSettings:', err);
  },
  onSettled: () => {
    // After mutation, invalidate and refetch appSettings
    queryClient.invalidateQueries(['appSettings', organizationData.groupId]);
  },
});

const updateSettings = async (newSettings, specificField = null) => {
  const settingsDocRef = doc(firestore, 'groups', organizationData.groupId);
  const settingsToUpdate = specificField ? { [specificField]: newSettings[specificField] } : newSettings;

  await updateAppSettingsMutation.mutateAsync(settingsToUpdate);
};

  /**
   * Helper function to compress base64 image data
   */
  const compressBase64Image = (base64Image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        // Create a canvas with the desired dimensions
        const canvas = document.createElement('canvas');
        const maxWidth = 800; // Maximum width
        const maxHeight = 800; // Maximum height

        let width = img.width;
        let height = img.height;

        // Calculate the scaling factor to maintain aspect ratio
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // Compress the image
        const compressedBase64 = canvas.toDataURL('image/webp', 0.7); // Adjust quality as needed (0.0 - 1.0)
        resolve(compressedBase64);
      };
      img.onerror = function (error) {
        console.error('Error loading image:', error);
        reject(error);
      };
      img.src = base64Image;
    });
  };

  /**
   * Helper function to compress images in responseData.fields
   */
  const compressImageFields = async (fields) => {
    const processedFields = await Promise.all(
      fields.map(async (field) => {
        if (
          field.type === 'ImagePicker' &&
          field.value &&
          field.value.startsWith('data:image/')
        ) {
          // Compress the image
          const compressedValue = await compressBase64Image(field.value);
          return {
            ...field,
            value: compressedValue,
          };
        } else {
          return field;
        }
      })
    );
    return processedFields;
  };

  // Function to upload logo
  const uploadLogo = async (logoFile, onProgress) => {
    if (!organizationData.groupId) {
      throw new Error('Missing groupId in organizationData.');
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();
        img.onload = async function () {
          // Create a canvas with the desired dimensions
          const canvas = document.createElement('canvas');
          const maxWidth = 800; // Maximum width
          const maxHeight = 800; // Maximum height

          let width = img.width;
          let height = img.height;

          // Calculate the scaling factor to maintain aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Compress the image
          const compressedBase64 = canvas.toDataURL('image/webp', 0.7); // Adjust quality as needed (0.0 - 1.0)

          // Now update the group's profilePicture field in Firestore
          try {
            const settingsDocRef = doc(firestore, 'groups', organizationData.groupId);
            await setDoc(settingsDocRef, { profilePicture: compressedBase64 }, { merge: true });
            resolve(compressedBase64);
          } catch (error) {
            console.error('Error updating profilePicture in Firestore:', error);
            reject(error);
          }
        };
        img.onerror = function (error) {
          console.error('Error loading image:', error);
          reject(error);
        };
        img.src = event.target.result;
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };

      reader.onprogress = (event) => {
        if (event.lengthComputable && onProgress) {
          const progress = (event.loaded / event.total) * 100;
          onProgress(progress);
        }
      };

      reader.readAsDataURL(logoFile);
    });
  };

  const {
    data: sortedForms,
    isLoading: allFormsLoading,
    error: allFormsError,
    refetch: refetchAllForms,
  } = useQuery({
    queryKey: ['formGroups', organizationData.groupId, 'forms'],
    queryFn: async () => {
      const formsRef = collection(firestore, 'formGroups', organizationData.groupId, 'forms');
      const q = query(formsRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      const fetchedForms = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      // Now, for each form, fetch the response count and last response date
      const formsWithStats = await Promise.all(
        fetchedForms.map(async (form) => {
          const responsesCollectionRef = collection(
            firestore,
            'formGroups',
            organizationData.groupId,
            'forms',
            form.id,
            'responses'
          );
  
          // Get the count of responses
          const countQuery = query(responsesCollectionRef);
          const countSnapshot = await getCountFromServer(countQuery);
          const responseCount = countSnapshot.data().count;
  
          // Get the last response date
          const lastResponseQuery = query(responsesCollectionRef, orderBy('submittedAt', 'desc'), limit(1));
          const lastResponseSnapshot = await getDocs(lastResponseQuery);
          let lastResponseDate = null;
          if (!lastResponseSnapshot.empty) {
            const lastResponseDoc = lastResponseSnapshot.docs[0];
            const lastResponseData = lastResponseDoc.data();
            lastResponseDate = lastResponseData.submittedAt;
          }
  
          return {
            ...form,
            responseCount,
            lastResponseDate,
          };
        })
      );

  
      return formsWithStats;
    },
    enabled: !!firestore && !!organizationData.groupId && !!organizationData.organizationId,
    onError: (err) => {
      console.error('Error fetching forms:', err);
    },
  });

  // Fetch groups data using React Query.
  const {
    data: groupsData,
    isLoading: groupsLoading,
    error: groupsError,
    refetch: refetchGroups,
  } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const groupsRef = collection(firestore, 'groups');
      const q = query(groupsRef, orderBy('groupName', 'asc'));
      const snapshot = await getDocs(q);
      const fetchedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Filter groups based on organizationData
      const filteredGroups = fetchedData.filter((group) => {
        if (organizationData.groupId === organizationData.organizationId) {
          // Show all groups with matching organizationId
          return (
            group.organizationId.id === organizationData.organizationId &&
            group.id != organizationData.organizationId
          );
        } else {
          // Show groups matching groupId and organizationId
          return (
            group.id === organizationData.groupId &&
            group.organizationId.id === organizationData.organizationId
          );
        }
      });
      return filteredGroups;
    },
    enabled: !!firestore && !!organizationData.groupId && !!organizationData.organizationId,
    onError: (err) => {
      console.error('Error fetching groups:', err);
    },
  });

// Sorting Forms Client-Side
const sortedFormsData = useMemo(() => {
  if (!sortedForms) return [];

  const sorted = [...sortedForms].sort((a, b) => {
    const aField = a[sortField];
    const bField = b[sortField];

    // Handle undefined or null fields
    if (aField == null && bField == null) return 0;
    if (aField == null) return sortOrder === 'asc' ? -1 : 1;
    if (bField == null) return sortOrder === 'asc' ? 1 : -1;

    // Compare based on data type
    if (typeof aField === 'string' && typeof bField === 'string') {
      return sortOrder === 'asc'
        ? aField.localeCompare(bField)
        : bField.localeCompare(aField);
    }

    // Handle Firestore Timestamps
    if (aField instanceof Timestamp && bField instanceof Timestamp) {
      return sortOrder === 'asc'
        ? aField.toMillis() - bField.toMillis()
        : bField.toMillis() - aField.toMillis();
    }

    // For responseCount (numbers)
    if (typeof aField === 'number' && typeof bField === 'number') {
      return sortOrder === 'asc' ? aField - bField : bField - aField;
    }

    // Default comparison
    if (aField < bField) return sortOrder === 'asc' ? -1 : 1;
    if (aField > bField) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });
  return sorted;
}, [sortedForms, sortField, sortOrder]);

  // Function to set sorting parameters
  const setSorting = (field) => {
    if (sortField === field) {
      // Toggle sort order
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortOrder('asc'); // Reset to ascending when changing field
    }
    // Reset to first page when sorting changes
    setCurrentPage(1);
    // No need to invalidate members/forms query; sorting is handled client-side
  };

  // Function to handle itemsPerPage change
  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    // No need to refetch members/forms; pagination is handled client-side
  };



  /**
   * **Responses Management with React Query**
   */

  /**
   * Function to fetch responses for a form
   */
  const fetchResponsesForForm = async (formId) => {
    console.log('fetchResponsesForForm called with formId:', formId);

    if (!formId) {
      console.warn('fetchResponsesForForm called without a formId.');
      return [];
    }

    if (!organizationData) {
      console.error('organizationData is not defined.');
      throw new Error('organizationData is not defined.');
    }

    const { groupId, organizationId } = organizationData;

    if (!groupId) {
      console.error('Group ID is not defined in organizationData.');
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Helper function to fetch responses for a specific groupId
      const fetchResponsesByGroup = async (currentGroupId) => {
        console.log(`Fetching responses for groupId: ${currentGroupId}`);

        const responsesCollection = collection(
          firestore,
          'formGroups',
          currentGroupId,
          'forms',
          formId,
          'responses'
        );

        const responsesQuery = query(
          responsesCollection,
          orderBy('submittedAt', 'desc')
          // You can add a limit here if needed, e.g., limit(100)
        );

        const responsesSnapshot = await getDocs(responsesQuery);
        console.log(
          `Fetched ${responsesSnapshot.size} responses from groupId: ${currentGroupId}`
        );

        return responsesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      };

      let fetchedResponses = [];

      if (groupId === organizationId) {

        // When groupId equals organizationId, fetch from all formGroups
        const formGroupsCollection = collection(firestore, 'formGroups');
        const formGroupsSnapshot = await getDocs(formGroupsCollection);
        console.log(`Fetched ${formGroupsSnapshot.size} formGroups.`);

        if (formGroupsSnapshot.empty) {
          console.warn('No formGroups found.');
          return [];
        }

        const formGroupIds = formGroupsSnapshot.docs.map((doc) => doc.id);

        // Fetch responses from all formGroups in parallel
        const allResponsesPromises = formGroupIds.map((currentGroupId) =>
          fetchResponsesByGroup(currentGroupId)
        );

        const allResponsesArrays = await Promise.all(allResponsesPromises);
        console.log('All responses arrays fetched.');

        // Flatten the array of arrays into a single array of responses
        fetchedResponses = allResponsesArrays.flat();
        console.log(`Total responses fetched: ${fetchedResponses.length}`);
      } else {
        console.log('groupId does not equal organizationId. Fetching from specific groupId.');

        // When groupId does not equal organizationId, fetch from the specific groupId
        fetchedResponses = await fetchResponsesByGroup(groupId);
        console.log(
          `Total responses fetched from groupId ${groupId}: ${fetchedResponses.length}`
        );
      }

      return fetchedResponses;
    } catch (error) {
      console.error(`Error fetching responses for formId ${formId}:`, error);
      throw error;
    }
  };

  /**
   * Custom hook to manage responses using React Query
   */
  const useResponses = (formId) => {
    return useQuery({
      queryKey: ['responses', formId],
      queryFn: () => fetchResponsesForForm(formId),
      enabled: !!formId, // Only run if formId is provided
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 2, // Retry failed requests twice
      onError: (error) => {
        // Optional: Implement global error handling or notifications
        console.error('Failed to fetch responses:', error);
      },
    });
  };

/**
 * Function to submit a form response
 */
const submitFormResponse = async (formId, responseData) => {
  if (!formId) {
    console.warn('submitFormResponse called without a formId.');
    throw new Error('Form ID is required to submit a response.');
  }

  const groupId = organizationData.groupId;
  if (!groupId) {
    throw new Error('Group ID is not defined in organizationData.');
  }

  try {
    // Compress images in responseData.fields
    const compressedFields = await compressImageFields(responseData.fields);
    responseData.fields = compressedFields;

    // Define the path to the responses collection under the new structure
    const responsesCollection = collection(
      firestore,
      'formGroups',
      groupId,
      'forms',
      formId,
      'responses'
    );

    // Add the response data with a timestamp
    const docRef = await addDoc(responsesCollection, {
      ...responseData,
      submittedAt: serverTimestamp(),
    });

    // **Additional Logic for "groupsForm"**
    if (formId === 'groupsForm') {
      // Transform responseData.fields into a document
      const groupData = {};
      responseData.fields.forEach((field) => {
        groupData[field.id] = field.value;
      });
      // Add createdAt, updatedAt, organizationId, and id
      groupData.createdAt = serverTimestamp();
      groupData.updatedAt = serverTimestamp();
      groupData.organizationId = doc(firestore, 'organization', organizationData.organizationId);
      groupData.id = docRef.id;

      // Create a new document in the "groups" collection
      const groupsDocRef = doc(firestore, 'groups', docRef.id);
      await setDoc(groupsDocRef, groupData);

      // **Existing Logic to create formGroups/{groupId} document**
      const formGroupDocRef = doc(firestore, 'formGroups', docRef.id);
      const formGroupData = {
        id: docRef.id,
        organizationId: organizationData.organizationId,
      };
      await setDoc(formGroupDocRef, formGroupData);

      // Copy default forms to the new group's forms collection
      const defaultFormsRef = collection(firestore, 'defaultForms');
      const defaultFormsSnapshot = await getDocs(defaultFormsRef);
      const formsBatch = writeBatch(firestore);

      defaultFormsSnapshot.forEach((defaultFormDoc) => {
        const defaultFormData = defaultFormDoc.data();

        // Add groupId and organizationId to each form
        defaultFormData.groupId = docRef.id;
        defaultFormData.organizationId = organizationData.organizationId;

        // Set the document in 'formGroups/{groupId}/forms/{formId}'
        const formId = defaultFormDoc.id;
        const targetFormDocRef = doc(
          firestore,
          'formGroups',
          docRef.id,
          'forms',
          formId
        );
        formsBatch.set(targetFormDocRef, defaultFormData);
      });

      // Commit the batch operation for forms
      await formsBatch.commit();

      // **New Logic to create roleGroups/{groupId} document**
      const roleGroupDocRef = doc(firestore, 'roleGroups', docRef.id);
      const roleGroupData = {
        id: docRef.id,
        organizationId: organizationData.organizationId,
      };
      await setDoc(roleGroupDocRef, roleGroupData);

      // Copy default roles to the new role group's roles collection
      const defaultRolesRef = collection(firestore, 'defaultRoles');
      const defaultRolesSnapshot = await getDocs(defaultRolesRef);
      const rolesBatch = writeBatch(firestore);

      defaultRolesSnapshot.forEach((defaultRoleDoc) => {
        const defaultRoleData = defaultRoleDoc.data();

        // Add groupId and organizationId to each role
        defaultRoleData.groupId = docRef.id;
        defaultRoleData.organizationId = organizationData.organizationId;

        // Set the document in 'roleGroups/{groupId}/roles/{roleId}'
        const roleId = defaultRoleDoc.id;
        const targetRoleDocRef = doc(
          firestore,
          'roleGroups',
          docRef.id,
          'roles',
          roleId
        );

        rolesBatch.set(targetRoleDocRef, defaultRoleData);
      });

      // Commit the batch operation for roles
      await rolesBatch.commit();

      // **New Logic to add the Admin role to the current user**
      if (auth.currentUser) {
        const currentUserId = auth.currentUser.uid;

        // Reference to the user's document
        const userDocRef = doc(firestore, 'users', currentUserId);

        // Get the user's document
        const userSnapshot = await getDoc(userDocRef);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();

          // Get the existing roles array or initialize it
          let rolesArray = userData.roles || [];

          // Add the new role entry
          rolesArray.push({
            groupId: doc(firestore, 'groups', docRef.id), // The new group ID as a reference
            organizationId: organizationData.organizationId,
            role: doc(
              firestore,
              'roleGroups',
              docRef.id,
              'roles',
              'Qk6agHI01TjA0vqGqUGT'
            ), // Admin role reference
          });

          // Update the user's document
          await updateDoc(userDocRef, { roles: rolesArray });
        } else {
          console.warn(`User document for user ID ${currentUserId} does not exist.`);
        }
      } else {
        console.warn('No authenticated user found when trying to add admin role.');
      }
    }

    // Invalidate the responses query to refetch after submission
    queryClient.invalidateQueries([
      'formGroups',
      groupId,
      'forms',
      formId,
      'responses',
    ]);

    return docRef.id; // Return Firestore document ID
  } catch (error) {
    console.error('Error submitting response:', error);
    throw error;
  }
};

  /**
 * Retrieves the document data from a given Firestore reference.
 *
 * @param {DocumentReference} reference - The Firestore document reference.
 * @returns {Promise<Object|null>} - A promise that resolves to the document data or null if it doesn't exist.
 */
const getObjectByReference = async (reference) => {
  try {
    const docSnap = await getDoc(reference);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error getting document:', error);
    throw error;
  }
};


  /**
   * Function to update a response
   */
  const updateResponse = async (formId, responseId, responseData) => {
    if (!formId || !responseId) {
      console.warn('updateResponse called without formId or responseId.');
      throw new Error('Form ID and Response ID are required to update a response.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Compress images in responseData.fields
      const compressedFields = await compressImageFields(responseData.fields);
      responseData.fields = compressedFields;

      // Define the path to the specific response document under the new structure
      const responseDocRef = doc(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses',
        responseId
      );

      // Update the response data with an updated timestamp
      await updateDoc(responseDocRef, {
        ...responseData,
        updatedAt: serverTimestamp(),
      });

      // **Additional Logic for "groupsForm"**
      if (formId === 'groupsForm') {
        // Transform responseData.fields into a document
        const groupData = {};
        responseData.fields.forEach((field) => {
          groupData[field.id] = field.value;
        });
        // Add updatedAt, organizationId, and groupId
        groupData.updatedAt = serverTimestamp();
        groupData.organizationId = doc(firestore, 'organization', organizationData.organizationId);
        groupData.id = responseId;

        const groupsDocRef = doc(firestore, 'groups', responseId);
        await updateDoc(groupsDocRef, groupData);
      }

      // Invalidate the responses query to refetch after update
      queryClient.invalidateQueries(['formGroups', groupId, 'forms', formId, 'responses']);
    } catch (error) {
      console.error('Error updating response:', error);
      throw error;
    }
  };

  /**
   * Function to delete a response
   */
  const deleteResponse = async (formId, responseId) => {
    if (!formId || !responseId) {
      console.warn('deleteResponse called without formId or responseId.');
      throw new Error('Form ID and Response ID are required to delete a response.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific response document under the new structure
      const responseDocRef = doc(
        firestore,
        'formGroups',
        groupId,
        'forms',
        formId,
        'responses',
        responseId
      );

      // Delete the response document
      await deleteDoc(responseDocRef);

      // **Additional Logic for "groupsForm"**
      if (formId === 'groupsForm') {
        const groupsDocRef = doc(firestore, 'groups', responseId);
        await deleteDoc(groupsDocRef);
      }

      // Invalidate the responses query to refetch after deletion
      queryClient.invalidateQueries(['formGroups', groupId, 'forms', formId, 'responses']);
    } catch (error) {
      console.error('Error deleting response:', error);
      throw error;
    }
  };

  /**
   * Function to fetch form details
   */
  const fetchFormDetails = async (formId) => {
    if (!formId) {
      console.warn('fetchFormDetails called without a formId.');
      throw new Error('Form ID is required to fetch form details.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific form document under the new structure
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        const formDataFirestore = formSnapshot.data();
        return { id: formId, ...formDataFirestore };
      } else {
        console.warn(`Form with ID ${formId} does not exist.`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching form details for formId ${formId}:`, error);
      throw error;
    }
  };

  /**
   * Function to fetch catalog data for given catalogIds
   */
  const fetchCatalogs = async (catalogIds) => {
    try {
      const fetchedCatalogData = {};
      for (const catalogId of catalogIds) {
        const catalogDocRef = doc(firestore, 'catalog', catalogId);
        const catalogDocSnap = await getDoc(catalogDocRef);
        if (catalogDocSnap.exists()) {
          const catalog = catalogDocSnap.data();
          fetchedCatalogData[catalogId] = catalog;
        } else {
          console.warn(`Catalog with ID ${catalogId} does not exist.`);
        }
      }
      return fetchedCatalogData;
    } catch (error) {
      console.error('Error fetching catalogs:', error);
      throw error;
    }
  };

  /**
   * Function to fetch all catalogs
   */
  const fetchAllCatalogs = async () => {
    try {
      const catalogsCollection = collection(firestore, 'catalog');
      const snapshot = await getDocs(catalogsCollection);
      const fetchedCatalogs = snapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Assuming each catalog has a 'name' field
      }));
      return fetchedCatalogs;
    } catch (error) {
      console.error('Error fetching catalogs:', error);
      throw error;
    }
  };

  /**
   * Function to fetch catalog options by catalogId
   */
  const fetchCatalogOptions = async (catalogId) => {
    try {
      const catalogDocRef = doc(firestore, 'catalog', catalogId);
      const catalogDocSnap = await getDoc(catalogDocRef);
      if (catalogDocSnap.exists()) {
        const catalogData = catalogDocSnap.data();
        if (Array.isArray(catalogData.codes)) {
          const fetchedOptions = catalogData.codes.map((code) => ({
            value: code, // Assuming codes are unique identifiers
            label: code, // You can adjust this based on your data structure
          }));
          return fetchedOptions;
        } else {
          console.warn('Catalog "codes" is not an array.');
          return [];
        }
      } else {
        console.warn('No such catalog document!');
        return [];
      }
    } catch (error) {
      console.error('Error fetching catalog options:', error);
      throw error;
    }
  };

  /**
   * Function to save a form (create or update) under formGroups/{groupId}/forms/{formId}
   */
  const saveForm = async (formId, formData) => {
    try {
      if (!organizationData.groupId) {
        throw new Error('Group ID is missing in organizationData.');
      }

      if (formId) {
        const formDocRef = doc(
          firestore,
          'formGroups',
          organizationData.groupId,
          'forms',
          formId
        );

        // Update existing form
        await updateDoc(formDocRef, {
          ...formData,
          updatedAt: serverTimestamp(),
        });
      } else {
        const formCollection = collection(
          firestore,
          'formGroups',
          organizationData.groupId,
          'forms'
        );
        // Create or set the {formId} document
        const docRef = await addDoc(formCollection, {
          ...formData,
          groupId: organizationData.groupId, // Reference to group
          organizationId: organizationData.organizationId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        return docRef.id;
      }

      // Invalidate and refetch forms query to ensure data consistency
      queryClient.invalidateQueries(['formGroups', organizationData.groupId]);
    } catch (error) {
      console.error('Error saving form:', error);
      throw error;
    }
  };

  /**
   * Function to delete a form
   */
  const deleteForm = async (formId) => {
    // Define the protected form IDs that cannot be deleted
    const protectedFormIds = ['membersForm', 'groupsForm'];

    // Check if the formId is protected
    if (protectedFormIds.includes(formId)) {
      // Throw an error to prevent deletion
      throw new Error('This form cannot be deleted.');
    }

    // Retrieve the groupId from organizationData
    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific form document under the new structure
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);

      // Delete the form document
      await deleteDoc(formDocRef);

      // Invalidate forms query to refetch the forms
      queryClient.invalidateQueries(['formGroups', groupId, 'forms']);
    } catch (error) {
      console.error('Error deleting form:', error);
      throw error;
    }
  };

  /**
   * Function to fetch form fields
   */
  const getFormFields = async (formId) => {
    if (!formId) {
      console.warn('getFormFields called without a formId.');
      throw new Error('Form ID is required to fetch form fields.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      // Define the path to the specific form document under the new structure
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        const formData = formSnapshot.data();

        // Assuming 'fields' is an array within the form document
        const fields = formData;
        if (!fields) {
          throw new Error(`Form with ID ${formId} does not contain a valid "fields" array.`);
        }

        return fields;
      } else {
        throw new Error(`Form with ID ${formId} does not exist.`);
      }
    } catch (error) {
      console.error(`Error fetching form fields for formId ${formId}:`, error);
      throw error;
    }
  };

  const useFormFields = (formId) => {
    return useQuery({
      queryKey: ['formFields', formId],
      queryFn: () => getFormFields(formId),
      enabled: !!formId, // Only run if formId is provided
      staleTime: 5 * 60 * 1000, // 5 minutes
    });
  };

  // Set up authentication state listener.
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setCurrentUserLoading(false);
      if (user) {
        // Invalidate queries to fetch fresh data that require authentication
        queryClient.invalidateQueries(['members', 'formGroups']);
      } else {
        // Remove queries that require authentication
        queryClient.removeQueries(['members', 'formGroups']);
        // Do not clear appSettings so that unauthenticated users can access it
      }
    });

    return () => unsubscribe();
  }, [queryClient]);

  // Authentication methods

  // Function to verify password reset code
  const verifyPasswordResetCode = async (code) => {
    try {
      await auth.verifyPasswordResetCode(code);
      return true; // Code is valid
    } catch (error) {
      console.error('Error verifying password reset code:', error);
      throw error;
    }
  };

  // Function to confirm password reset
  const confirmPasswordReset = async (oobCode, newPassword) => {
    try {
      await auth.confirmPasswordReset(oobCode, newPassword);
      return true; // Password reset successful
    } catch (error) {
      console.error('Error confirming password reset:', error);
      throw error;
    }
  };

  // Function to send password reset email
  const sendPasswordResetEmail = async (email) => {
    try {
      await auth.sendPasswordResetEmail(email);
      return true; // Email sent
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  // Function to sign in
  const signIn = async (email, password) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
      // currentUser will be updated via auth.onAuthStateChanged listener
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  // Function to sign out
  const signOut = async () => {
    try {
      await auth.signOut();
      return true; // Sign-out successful
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  // Function to get current user's email
  const getCurrentUserEmail = () => {
    const currentUser = auth.currentUser;
    return currentUser ? currentUser.email : null;
  };

  /**
   * Function to get tableFields configuration from Firestore
   */
  const getTableFields = async (formId) => {
    if (!formId) {
      throw new Error('Form ID is required to get table fields.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      const formSnapshot = await getDoc(formDocRef);

      if (formSnapshot.exists()) {
        const formData = formSnapshot.data();
        return formData.tableFields || [];
      } else {
        console.warn(`Form with ID ${formId} does not exist.`);
        return [];
      }
    } catch (error) {
      console.error('Error fetching table fields:', error);
      throw error;
    }
  };

  /**
   * Function to save tableFields configuration to Firestore
   */
  const saveTableFields = async (formId, tableFields) => {
    if (!formId) {
      throw new Error('Form ID is required to save table fields.');
    }

    const groupId = organizationData.groupId;
    if (!groupId) {
      throw new Error('Group ID is not defined in organizationData.');
    }

    try {
      const formDocRef = doc(firestore, 'formGroups', groupId, 'forms', formId);
      await updateDoc(formDocRef, {
        tableFields: tableFields,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error saving table fields:', error);
      throw error;
    }
  };


   // Fetch teams
const fetchTeams = async () => {
  if (!organizationData || !organizationData.groupId) {
    console.error('Organization data is not available.');
    return;
  }
  setIsTeamsLoading(true);
  try {
    const teamsCollectionRef = collection(
      firestore,
      'teamGroups',
      organizationData.groupId,
      'teams'
    );
    const teamsSnapshot = await getDocs(teamsCollectionRef);
    const teamsList = teamsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // Resolve member references and flatten member fields
    const teamsWithMemberData = await Promise.all(
      teamsList.map(async (team) => {
        const memberRefs = team.members || [];
        const membersData = await Promise.all(
          memberRefs.map(async (memberRef) => {
            const memberSnapshot = await getDoc(memberRef);
            if (memberSnapshot.exists()) {
              const memberData = memberSnapshot.data();
              memberData.id = memberRef.id;

              // Flatten memberData.fields into memberData
              if (Array.isArray(memberData.fields)) {
                memberData.fields.forEach((field) => {
                  memberData[field.id] = field.value;
                });
              }
              
              return memberData;
            } else {
              return null;
            }
          })
        );
        // Filter out nulls
        const members = membersData.filter((m) => m !== null);
        return {
          ...team,
          membersData: members,
        };
      })
    );

    setTeams(teamsWithMemberData);
  } catch (error) {
    console.error('Error fetching teams:', error);
    setIsTeamsError(error);
  } finally {
    setIsTeamsLoading(false);
  }
};

const addTeam = async ({ name, members }) => {
  if (!name) {
    throw new Error('Team name is required');
  }
  try {
    const teamsCollectionRef = collection(
      firestore,
      'teamGroups',
      organizationData.groupId,
      'teams'
    );

    // Map member IDs to DocumentReferences using each member's groupId
    const memberRefs = (members || []).map((member) => {
      const memberParts = member.split("_");
      const memberGroupId = memberParts[0];
      const memberId = memberParts[1];

      if (!memberGroupId) {
        throw new Error(`Missing groupId for member with ID ${memberId}`);
      }

      return doc(
        firestore,
        'formGroups',
        memberGroupId,
        'forms',
        'membersForm',
        'responses',
        memberId
      );
    });

    const newTeam = {
      name,
      members: memberRefs,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };
    await addDoc(teamsCollectionRef, newTeam);
    await fetchTeams();
  } catch (error) {
    console.error('Error adding team:', error);
    throw error;
  }
};

// Edit team
const editTeam = async (teamId, { name, members }) => {
  if (!teamId || !name) {
    throw new Error('Team ID and name are required');
  }
  try {
    const teamDocRef = doc(
      firestore,
      'teamGroups',
      organizationData.groupId,
      'teams',
      teamId
    );

    // Map member IDs to DocumentReferences using each member's groupId
    const memberRefs = (members || []).map((member) => {
      const memberParts = member.split("_");
      const memberGroupId = memberParts[0];
      const memberId = memberParts[1];
      

      if (!memberGroupId) {
        throw new Error(`Missing groupId for member with ID ${memberId}`);
      }

      return doc(
        firestore,
        'formGroups',
        memberGroupId,
        'forms',
        'membersForm',
        'responses',
        memberId
      );
    });

    await updateDoc(teamDocRef, {
      name,
      members: memberRefs,
      updatedAt: serverTimestamp(),
    });
    await fetchTeams();
  } catch (error) {
    console.error('Error editing team:', error);
    throw error;
  }
};

  // Delete team
  const deleteTeam = async (teamId) => {
    if (!teamId) {
      throw new Error('Team ID is required');
    }
    try {
      const teamDocRef = doc(
        firestore,
        'teamGroups',
        organizationData.groupId,
        'teams',
        teamId
      );
      await deleteDoc(teamDocRef);
      await fetchTeams();
    } catch (error) {
      console.error('Error deleting team:', error);
      throw error;
    }
  };

  // Fetch teams when organizationData.groupId changes
  useEffect(() => {
    if (organizationData.groupId) {
      fetchTeams();
    }
  }, [organizationData.groupId]);

  /**
   * Expose data and functions via context
   */
  return (
    <DataContext.Provider
      value={{
        // Forms data
        sortedForms: sortedFormsData || [],
        allFormsLoading,
        allFormsError,
        refetchAllForms,

        // User data
        userRole,
        userData,
        organizationData,
        getObjectByReference,

        // Users and Roles data
        users,
        roles,
        rolesMap,
        isUsersLoading,
        isUsersError,
        fetchUsersAndRoles,
        addUser,
        editUser,
        deleteUser,
        resetUserPassword,

        // App settings
        appSettings: appSettingsData,
        appSettingsLoading,
        appSettingsError,
        updateSettings,
        refetchAppSettings,

        // Sorting
        sortField,
        sortOrder,
        setSorting,

        // Groups data
        groupsData: groupsData || [],
        groupsLoading,
        groupsError,
        refetchGroups,

        // Responses management
        useResponses,

        // Forms and Responses methods
        saveForm,
        deleteForm,
        fetchResponsesForForm,
        deleteResponse,
        fetchFormDetails,
        fetchCatalogs,
        fetchAllCatalogs,
        fetchCatalogOptions,
        submitFormResponse,
        updateResponse,
        useFormFields,

        // Upload Logo function
        uploadLogo,

        // Authentication data and methods
        currentUser,
        currentUserLoading,
        signIn,
        signOut,
        sendPasswordResetEmail,
        confirmPasswordReset,
        verifyPasswordResetCode,
        getCurrentUserEmail,

        getTableFields,
        saveTableFields,

        // Teams data and methods
        teams,
        isTeamsLoading,
        isTeamsError,
        fetchTeams,
        addTeam,
        editTeam,
        deleteTeam,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
