// src/components/Common/PaginationControls.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './PaginationControls.css';

const PaginationControls = ({
  currentPage,
  totalPages,
  onPrev,
  onNext,
  onPageClick,
  itemsPerPage,
  onItemsPerPageChange,
  itemsPerPageOptions,
  itemsPerPageLabel,
  previousLabel,
  nextLabel,
  pageLabel,
  isMobileBreakpoint,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= isMobileBreakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= isMobileBreakpoint);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileBreakpoint]);

  const generatePageNumbers = () => {
    const pageNumbers = [];

    if (isMobile) {
      let startPage = Math.max(currentPage - 1, 1);
      let endPage = Math.min(startPage + 2, totalPages);

      if (endPage - startPage < 2) {
        startPage = Math.max(endPage - 2, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="pagination-controls-container">
      <div className="pagination-options">
        <label htmlFor="itemsPerPage">{itemsPerPageLabel}</label>
        <select
          id="itemsPerPage"
          value={itemsPerPage}
          onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
        >
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </div>
      <div className="pagination-controls">
        <button
          onClick={onPrev}
          disabled={currentPage === 1}
          aria-label={previousLabel}
        >
          {previousLabel}
        </button>
        {pageNumbers.map((number) => (
          <button
            key={number}
            className={number === currentPage ? 'selected-page-button' : 'page-button'}
            onClick={() => onPageClick(number)}
            aria-label={`${pageLabel} ${number}`}
          >
            {number}
          </button>
        ))}
        <button
          onClick={onNext}
          disabled={currentPage === totalPages}
          aria-label={nextLabel}
        >
          {nextLabel}
        </button>
      </div>
    </div>
  );
};

PaginationControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
  itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  itemsPerPageLabel: PropTypes.string,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  pageLabel: PropTypes.string,
  isMobileBreakpoint: PropTypes.number,
};

PaginationControls.defaultProps = {
  itemsPerPageOptions: [20, 50, 100],
  itemsPerPageLabel: 'Items per page:',
  previousLabel: 'Previous',
  nextLabel: 'Next',
  pageLabel: 'Page',
  isMobileBreakpoint: 768,
};

export default PaginationControls;
