import React from 'react';
import PropTypes from 'prop-types';
import './SearchBar.css';
import Spinner from './Spinner';

const SearchBar = ({ searchTerm, onSearchChange, placeholder, isLoading }) => {
  if (isLoading) {
    return (
      <div className="search-bar-container">
        <Spinner />
      </div>
    );
  }

  return (
    <input
      type="text"
      placeholder={placeholder}
      className="search-bar"
      value={searchTerm}
      onChange={onSearchChange}
      aria-label={placeholder}
    />
  );
};

SearchBar.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
  isLoading: false,
};

// Wrap with React.memo
export default React.memo(SearchBar);
