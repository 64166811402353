// src/components/Common/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';
import CatholicoreLogo from './assets/CatholicoreLogo.png'; // Ensure the path is correct
import './NotFound.css'; // Ensure this CSS file exists

const NotFound = () => {
  return (
    <div className='not-found-background'>
      <div className='not-found-container'>
        <img src={CatholicoreLogo} alt="Catholicore Logo" className="not-found-logo" />
        <h2>404 - Page Not Found</h2>
        <p>The page you are looking for does not exist.</p>
        <Link to="/" className="home-link">Go to Home</Link>
      </div>
    </div>
  );
};

export default NotFound;
