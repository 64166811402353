// src/components/FormBuilder/Fields/EmailField.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './EmailField.css';

const EmailField = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  required,
  onValidate, // New prop for reporting validation
}) => {
  const [error, setError] = useState('');

  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validation function
  const validate = (valueToValidate) => {
    if (required && !valueToValidate.trim()) {
      return 'This field is required.';
    }
    if (valueToValidate && !emailRegex.test(valueToValidate)) {
      return 'Please enter a valid email address.';
    }
    return '';
  };

  // Effect to report validation status to parent when error changes
  useEffect(() => {
    if (onValidate) {
      onValidate(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // Handler for onBlur event
  const handleBlur = () => {
    const validationError = validate(value);
    setError(validationError);
  };

  // Handler for onChange event
  const handleChange = (e) => {
    onChange(e.target.value);
    if (error) {
      // Re-validate on change if there's an existing error
      const validationError = validate(value);
      setError(validationError);
    }
  };

  return (
    <div className="form-group">
      <label htmlFor={name}>
        {label} {required && <span className="required">*</span>}
      </label>
      <input
        type="email"
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        aria-describedby={`${name}-error`}
        required={required}
      />
      {error && <span className="error-message">{error}</span>}
    </div>
  );
};

EmailField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onValidate: PropTypes.func, // New prop
};

EmailField.defaultProps = {
  value: '',
  placeholder: 'example@domain.com',
  required: false,
  onValidate: () => {}, // Default no-op function
};

export default EmailField;
