// src/components/Common/DeleteConfirmationModal.jsx

import React from 'react';
import PropTypes from 'prop-types';
import CustomModal from './CustomModal';
import ActionButton from './ActionButton';
import Spinner from './Spinner'; // Import the custom Spinner component
import { useTranslation } from 'react-i18next';
import './DeleteConfirmationModal.css';

const DeleteConfirmationModal = ({
  show,
  onConfirm,
  onCancel,
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  isMobile,
  isLoading, // New prop for loading state
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal show={show} onClose={onCancel} title={title || t('deleteConfirmation.title')}>
      <div className="delete-confirmation">
        <p>{message || t('deleteConfirmation.defaultMessage')}</p>
        <div className="delete-confirmation-actions">
          <ActionButton
            onClick={onConfirm}
            label={confirmButtonText || t('deleteConfirmation.confirmButton')}
            text={
              isLoading
                ? (
                  <>
                    <Spinner size="20px" color="#fff" /> {t('deleting')}
                  </>
                )
                : (confirmButtonText || t('deleteConfirmation.confirmButton'))
            }
            icon={isLoading ? null : 'faCheck'}
            isMobile={isMobile}
            colorType="primary"
            ariaLabel={confirmButtonText || t('deleteConfirmation.confirmButton')}
            disabled={isLoading} // Disable button when loading
            spin={false} // No spin prop since we're using Spinner component
          />
          <ActionButton
            onClick={onCancel}
            label={cancelButtonText || t('deleteConfirmation.cancelButton')}
            text={cancelButtonText || t('deleteConfirmation.cancelButton')}
            icon="faTimes"
            isMobile={isMobile}
            colorType="secondary"
            ariaLabel={cancelButtonText || t('deleteConfirmation.cancelButton')}
            disabled={isLoading} // Disable button when loading
            spin={false}
          />
        </div>
      </div>
    </CustomModal>
  );
};

DeleteConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  isMobile: PropTypes.bool,
  isLoading: PropTypes.bool, // Define prop type
};

DeleteConfirmationModal.defaultProps = {
  title: '',
  message: '',
  confirmButtonText: '',
  cancelButtonText: '',
  isMobile: false,
  isLoading: false, // Default to not loading
};

export default DeleteConfirmationModal;
